/** @format */

import axios, { Axios } from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { createContext } from "react";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  axios.defaults.withCredentials = true;
  const login = async (inputs) => {
    const res = await axios.post(
      `${process.env.REACT_APP_Backend_API}/api/auth/login`,
      inputs,

      {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": process.env.REACT_APP_Backend_API,
      }
    );
    setCurrentUser(res.data);
  };

  const logout = async (inputs) => {
    const res = await axios.post(
      `${process.env.REACT_APP_Backend_API}/api/auth/logout`
    );
    setCurrentUser(null);
  };

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(currentUser));
  }, [currentUser]);

  return (
    <AuthContext.Provider value={{ currentUser, login, logout }}>
      {children}{" "}
    </AuthContext.Provider>
  );
};
