/** @format */

import axios from "axios";
import MainCard from "components/cards";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BidsTable from "./BidsTable";
import { columnsDataUsersOverview } from "./variables/columnsDataUsersOverview";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BidsAdmin = () => {
  const [bids, setBids] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_Backend_API}/api/bids/`
        );
        setBids(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="w-full ">
      <MainCard extra={"flex items-center justify-between h-[100px] px-8  "}>
        <button>
          <Link
            className="px-3 py-[16px] rounded-xl text-2xl font-banscrift font-bold text-white bg-grayPrimary hover:bg-gray-500 uppercase "
            to="/admin/bids/addbid"
          >
            Add new Bid
          </Link>
        </button>
        <h1 className="text-textSecondary uppercase text-[30px] font-banscrift font-bold ">
          Bids Page
        </h1>
      </MainCard>
      <ToastContainer position="top-center" />
      {/* post list */}
      <div>
        <BidsTable tableData={bids} columnsData={columnsDataUsersOverview} />
      </div>
    </div>
  );
};

export default BidsAdmin;
