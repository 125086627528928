/** @format */

import CopyRightFooter from "components/footer/CopyRightFooter";
import Footer from "components/footer/Footer";
import { Route, Routes } from "react-router-dom";
import Home from "view/pages/home";
import WhatWeDo from "view/pages/whatwedo";
import WhereWeWork from "view/pages/wherewework";
import OurStories from "view/pages/ourstories";
import Story from "view/pages/story";
import Announcement from "view/pages/announcement";
import Details from "view/pages/announcementDetails";
import About from "view/pages/about";
import Contact from "view/pages/contact";
import NoMatch from "view/pages/nomatch";
import SingleBid from "view/pages/announcementBids";

import PolicyDetail from "view/pages/about/components/PolicyDetail";
import Test from "view/pages/Test";

function WebPage() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/test" element={<Test />} />

        <Route path="/whatwedo" element={<WhatWeDo />} />
        {/* <Route path='/whatwedo/social-audit' element={<SocialAudit />} /> */}

        <Route path="/wherewework" element={<WhereWeWork />} />
        <Route path="/ourstories" element={<OurStories />} />
        <Route path="/ourstories/:story" element={<Story />} />
        <Route path="/announcement" element={<Announcement />} />
        <Route path="/announcement/jobs/:id" element={<Details />} />
        <Route path="/announcement/Bids/:id" element={<SingleBid />} />

        <Route path="/aboutus" element={<About />} />
        <Route path="/about/policy/:id" element={<PolicyDetail />} />
        <Route path="/contactus" element={<Contact />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>

      <Footer />
      <CopyRightFooter />
    </div>
  );
}

export default WebPage;
