/** @format */

import React from "react";

function HealthSchool({
  paragraph1,
  paragraph2,
  paragraph3,
  paragraph4,
  text,
  photo,
}) {
  return (
    <div className='flex flex-col w-full h-full lg:grid grid-cols-10 !mt-12 '>
      <div className='flex items-center justify-center  lg:col-span-4 lg:mt-24 lg:ml-32 lg:w-[300px] lg:h-[250px]'>
        <img
          src={photo}
          className='w-[160px] h-[140px] lg:w-full lg:h-full '
          alt=''
        />
      </div>
      <div
        className={`mt-8 lg:mt-0 px-4 lg:col-span-6   lg:ml-14 ${text} lg:px-3 `}
      >
        <p className='text-grayDark dark:text-white lg:text-lg font-calibriregular'>
          {paragraph1}
        </p>
        <p className='mt-5 text-grayDark dark:text-white lg:text-lg font-calibriregular'>
          {paragraph2}
        </p>
        <p className='mt-5 text-grayDark dark:text-white lg:text-lg font-calibriregular'>
          {paragraph3}
        </p>
        <p className='mt-5 text-grayDark dark:text-white lg:text-lg font-calibriregular'>
          {paragraph4}
        </p>
      </div>
    </div>
  );
}

export default HealthSchool;
