/** @format */

const MainCard = (props) => {
  const { variant, extra, children, ...rest } = props;
  return (
    <div
      className={`!z-5 relative dark:!bg-navy-800 dark:shadow-none dark:text-white rounded-[20px] bg-clip-border bg-white shadow-shadow-500 shadow-3xl ${extra}`}
      {...rest}
    >
      {children}
    </div>
  );
};

export default MainCard;
