/** @format */

import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MainCard from "components/cards";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const SinglePolicyAdmin = () => {
  const [policy, setPolicy] = useState({});
  const navigate = useNavigate();

  const location = useLocation();
  const policyId = location.pathname.split("/")[3];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_Backend_API}/api/policy/${policyId}`
        );
        setPolicy(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [policyId]);

  const handleDelete = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_Backend_API}/api/policy/${policyId}`
      );
      toast.success("Policy deleted successfully!");
      navigate("/admin/policies");
    } catch (err) {
      console.log(err);
    }
  };

  const policyfile = `${process.env.REACT_APP_Backend_API}/upload/policyfile/file/`;
  const policyphoto = `${process.env.REACT_APP_Backend_API}/upload/policyfile/cover/`;

  const MySwal = withReactContent(Swal);
  const popUpDelete = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete();
      }
    });
  };

  return (
    <div>
      <MainCard extra={"flex items-center  justify-between h-[100px] px-8  "}>
        <Link to="/admin/policies">
          <h1 className="text-textSecondary flex items-center gap-2 uppercase text-[30px] font-banscrift font-bold ">
            <AiOutlineArrowLeft />
            Back
          </h1>
        </Link>
      </MainCard>
      <MainCard extra=" dark:bg-dark w-full mt-5 pb-10 px-14 pt-8 rounded-[20px] bg-white">
        {/* header */}
        <h1 className="text-textSecondary font-banscrift dark:text-white text-[30px] font-bold">
          {policy.title}
        </h1>
        <div className="grid w-full grid-cols-2 gap-3 mt-3 full">
          <div className="w-full h-full">
            <p className="text-[18px] font-calibriregular ">
              {policy.description}
            </p>
            {/* download button */}
            <div className="mt-10">
              <a
                download={`${policy.file}`}
                href={policyfile + policy.file}
                className="px-4 py-3 mt-5 text-white transition-all duration-200 dark:text-white dark:bg-darkHeader dark:hover:bg-darkMain rounded-xl bg-textPrimary hover:bg-textSecondary"
              >
                {" "}
                Download File
              </a>
            </div>
          </div>
          <div className="w-full h-full">
            <img
              src={policyphoto + policy.cover}
              className="w-full h-full rounded-md"
              alt=""
            />
          </div>
        </div>

        <div className="flex items-center justify-end gap-5 mt-12">
          <button
            onClick={popUpDelete}
            className="px-4 py-1 text-white transition-colors duration-200 bg-red-800 rounded-lg hover:bg-red-900"
          >
            Delete Policy
          </button>

          <Link to={`/admin/policies/updatepolicy/${policyId}`}>
            <button className="px-4 py-1 text-white transition-colors duration-200 bg-blue-800 rounded-lg hover:bg-blue-900">
              Update Policy
            </button>
          </Link>
        </div>
      </MainCard>
    </div>
  );
};

export default SinglePolicyAdmin;
