/** @format */

import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import TextField from "./TextField";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { BsEyeFill } from "react-icons/bs";

const SignUp = () => {
  const emailRegex = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  // file: Yup.mixed().required('File is required'),
  const ValidateSchema = Yup.object().shape({
    username: Yup.string()
      .max(20, "must be 20 characters or less")
      .required("Required!"),
    email: Yup.string()
      .trim()
      .required("Required")
      .email("Invalid email")
      .matches(emailRegex, "Invalid email"),
    password: Yup.string()
      .min(6, "password should be at least 6 characters")
      .required("Password is Required!"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "password should match")
      .required("Confirm password is Required!"),
    file: Yup.mixed().required("File is required"),
    // role: Yup.string().required("Role is Required!"),
  });

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [role, setRole] = useState("");
  const [file, setFile] = useState("");

  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);

  const handleClick = async (e) => {
    // e.preventDefault();
    setSubmitting(true);

    var formData = new FormData();
    formData.append("username", username);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("file", file);
    formData.append("role", role);
    try {
      await axios.post(
        `${process.env.REACT_APP_Backend_API}/api/auth/register`,
        formData,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      toast.success("New user added successfully!");
      navigate("/admin/users");
    } catch (error) {
      console.log("error is here :" + error);
    } finally {
      setSubmitting(false);
    }
  };

  //toggle password
  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <div>
      <Formik
        initialValues={{
          username: username,
          email: email,
          password: password,
          confirmPassword: confirmPassword,
          // role : role,
        }}
        validationSchema={ValidateSchema}
        onSubmit={handleClick}
      >
        {(props) => (
          <div>
            <Form>
              <TextField
                name="username"
                onChange={(e) => {
                  props.handleChange(e);
                  setUsername(e.target.value);
                }}
                type="text"
                label="Full Name"
              />
              <TextField
                name="email"
                onChange={(e) => {
                  props.handleChange(e);
                  setEmail(e.target.value);
                }}
                type="email"
                label="Email"
              />
              <div className="flex items-center gap-2">
                <TextField
                  name="password"
                  onChange={(e) => {
                    props.handleChange(e);
                    setPassword(e.target.value);
                  }}
                  type={passwordType}
                  label="Password"
                />
                <BsEyeFill onClick={togglePassword} className="w-5 h-5 mb-2 " />
              </div>
              <TextField
                name="confirmPassword"
                type="password"
                label="Confirm Password"
              />
              <TextField
                type="file"
                name="file"
                onChange={(e) => {
                  props.handleChange(e);
                  setFile(e.target.files[0]);
                }}
              />

              {/* <TextField
                name="role"
                onChange={(e) => {
                  props.handleChange(e);
                  setRole(e.target.value);
                }}
                type="textarea"
                label="Role"
                placeholder="admin, jobs, posts"
              /> */}
              <div className="my-8">
                <label
                  className={`text-sm text-bluePrimary dark:text-white mb-2 `}
                >
                  Role
                </label>
                <select
                  className="outline-none dark:bg-navy-800 dark:!border-navy-700 border text-sm flex items-center justify-center border-lightPrimary w-full p-3 full rounded-xl"
                  name="role"
                  onChange={(e) => {
                    setRole(e.target.value);
                  }}
                  id=""
                >
                  <option>Select User Role</option>
                  <option value="jobs">Jobs & Bids</option>
                  <option value="posts">Stories</option>
                  <option value="admin">Admin</option>
                </select>
              </div>

              <button
                type="submit"
                disabled={submitting}
                className="px-4 py-1 text-white bg-blue-900 rounded-lg"
              >
                {submitting ? "Registring User..." : "Register User"}
              </button>
              <button
                type="reset"
                className="px-4 py-1 ml-3 text-white bg-red-900 rounded-lg"
              >
                Reset
              </button>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default SignUp;
