/** @format */

import IntegrityColor from "components/icons/IntegrityColor";
import Navbars from "components/navbar/Navbars";
import Content from "./components/Content";
import Header from "./components/Header";

const SingleBid = () => {
  return (
    <div className='w-full dark:bg-darkMain h-full pb-20 '>
      <div>
        <Navbars
          textColor={"text-textPrimary"}
          bg='bg-colorWhite dark:bg-darkHeader'
          logo={<IntegrityColor />}
        />
      </div>
      <div>
        <Header />
      </div>
      <div className='max-w-screen-xl px-6 md:px-0 mx-auto'>
        <Content />
      </div>
    </div>
  );
};

export default SingleBid;
