/** @format */

import React, { useEffect, useState } from "react";
import Card from "components/cards/card";
import axios from "axios";

function Policies() {
  const [policies, setPolicies] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_Backend_API}/api/policy/`
        );
        setPolicies(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);
  const policyphoto = `${process.env.REACT_APP_Backend_API}/upload/policyfile/cover/`;
  return (
    <div className=" max-w-screen-xl justify-items-center justify-center  mx-auto grid grid-cols-1 sm:grid-cols-2  lg:grid-cols-3 px-12 lg:px-0 gap-3 lg:gap-10 mt-[70px]  ">
      {policies.map((policy) => (
        <div>
          <Card
            buttonBg="!bg-[#E46710]"
            to={`/about/policy/${policy.id}`}
            title={policy.title}
            badgename="Our Policies"
            image={policyphoto + policy.cover}
          />
        </div>
      ))}
    </div>
  );
}

export default Policies;
