/** @format */

import React, { useEffect, useState } from "react";
import Paragraph from "./Paragraph";
import Volunteer from "components/displayData/Volunteer";

function TrialMethodology() {
  const [trial, setTrial] = useState([]);
  const fetchPosts = async () => {
    const response = await fetch(
      "https://api.communitymonitoring.org/api/external/sites-monitored"
    );
    const data = await response.json();

    setTrial(data.cbmt);
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <>
      <div className='flex flex-col items-center w-full h-full pb-10'>
        {/* header */}
        <h1 className=' text-[24px] dark:text-white px-4 lg:px-0 text-left lg:text-[30px] uppercase text-grayDark2 lg:text-center font-bold'>
          The methodology of{" "}
          <span className=''>
            {" "}
            <br /> Community Based Monitoring of Trial (CBM-T)
          </span>
        </h1>
        {/* main content */}
        <div className='px-4 mt-4 lg:px-10'>
          <Paragraph
            line='h-[100px]'
            title='Community Mobilization'
            description='The CBM-T program draws on community participation to establish the legitimacy of its objectives. “Community” for this program assumes the inclusion of Community Development Councils, civil society actors, traditional Shuras, and legal service providers. Each participating community is asked to elect a local Integrity Volunteer as a trials monitor. We strongly encourage the participation of both male and female Integrity Volunteers of diverse backgrounds. They should be literate, of good reputation within the community, and able to commit several days to CBM-T each month. Ideally they should live within walking distance of the court.
          '
          />
          <Paragraph
            line='h-[100px]'
            title='Training of Integrity Volunteers'
            description='The Integrity Volunteers receive specific training on legal issues in order to consolidate their legal knowledge and improve their monitoring skills. In addition, Integrity Watch facilitates workshops delivered by other national and international organizations, such as UNAMA, IDLO, NRC, and Independent Human Rights Commission, for Integrity Volunteers to improve their awareness and legal knowledge and understanding. '
          />
          <Paragraph
            line='h-[100px]'
            title='Baseline Survey'
            description='Newly-trained Integrity Volunteers are supported to conduct Baseline Surveys (using random sampling) with adults who live in communities that fall within the catchment of a court selected for CBM-T. The survey investigates community understanding of trial courts and satisfaction levels with local justice, and also awareness of and participation in open trial monitoring.'
          />
          <Paragraph
            line='h-[100px]'
            title='Building Relations with Court Authorities'
            description='Local Monitors and monitoring program representatives meet with each judge whose proceedings will be followed. They introduce themselves and explain their planned activities so that their presence in the courtroom will be recognized and understood. Court officials are sometimes defensive, critical, or even aggressive towards trial monitoring if they perceive it to be an intrusive activity that questions their competence and authority. Against this background, meetings with judicial officials represent a unique opportunity to show the program’s good intentions and professionalism. Experience shows that conducting a meeting with judges in advance has improved access to hearings and facilitates the resolution of problems that may arise.'
          />
          <Paragraph
            line='h-[100px]'
            title='Monitoring the Courts'
            description='CBM-T Integrity Volunteers from different communities coordinate together to cover all sessions at a single court. Integrity Watch aims to have its Integrity Volunteers monitor every criminal and civil case that is openly held and takes place in the courts in the targeted districts. Across all the targeted courts, Integrity Watch expects to monitor around one thousand trials in a year.'
          />
          <Paragraph
            line='h-[100px]'
            title='Local Advocacy'
            description='
          Recommendations made by the CBM-T program are more likely to be implemented if there is mutual trust between the program staff and the justice sector. Therefore, once monitoring has begun, the Integrity Volunteer regularly meets with the judges to share valuable feedback and observations on problems and possible causes.'
            description2='On a broader level, once data from each of the courts in a province is collected, Integrity Watch engages in advocacy efforts with the provincial governor, judges, and prosecutors to address areas of concern that have been identified.Community'
          />

          <Paragraph
            line='h-[100px]'
            title='Feedback Meetings'
            description='
          In the CBM-T program we facilitate both small and large community feedback meetings. Each month the Integrity Volunteers have one small feedback meeting with two or three community leaders to share findings from their monitoring activities. The large meetings, scheduled twice per quarter, are designed to engage a variety stakeholders in debating CBM-T program findings, including local government officials, community elders, civil society, legal experts, women’s group leaders, and youth representatives.'
          />
          <Paragraph
            line='h-[100px]'
            title='Public Awareness Activities'
            title2='Mock Trial'
            description={` 
          Integrity Watch has raised awareness of the CBM-T program by conducting mock trials in local communities in all targeted provinces. Integrity Volunteers explain to citizens the ethics of the courts, and their rights to attend and monitor open trials and to flag any irregularities they may observe. In the mock trial sessions, the public becomes acquainted with legal proceedings and gains an understanding of the role of judges, prosecutors, defense counsel, and parties to the litigation. The mock trial empowers citizens to defend their rights and demand greater transparency in legal proceedings. The mock trials have so far been warmly welcomed by the public and further sessions are being requested.`}
            title3='The Theatre of the Oppressed (TO)'
            description2='
          The Theatre of the Oppressed (TO) is a theatrical approach to public awareness-raising on issues of procedural irregulars and corruption in a range of sectors (not just trials). In TO, the spectators (public) become actors themselves. Integrity Watch initially organized six-day training for Integrity Volunteers in each of the targeted provinces to prepare them to conduct TO events in their respective fields. Integrity Volunteers have conducted a total of 60 TO events in Kundoz, Balkh, Bamyan, Kapisa, and Nangarhar provinces. Due to the success of TO, Integrity Watch plans to extend TO programming to all provinces in which it operates.'
          />
          <Paragraph
            title='Endline Survey'
            line='h-[100px]'
            description='At the end of the facilitated CBM-T period, Integrity Volunteers conduct an Endline Survey to investigate changes in community understanding of trial courts and satisfaction levels with local justice, and awareness of the CBM-T program and participation in open trials monitoring. The CBM-T program is then handed over to the community entirely, though with Integrity Watch continuing to provide technical assistance where needed.'
          />
          <Paragraph
            title='CSO Training '
            description='In 2017, to further increase legal awareness among the public, Integrity Watch arranged 20 training sessions for various Civil Society Organizations (CSOs) in Balkh, Kapisa, Nangarhar, Kandahar, and Herat. Integrity Watch intends to deliver CSO training in other target provinces in the near future.'
          />
          <div className='flex items-center justify-center w-full my-10'>
            <div className='w-1/2 bg-grayDark  h-[1px]'></div>
          </div>
          <Paragraph
            description={`Aiming to increase citizens’ participation in Afghan courts and monitor compliance with procedural laws, the CBM-T program has helped raise transparency in judicial decision-making. It has increased community awareness of the rule of law system and encouraged data-driven advocacy to promote greater integrity in the judiciary. The program has achieved wide community interest and buy-in: to date, ${trial["volunteers"]} men and women as Integrity Volunteers have monitored ${trial["sites-monitored"]} open trials in the provinces of Balkh, Kapisa, Kabul, Laghman, Nangarhar, Bamyan, Parwan, Herat, and Jawzjan. 
  `}
          />
        </div>
        <p></p>
      </div>
      {/* data from communitymonitoring */}
      <div className='h-full max-w-screen-xl mx-auto mt-8'>
        <Volunteer
          volunteers={trial.volunteers}
          problemsIdentified={trial.problems}
          problemsSolved={trial["problems-solved"]}
          sitesMonitored={trial["sites-monitored"]}
        />
      </div>
    </>
  );
}

export default TrialMethodology;
