/** @format */
import { useGlobalFilter, useTable } from "react-table";
import IntegrityColor from "components/icons/IntegrityColor";
import Navbars from "components/navbar/Navbars";
import Header from "./components/Header";
import SearchTableUsers from "./components/SearchTableUsersOverivew";
import { columnsDataUsersOverview } from "./variables/columnsDataUsersOverview";

import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import BidsPageTable from "./components/BidsPageTable";
const Announcement = () => {
  const [jobs, setJobs] = useState([]);
  const [bids, setBids] = useState([]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const res = await axios.get(
  //         `${process.env.REACT_APP_Backend_API}/api/jobs/`
  //       );
  //       setJobs(res.data);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   fetchData();
  // }, []);

  // //get bid data
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const res = await axios.get(
  //         `${process.env.REACT_APP_Backend_API}/api/bids/`
  //       );
  //       setBids(res.data);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   fetchData();
  // }, []);

  const job = "jobs";
  const bid = "bids";

  const [activeJobs, setActiveJobs] = useState(true);
  const [activeBids, setActiveBids] = useState(false);
  const columns = useMemo(
    () => columnsDataUsersOverview,
    [columnsDataUsersOverview]
  );
  const data = useMemo(() => bids, [bids, jobs]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );

  const { setGlobalFilter } = tableInstance;

  //  Pagination and filtering for jobs
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [products, setProducts] = useState([]);

  const fetchProducts = async (page) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Backend_API}/api/jobs/alljobs?page=${page}&pageSize=5`
      );
      const { products, totalPages } = response.data;
      setProducts(products);
      setTotalPages(totalPages);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchProducts(currentPage);
  }, [currentPage]);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  //  Pagination and filtering for Bids
  const [currentPagee, setCurrentPagee] = useState(1);
  const [totalPagess, setTotalPagess] = useState(0);
  const [bidsdata, setBidsdata] = useState([]);

  const fetchBids = async (page) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Backend_API}/api/bids/allbids?page=${page}&pageSize=5`
      );
      const { totalPages } = response.data;
      const totalBids = response.data.bids;
      setBidsdata(totalBids);
      setTotalPagess(totalPages);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchBids(currentPagee);
  }, [currentPagee]);

  const handlePrevPagee = () => {
    if (currentPagee > 1) {
      setCurrentPagee(currentPagee - 1);
    }
  };

  const handleNextPagee = () => {
    if (currentPagee < totalPagess) {
      setCurrentPagee(currentPagee + 1);
    }
  };

  return (
    <div className="w-full h-full pb-10 dark:bg-darkMain ">
      <div>
        <Navbars
          textColor={"text-textPrimary"}
          bg="bg-[#FAFAFA] dark:bg-darkHeader"
          logo={<IntegrityColor />}
        />
      </div>
      <div>
        <Header />
      </div>

      <div className="w-full h-full max-w-screen-xl pt-5 mx-auto mt-16 rounded-xl dark:bg-dark bg-colorWhite">
        <div className="flex items-center justify-center w-full gap-4 pt-5">
          <h1
            onClick={() => {
              setGlobalFilter(job);
              setActiveJobs(true);

              setActiveBids(false);
            }}
            className={`text-textSecondary hover:cursor-pointer text-[24px] uppercase ${
              activeJobs
                ? "font-bold border-b-[4px] dark:text-white dark:border-white border-textSecondary"
                : "font-normal text-grayPrimary dark:border-dark border-b-2 border-colorWhite"
            }`}
          >
            jobs
          </h1>
          <h1
            onClick={() => {
              setGlobalFilter(bid);
              setActiveBids(true);

              setActiveJobs(false);
            }}
            className={`text-textSecondary hover:cursor-pointer text-[24px] uppercase ${
              activeBids
                ? "font-bold  border-b-[4px] dark:text-white dark:border-white border-textSecondary"
                : "font-normal text-grayPrimary dark:border-dark border-b-2 border-colorWhite"
            }`}
          >
            bids
          </h1>
        </div>
        {activeJobs ? (
          <SearchTableUsers
            tableData={products}
            columnsData={columnsDataUsersOverview}
            nextClick={handleNextPage}
            prevClick={handlePrevPage}
          />
        ) : (
          <BidsPageTable
            tableData={bidsdata}
            columnsData={columnsDataUsersOverview}
            nextClick={handleNextPagee}
            prevClick={handlePrevPagee}
          />
        )}
      </div>
    </div>
  );
};

export default Announcement;
