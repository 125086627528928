/** @format */

import axios from "axios";
import MainCard from "components/cards";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import JobsTable from "./JobsTable";
import { columnsDataUsersOverview } from "./variables/columnsDataUsersOverview";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const JobsAdmin = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_Backend_API}/api/jobs/`
        );
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);
  const getText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent;
  };
  return (
    <div className='w-full '>
      <ToastContainer position='top-center' />
      <MainCard extra={"flex items-center justify-between h-[100px] px-8  "}>
        <h1 className='text-textSecondary uppercase text-[30px] font-banscrift font-bold '>
          Jobs Page
        </h1>
        <button>
          <Link
            className='px-3 py-[16px]  rounded-2xl text-xl font-banscrift font-bold text-white bg-grayPrimary hover:bg-gray-500 uppercase '
            to='/admin/jobs/addjob'
          >
            Add new Job
          </Link>
        </button>
      </MainCard>
      {/* post list */}
      <div>
        <JobsTable tableData={posts} columnsData={columnsDataUsersOverview} />
      </div>
    </div>
  );
};

export default JobsAdmin;
