/** @format */

import React, { Children } from "react";
import { useField, ErrorMessage } from "formik";

const TextField = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="mb-4">
      <label className="mb-1" htmlFor={field.name}>
        {label}
      </label>
      <input
        {...field}
        {...props}
        className={`w-full rounded-lg ${
          meta.touched && meta.error && "!border-red-700"
        }`}
      />
      <ErrorMessage
        component="div"
        className="text-red-500"
        name={field.name}
      />
    </div>
  );
};

export default TextField;
