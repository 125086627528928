/** @format */

import React from "react";
import Plus from "components/icons/Plus";
import SearchIcon from "components/icons/SearchIcon";
import User from "components/icons/User";
import tick from "assets/What we do/Group 3/tick.svg";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { useAnimation } from "framer-motion";
import { motion } from "framer-motion";
function Volunteer({
  volunteers,
  sitesMonitored,
  problemsIdentified,
  problemsSolved,
}) {
  const { ref, inView } = useInView({
    threshold: 0.2,
  });
  const fade = useAnimation();
  useEffect(() => {
    if (inView) {
      fade.start({
        opacity: 1,
        transition: { duration: 0.5 },
      });
    }
    if (!inView) {
      fade.start({
        opacity: 0,
      });
    }
  }, [inView]);
  return (
    <div
      ref={ref}
      className='grid grid-cols-2 dark:bg-darkMain lg:grid-cols-4 gap-14 px-4 lg:px-0 lg:gap-[100px] pb-[150px] xl:pt-[66px] pt-7 bg-white'
    >
      <motion.div animate={fade} className='flex flex-col items-center'>
        <p className=' text-[#1AA9B8] text-[30px] gl:text-[40px] '>
          <User />
        </p>
        <h1 className='text-[#1AA9B8] dark:text-white text-[36px] lg:text-[46px] mt-3 lg:mt-[60px] font-bold '>
          {volunteers}
        </h1>
        <h1 className='text-[#1AA9B8] dark:text-white text-[18px] lg:text-[27px] mt-[18px] uppercase font-semibold '>
          Volunteers
        </h1>
      </motion.div>
      <motion.div
        animate={fade}
        className='flex flex-col items-center text-center'
      >
        <p className=' text-[#005362] dark:text-white text-[30px] lg:text-[40px] '>
          <SearchIcon />
        </p>
        <h1 className='text-[#005362] dark:text-white text-[36px] lg:text-[46px] mt-3 lg:mt-[60px] font-bold '>
          {sitesMonitored}
        </h1>
        <h1 className='text-[#005362] dark:text-white text-[18px] lg:text-[27px] mt-[18px] uppercase font-semibold '>
          Sites Monitored
        </h1>
      </motion.div>
      <motion.div
        animate={fade}
        className='flex flex-col items-center text-center'
      >
        <p className=' text-[#852711] dark:text-white text-[30px] lg:text-[40px] '>
          <Plus />
        </p>
        <h1 className='text-[#852711] dark:text-white text-[36px] lg:text-[46px] mt-3 lg:mt-[60px] font-bold '>
          {problemsIdentified}
        </h1>
        <h1 className='text-[#852711] dark:text-white text-[18px] lg:text-[27px] uppercase mt-[18px] font-semibold '>
          Problems Identified
        </h1>
      </motion.div>
      <motion.div
        animate={fade}
        className='flex flex-col items-center text-center'
      >
        <div className=' text-[#E46710] dark:text-white w-[75px] h-[70px] lg:w-[75px] '>
          <img src={tick} alt='' />
        </div>
        <h1 className='text-[#E46710] dark:text-white text-[36px] lg:text-[46px] mt-3 lg:mt-[60px] font-bold '>
          {problemsSolved}
        </h1>
        <h1 className='text-[#E46710] dark:text-white text-[18px] lg:text-[27px] uppercase mt-[18px] font-semibold '>
          Problems Solved
        </h1>
      </motion.div>
    </div>
  );
}

export default Volunteer;
