/** @format */
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "context/authConext";

import { BsDiamond, BsJournalBookmark } from "react-icons/bs";
import { HiX } from "react-icons/hi";
import { MdDoorFront, MdPhoneIphone, MdWebStories } from "react-icons/md";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";

const Sidebar = ({ open, onClose }) => {
  const navLinkStyle = ({ isActive }) => {
    return {
      color: isActive ? `#1AA9B8 ` : "#005362",
    };
  };
  const { currentUser, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const handleLogOut = () => {
    logout();
    navigate("/");
  };

  const [user, setUser] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_Backend_API}/api/auth/register/${currentUser.id}`
        );

        setUser(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [currentUser.id]);

  return (
    <div>
      <div
        className={`fixed inset-0  w-screen h-screen transition-opacity block  xl:hidden ${
          open
            ? "opacity-100 pointer-events-auto"
            : "opacity-0 pointer-events-none"
        }`}
      />
      <div
        className={`fixed  z-20 h-screen bg-white rounded-br-xl w-[295px] transition-transform duration-300 ${
          open ? "translate-x-0" : "-translate-x-96"
        }`}
      >
        <span
          className="absolute block cursor-pointer top-4 right-4 xl:hidden"
          onClick={onClose}
        >
          <HiX />
        </span>

        <div className={`flex items-center mx-[40px]    `}>
          <div className="font-calibribold w-full h-full py-10 uppercase text-[24px] text-textSecondary ">
            Integrity watch
          </div>
        </div>
        <div class=" h-px bg-gray-300  mb-8 " />
        {/* Nav item */}

        {/* links */}
        <ul className="pt-1 ">
          {user.role === "admin" || user.role === "posts" ? (
            <NavLink
              style={navLinkStyle}
              to={"/admin/stories"}
              className={` text-textSecondary hover:text-teal-600`}
            >
              <div className="relative flex mb-3 hover:cursor-pointer">
                <li className="flex items-center px-8  my-[3px] cursor-pointer">
                  <span className={` text-lg font-bold `}>
                    <MdWebStories />{" "}
                  </span>
                  <span
                    className={`flex text-base ml-4 capitalize font-banscrift font-bold`}
                  >
                    Stories
                  </span>
                </li>
              </div>
            </NavLink>
          ) : null}
          {user.role === "jobs" || user.role === "admin" ? (
            <NavLink
              style={navLinkStyle}
              to={"/admin/jobs"}
              className={` text-textSecondary hover:text-teal-600`}
            >
              <div className="relative flex mb-3 hover:cursor-pointer">
                <li className="flex items-center px-8 my-[3px] cursor-pointer">
                  <span className={`font-bold text-lg `}>
                    <BsJournalBookmark />{" "}
                  </span>
                  <span
                    className={`flex text-base ml-4 font-banscrift capitalize font-bold`}
                  >
                    jobs
                  </span>
                </li>
              </div>
            </NavLink>
          ) : null}
          {user.role === "jobs" || user.role === "admin" ? (
            <NavLink
              style={navLinkStyle}
              to={"/admin/bids"}
              className={` text-textSecondary hover:text-teal-600`}
            >
              <div className="relative flex mb-3 hover:cursor-pointer">
                <li className="flex items-center px-8 my-[3px] cursor-pointer">
                  <span className={`font-bold text-lg`}>
                    <BsDiamond />{" "}
                  </span>
                  <span
                    className={`flex text-base ml-4 font-banscrift capitalize font-bold`}
                  >
                    bids
                  </span>
                </li>
              </div>
            </NavLink>
          ) : null}
          {user.role === "admin" ? (
            <NavLink
              style={navLinkStyle}
              to={"/admin/contacts"}
              className={` text-textSecondary hover:text-teal-600`}
            >
              <div className="relative flex mb-3 hover:cursor-pointer">
                <li className="flex items-center px-8  my-[3px] cursor-pointer">
                  <span className={` text-lg font-bold `}>
                    <MdPhoneIphone />{" "}
                  </span>
                  <span
                    className={`flex text-base ml-4 capitalize font-banscrift font-bold`}
                  >
                    Contacts
                  </span>
                </li>
              </div>
            </NavLink>
          ) : null}
          {user.role === "admin" ? (
            <NavLink
              style={navLinkStyle}
              to={"/admin/policies"}
              className={` text-textSecondary hover:text-teal-600`}
            >
              <div className="relative flex mb-3 hover:cursor-pointer">
                <li className="flex items-center px-8  my-[3px] cursor-pointer">
                  <span className={` text-lg font-bold `}>
                    <MdDoorFront />{" "}
                  </span>
                  <span
                    className={`flex text-base ml-4 capitalize font-banscrift font-bold`}
                  >
                    Policies
                  </span>
                </li>
              </div>
            </NavLink>
          ) : null}
          {user.role === "admin" ? (
            <NavLink
              style={navLinkStyle}
              to={"/admin/users"}
              className={` text-textSecondary hover:text-teal-600`}
            >
              <div className="relative flex mb-3 hover:cursor-pointer">
                <li className="flex items-center px-8  my-[3px] cursor-pointer">
                  <span className={` text-lg font-bold `}>
                    <MdDoorFront />{" "}
                  </span>
                  <span
                    className={`flex text-base ml-4 capitalize font-banscrift font-bold`}
                  >
                    Users
                  </span>
                </li>
              </div>
            </NavLink>
          ) : null}
        </ul>

        {/* Free Horizon Card       */}
        <div className="absolute flex justify-center w-full px-5 bottom-10 ">
          {/* <SidebarCard /> */}
          <button
            onClick={handleLogOut}
            className="flex items-center justify-center w-full py-3 mt-5 text-xl text-white bg-blue-500 hover:bg-blue-600 rounded-3xl"
          >
            Logout
          </button>
        </div>

        {/* Nav item end */}
      </div>
    </div>
  );
};

export default Sidebar;
