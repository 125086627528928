import React from 'react'

function User() {
  return (
     <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
     <path d="M67.4414 62.4023C62.9067 54.5803 55.4948 48.8401 46.7871 46.4062C50.8961 44.3192 54.1823 40.9089 56.1158 36.7254C58.0493 32.5419 58.5172 27.8291 57.4442 23.347C56.3712 18.865 53.8197 14.8751 50.2014 12.0207C46.583 9.16638 42.1086 7.61401 37.5 7.61401C32.8913 7.61401 28.417 9.16638 24.7986 12.0207C21.1802 14.8751 18.6288 18.865 17.5558 23.347C16.4827 27.8291 16.9507 32.5419 18.8841 36.7254C20.8176 40.9089 24.1038 44.3192 28.2129 46.4062C19.5052 48.8401 12.0933 54.5803 7.55856 62.4023C7.43903 62.6013 7.3608 62.8224 7.32854 63.0522C7.29628 63.2821 7.31066 63.5161 7.37081 63.7403C7.43096 63.9645 7.53566 64.1743 7.67865 64.3572C7.82165 64.5401 8.00001 64.6922 8.2031 64.8047C8.40312 64.9202 8.62397 64.9952 8.85299 65.0254C9.08202 65.0555 9.31475 65.0402 9.53786 64.9803C9.76097 64.9205 9.9701 64.8172 10.1533 64.6765C10.3365 64.5358 10.4901 64.3603 10.6054 64.1601C13.331 59.4391 17.2513 55.5186 21.9723 52.7929C26.6933 50.0671 32.0486 48.6321 37.5 48.6321C42.9513 48.6321 48.3067 50.0671 53.0276 52.7929C57.7486 55.5186 61.6689 59.4391 64.3945 64.1601C64.5489 64.4275 64.771 64.6495 65.0384 64.8038C65.3058 64.9581 65.6092 65.0393 65.9179 65.0391C66.2257 65.0343 66.5276 64.9538 66.7968 64.8047C66.9999 64.6922 67.1783 64.5401 67.3213 64.3572C67.4643 64.1743 67.569 63.9645 67.6291 63.7403C67.6893 63.5161 67.7037 63.2821 67.6714 63.0522C67.6391 62.8224 67.5609 62.6013 67.4414 62.4023V62.4023ZM20.5078 28.125C20.5078 24.7643 21.5044 21.479 23.3715 18.6846C25.2386 15.8903 27.8924 13.7124 30.9973 12.4263C34.1023 11.1402 37.5188 10.8037 40.815 11.4593C44.1111 12.115 47.1389 13.7333 49.5153 16.1097C51.8917 18.4861 53.51 21.5138 54.1657 24.81C54.8213 28.1061 54.4848 31.5227 53.1987 34.6276C51.9126 37.7325 49.7347 40.3864 46.9403 42.2535C44.146 44.1206 40.8607 45.1172 37.5 45.1172C32.9934 45.1172 28.6713 43.3269 25.4847 40.1403C22.298 36.9536 20.5078 32.6316 20.5078 28.125Z" fill="#1AA9B8"/>
     </svg>
     
  )
}

export default User