/** @format */
import EditorToolbar, {
  modules,
  formats,
} from "components/Editor/EditorToolbar";
import axios from "axios";
import MainCard from "components/cards";

import React from "react";
import { useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import TextField from "./variables/TextField";
import Select from "./variables/Select";

function AddStoryValidate() {
  const dropDownOption = [
    {
      key: "Select an Option",
      value: "",
    },
    {
      key: "Hide",
      value: "true",
    },
    {
      key: "Show",
      value: "false",
    },
  ];

  const validFileExtensions = {
    image: ["jpg", "gif", "png", "jpeg", "svg", "webp"],
  };

  function isValidFileType(fileName, fileType) {
    return (
      fileName &&
      validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  }

  function getAllowedExt(type) {
    return validFileExtensions[type].map((e) => `.${e}`).toString();
  }

  const MAX_FILE_SIZE = 102400;

  const ValidateSchema = Yup.object().shape({
    title: Yup.string().required("Title is Required!"),
    postby: Yup.string().required("Postby is Required!"),
    location: Yup.string().required("Location is Required!"),
    date: Yup.date().required("Date is Required!"),
    photolocation: Yup.string().required("Photo Location is Required!"),
    hidden: Yup.string().required("Visiblity is Required!"),

    // file: Yup.mixed().required("Photo is Required!"),
  });
  const [value, setValue] = useState("");
  const [title, setTitle] = useState("");
  const [date, setDate] = useState(null);
  const [file, setFile] = useState("");
  const [postby, setPostby] = useState("");
  const [location, setLocation] = useState("");
  const [photolocation, setPhotolocation] = useState("");
  const [hidden, setHidden] = useState("false");
  const [submitting, setSubmitting] = useState(false);
  const [avatarPreview, setAvatarPreview] = useState();

  const navigate = useNavigate();

  const handleClick = async (e) => {
    // e.preventDefault();
    setSubmitting(true);

    var formData = new FormData();
    formData.set("title", title);
    formData.set("description", value);
    formData.set("file", file);

    formData.set("date", date);
    formData.set("postby", postby);
    formData.set("location", location);
    formData.set("photolocation", photolocation);
    formData.set("hidden", hidden);

    try {
      await axios.post(
        `${process.env.REACT_APP_Backend_API}/api/posts/`,
        formData,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      toast.success("Story Added Successfully!");
      navigate("/admin/stories");
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };
  const reader = new FileReader();
  //showing photo
  const onChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatarPreview(reader.result);
      }
    };
    setFile(e.target.files[0]);
    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <div>
      <Formik
        initialValues={{
          title: title,
          location: location,
          postby: postby,
          photolocation: photolocation,
          date: date,
          file: file,
        }}
        validationSchema={ValidateSchema}
        onSubmit={handleClick}
      >
        {(props) => (
          <div>
            <MainCard
              extra={"flex items-center justify-between h-[100px] px-8  "}
            >
              <Link to="/admin/stories">
                <h1 className="text-textSecondary flex items-center gap-2 uppercase text-[30px] font-banscrift font-bold ">
                  <AiOutlineArrowLeft />
                  Back
                </h1>
              </Link>
              <div>
                <h1 className="text-textSecondary flex items-center gap-2 uppercase text-[30px] font-banscrift font-bold ">
                  Add Story Page
                </h1>
              </div>
            </MainCard>
            <MainCard extra={"mt-4 pt-8 pb-14 px-8"}>
              <Form>
                <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                  <TextField
                    name="title"
                    onChange={(e) => {
                      props.handleChange(e);
                      setTitle(e.target.value);
                    }}
                    type="text"
                    label="title "
                  />
                  <TextField
                    name="postby"
                    onChange={(e) => {
                      props.handleChange(e);
                      setPostby(e.target.value);
                    }}
                    type="text"
                    label="postby"
                  />

                  <TextField
                    name="location"
                    onChange={(e) => {
                      props.handleChange(e);
                      setLocation(e.target.value);
                    }}
                    type="text"
                    label="location"
                  />
                  <TextField
                    name="photolocation"
                    type="text"
                    label="photo location"
                    onChange={(e) => {
                      props.handleChange(e);
                      setPhotolocation(e.target.value);
                    }}
                  />
                  <TextField
                    name="date"
                    type="date"
                    label="posted date "
                    onChange={(e) => {
                      props.handleChange(e);
                      setDate(e.target.value);
                    }}
                  />

                  {/* <div className="mb-4">
                    <label
                      className={`text-sm text-bluePrimary dark:text-white mb-2 `}
                    >
                      Visiblity
                    </label>
                    <select
                      className="outline-none dark:bg-navy-800 dark:!border-navy-700 border text-sm flex items-center justify-center border-lightPrimary w-full p-3 full rounded-xl"
                      name="hidden"
                      onChange={(e) => {
                        setHidden(e.target.value);
                      }}
                      id=""
                      defaultValue="false"
                    >
                      <option value="false">Show</option>
                      <option value="true">Hide</option>
                    </select>
                  </div> */}

                  <Select
                    name="hidden"
                    type="select"
                    label=" Visiblity "
                    onChange={(e) => {
                      props.handleChange(e);
                      setHidden(e.target.value);
                    }}
                    options={dropDownOption}
                  />

                  {/* <TextField
                    name="hidden"
                    type="select"
                    label="posted date "
                    onChange={(e) => {
                      props.handleChange(e);
                      setHidden(e.target.value);
                    }}
                  /> */}
                  {/* <TextField
                    name="file"
                    type="file"
                    label="flle  "
                    onChange={(e) => {
                      props.handleChange(e);
                      setFile(e.target.files[0]);
                    }}
                  /> */}

                  <div className="flex flex-col items-center w-full mb-3 gap-x-4 md:flex-row">
                    <div className="w-full">
                      <label
                        className={`text-base block text-bluePrimary dark:text-white mb-3 `}
                      >
                        photo Preview
                      </label>
                      <div className="">
                        <img
                          src={avatarPreview}
                          width={55}
                          alt="old pic"
                          height={55}
                          className="object-cover rounded-2xl "
                        />
                      </div>
                    </div>
                    {/* <input type="file" name="file" onChange={onChange} /> */}
                    <TextField
                      name="file"
                      type="file"
                      label="flle  "
                      onChange={(e) => {
                        props.handleChange(e);
                        // setFile(e.target.files[0]);
                        reader.onload = () => {
                          if (reader.readyState === 2) {
                            setAvatarPreview(reader.result);
                          }
                        };
                        reader.readAsDataURL(e.target.files[0]);
                        setFile(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
                <div className="mt-3 rounded-xl ">
                  <label
                    className={`text-sm text-bluePrimary dark:text-white mb-2 `}
                  >
                    Post Description
                  </label>

                  {/* <ReactQuill theme='snow' className='h-[420px] ' onChange={setValue} /> */}
                  <EditorToolbar toolbarId={"t1"} />
                  <ReactQuill
                    className="h-[500px] text-2xl"
                    theme="snow"
                    onChange={setValue}
                    placeholder={"Write post content here..."}
                    modules={modules("t1")}
                    formats={formats}
                  />
                </div>
                <div className="my-10"></div>
                <div className="flex justify-end w-full mt-4">
                  <button
                    type="submit"
                    disabled={submitting}
                    className="px-4 py-1 text-white bg-blue-900 rounded-lg"
                  >
                    {submitting ? "Publishing..." : "Publish"}
                  </button>
                  <button
                    type="reset"
                    className="px-4 py-1 ml-3 text-white bg-red-900 rounded-lg"
                  >
                    Reset
                  </button>
                </div>
              </Form>
            </MainCard>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default AddStoryValidate;
