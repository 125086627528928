/** @format */

import axios from "axios";
import { useEffect, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import MainCard from "components/cards";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { toast } from "react-toastify";
import photo from "assets/image/image1.png";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const UserDetails = () => {
  const [user, setUser] = useState({});
  const navigate = useNavigate();

  const location = useLocation();
  const userId = location.pathname.split("/")[3];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_Backend_API}/api/users/${userId}`
        );
        setUser(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [userId]);

  const handleDelete = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_Backend_API}/api/users/${userId}`
      );
      toast.success("User deleted successfully!");
      navigate("/admin/users");
    } catch (err) {
      console.log(err);
    }
  };

  const MySwal = withReactContent(Swal);
  const popUpDelete = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete();
      }
    });
  };

  const userphoto = `${process.env.REACT_APP_Backend_API}/upload/profile/`;

  return (
    <div>
      <MainCard extra={"flex items-center  justify-between h-[100px] px-8  "}>
        <Link to="/admin/users">
          <h1 className="text-textSecondary flex items-center gap-2 uppercase text-[30px] font-banscrift font-bold ">
            <AiOutlineArrowLeft />
            Back
          </h1>
        </Link>
      </MainCard>
      <MainCard extra=" dark:bg-dark w-full mt-5 pb-14 px-14 pt-8 rounded-[20px] bg-white">
        {/* header */}
        <div className="flex items-center gap-3">
          <h1 className="text-textSecondary font-banscrift dark:text-white text-[20px] font-bold">
            User Name :
          </h1>
          <h1 className="text-textSecondary font-banscrift dark:text-white text-[20px] font-bold">
            {user.username}
          </h1>
        </div>
        <div className="grid w-full grid-cols-2 gap-3 mt-3 full">
          <div>
            <div className="flex items-center gap-3">
              <h1 className="text-textSecondary font-banscrift dark:text-white text-[20px] font-bold">
                User Email :
              </h1>
              <h1 className="text-textSecondary font-banscrift dark:text-white text-[20px] font-bold">
                {user.email}
              </h1>
            </div>
            <div className="flex items-center gap-3">
              <h1 className="text-textSecondary font-banscrift dark:text-white text-[20px] font-bold">
                User Role :
              </h1>
              <h1 className="text-textSecondary font-banscrift dark:text-white text-[20px] font-bold">
                {user.role}
              </h1>
            </div>
          </div>
          <div className="w-full h-full">
            <img
              src={userphoto + user.image}
              className="w-full h-full rounded-md"
              alt=""
            />
          </div>
        </div>

        <div className="flex items-center justify-end gap-5 mt-12">
          <button
            onClick={popUpDelete}
            className="px-4 py-1 text-white transition-colors duration-200 bg-red-800 rounded-lg hover:bg-red-900"
          >
            Delete Policy
          </button>

          <Link to={`/admin/users/updateuser/${userId}`}>
            <button className="px-4 py-1 text-white transition-colors duration-200 bg-blue-800 rounded-lg hover:bg-blue-900">
              Update User
            </button>
          </Link>
        </div>
      </MainCard>
    </div>
  );
};

export default UserDetails;
