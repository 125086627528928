/** @format */

import axios from "axios";
import MainCard from "components/cards";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { columnsDataPolicy } from "./variables/columnsDataPolicy";
import UsersTable from "./UsersTable";

const UsersPage = () => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_Backend_API}/api/users/`
        );
        setUsers(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="w-full ">
      <MainCard extra={"flex items-center justify-between h-[100px] px-8  "}>
        <button>
          <Link
            className="px-3 py-[16px] rounded-xl text-2xl font-banscrift font-bold text-white bg-grayPrimary hover:bg-gray-500 uppercase "
            to="/admin/register"
          >
            Register User
          </Link>
        </button>
        <h1 className="text-textSecondary uppercase text-[30px] font-banscrift font-bold ">
          Users Page
        </h1>
      </MainCard>
      <ToastContainer position="top-center" />
      {/* post list */}
      <div>
        <UsersTable tableData={users} columnsData={columnsDataPolicy} />
      </div>
    </div>
  );
};

export default UsersPage;
