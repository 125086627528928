/** @format */

import IntegrityColor from "components/icons/IntegrityColor";
import Navbars from "components/navbar/Navbars";
import PolicyHeader from "./PolicyHeader";
import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
const PolicyDetail = () => {
  const [policy, setPolicy] = useState({});
  const location = useLocation();
  const policyId = location.pathname.split("/")[3];
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_Backend_API}/api/policy/${policyId}`
        );
        setPolicy(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [policyId]);
  const policyfile = `${process.env.REACT_APP_Backend_API}/upload/policyfile/file/`;
  const policyphoto = `${process.env.REACT_APP_Backend_API}/upload/policyfile/cover/`;

  return (
    <div className="w-full h-full pb-20 dark:bg-darkMain ">
      <div>
        <Navbars
          textColor={"text-textPrimary"}
          bg="bg-colorWhite dark:bg-darkHeader"
          logo={<IntegrityColor />}
        />
      </div>
      <div>
        {" "}
        <PolicyHeader />{" "}
      </div>
      {/* content */}

      <div className="max-w-screen-xl dark:bg-dark mx-auto mt-14 px-14 py-8 rounded-[20px] bg-colorWhite">
        {/* header */}
        <h1 className="text-textSecondary font-banscrift dark:text-white text-[30px] font-bold">
          {policy.title}
        </h1>
        <div className="grid w-full grid-cols-1 gap-3 mt-3 lg:grid-cols-2 full">
          <div className="w-full h-full mb-8 lg:mb-0">
            <p className="text-[18px] dark:text-white font-calibriregular ">
              {policy.description}
            </p>
            {/* download button */}
            <div className="mt-10">
              <a
                download={`${policy.file}`}
                href={policyfile + policy.file}
                className="px-4 py-3 mt-5 text-white transition-all duration-200 dark:text-white dark:bg-darkHeader dark:hover:bg-darkMain rounded-xl bg-textPrimary hover:bg-textSecondary"
              >
                {" "}
                Download File
              </a>
            </div>
          </div>
          <div className="w-full h-full">
            <img
              src={policyphoto + policy.cover}
              className="w-full h-full rounded-md"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolicyDetail;
