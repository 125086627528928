/** @format */

import React, { useEffect, useState } from "react";
import Paragraph from "./Paragraph";
import Volunteer from "components/displayData/Volunteer";

function InfrastractureMethodology() {
  const [infrast, setInfrast] = useState([]);
  const fetchPosts = async () => {
    const response = await fetch(
      "https://api.communitymonitoring.org/api/external/sites-monitored"
    );
    const data = await response.json();

    setInfrast(data.cbmi);
  };

  useEffect(() => {
    fetchPosts();
  }, []);
  return (
    <>
      <div className='flex flex-col items-center w-full h-full pb-10'>
        {/* header */}
        <h1 className=' text-[24px] dark:text-white px-4 lg:px-0 text-left lg:text-[30px] uppercase text-grayDark2 lg:text-center font-bold'>
          The methodology of{" "}
          <span className=''>
            {" "}
            <br /> Community Based Monitoring of Infrastructure (CBM-I)
          </span>
        </h1>
        {/* main content */}
        <div className='px-4 mt-4 lg:px-10'>
          <Paragraph
            line='h-[100px]'
            title='Community Mobilization'
            description='The Community-Based Monitoring of Infrastructure (CBM-I) program draws on community participation, including that of Community Development Councils and civil society actors, to establish the legitimacy of its objectives. Having gained appropriate permissions from the local authority, Integrity Watch staff meet with a chosen community to explain CBM-I and its benefits. The community then holds a meeting to elect an Integrity Volunteer, who should live within walking distance of the construction site. The Integrity Volunteer should preferably be literate, of good reputation within the community, and able to devote several hours each week to monitoring.'
          />
          <Paragraph
            line='h-[100px]'
            title='Training of Integrity Volunteers'
            description='After their election, all Integrity Volunteers receive social and technical training from Integrity Watch staff. Social training covers the basic concepts of corruption awareness, community mobilization, power mapping, effective communication and problem-solving. Technical training covers the program flowchart and guidelines, monitoring tools, survey questionnaires, checklists, and data collection. The volunteers also learn how to conduct meetings, identify problems, and seek solutions.'
          />
          <Paragraph
            line='h-[100px]'
            title='Information Collection and Baseline Survey'
            description='Once trained, the Integrity Volunteer requests and obtains all the relevant documents from the project implementor, government officials or related directorate. The Integrity Volunteer then conducts a baseline survey of the community, interviewing 20 people over the age of 18 who are stakeholders (as direct or indirect beneficiaries) in the local infrastructure project. This survey helps introduce the monitoring program to the community and collects information on local knowledge and participation in project monitoring.'
          />
          <Paragraph
            line='h-[100px]'
            title='Monitoring the Infrastructure Project'
            description='The Integrity Volunteer visits the local infrastructure project once a week and meets with the site engineer and others responsible for the ongoing work. The Integrity Volunteer monitors the environment, labor safety, and quality of construction materials and fills out the monitoring checklist. The volunteer shares findings and any problems found with the implementor and tries to resolve issues directly.
          '
          />
          <Paragraph
            line='h-[100px]'
            title='Data reporting '
            description='Staff at the provincial office transfer data from weekly and quarterly monitoring forms to IWA’s database. Monthly feedback sheets are prepared from the recorded information.'
          />
          <Paragraph
            line='h-[100px]'
            title='Community Feedback Meetings'
            description='The Integrity Volunteer shares monitoring findings with community members on a monthly basis, aiming to keep them engaged and updated about the CBM-I program and their interactions with the contractor. Engaging the local community helps ensure that the local infrastructure project is delivered to a good quality and standard.'
          />

          <Paragraph
            line='h-[100px]'
            title='Sectoral Monitoring Group Meetings'
            description='Hosted by IWA or implementing partner staff, the SMG comprises line ministry representatives, Integrity Volunteers, Provincial Council members, media, aid actors, and contractor representatives. The SMG is a higher-level advocacy platform to address irregularities that have not been resolved at the community level. An SMG delegation will occasionally make site visits to infrastructure projects to help ensure that what has been promised is delivered to the communities.'
          />
          <Paragraph
            line='h-[100px]'
            title='Integrity Volunteers Meeting'
            description='CBM-I Integrity Volunteers meet together once a month. Hosted by the project implementer, this meeting allows Integrity Volunteers to encourage each other, exchange ideas, determine common challenges, and jointly seek solutions. '
          />
          <Paragraph
            title='Endline Survey'
            description='This program concludes with a survey to gauge CBM-I program impact and effectiveness. The outcome of this survey is used as input for evaluating the program’s success overall, before full hand-over to the community of the CBM-I initiative.'
          />
          <div className='flex items-center justify-center w-full my-10'>
            <div className='w-1/2 bg-grayDark  h-[1px]'></div>
          </div>
          <Paragraph
            description={`Established in 2007, CBM-I is a flagship program that has so far been used to monitor ${infrast["sites-monitored"]} infrastructure and development projects in ten provinces (Balkh, Herat, Nangarhar, Kapisa, Laghman, Parwan, Panjsher, Kunduz, Badakhshan, and Bamyan). The program has involved ${infrast["volunteers"]} Integrity Volunteers and achieved an impressive ${infrast["fixrate"]} problem fix rate.`}
          />
        </div>
        <p></p>
      </div>

      {/* data from communitymonitoring */}
      <div className='h-full max-w-screen-xl mx-auto mt-8'>
        <Volunteer
          volunteers={infrast.volunteers}
          problemsIdentified={infrast.problems}
          problemsSolved={infrast["problems-solved"]}
          sitesMonitored={infrast["sites-monitored"]}
        />
      </div>
    </>
  );
}

export default InfrastractureMethodology;
