/** @format */

export const columnsDataUsersOverview = [
  {
    Header: "Id",
    accessor: "id",
  },
  {
    Header: "Title",
    accessor: "title",
  },

  {
    Header: "Posted",
    accessor: "posted",
  },
  {
    Header: "Location",
    accessor: "location",
  },
  {
    Header: "Closing",
    accessor: "closing",
  },
];
