/** @format */
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import React, { useEffect, useState } from "react";
import { MdOutlineDateRange } from "react-icons/md";
import axios from "axios";
import { useLocation } from "react-router-dom";
import moment from "moment";
import Card1 from "components/cards/Card1";

function Content() {
  const [post, setPost] = useState({});

  const location = useLocation();
  const postId = location.pathname.split("/")[2];
  console.log(postId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_Backend_API}/api/posts/${postId}`
        );
        setPost(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [postId]);

  // all posts

  const [posts, setPosts] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_Backend_API}/api/posts/`,
          {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": process.env.REACT_APP_Backend_API,
          }
        );
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const photo = `${process.env.REACT_APP_Backend_API}/upload/stories/`;

  return (
    <div>
      <div className="h-full max-w-screen-xl px-6 mx-auto mt-10 lg:px-0 ">
        {/* img */}
        <div>
          <div className="w-full  lg:min-h-[600px] h-full bg-no-repeat bg-cover rounded-lg">
            {post.file && (
              <img
                alt="phot"
                src={photo + post.file}
                className="object-cover w-full h-full bg-norepeat rounded-xl "
              />
            )}
          </div>
          <div className="flex items-center gap-2 mt-4">
            <div className="text-xl text-grayDark2 dark:text-white ">
              <MdOutlineDateRange />
            </div>
            <h1 className="text-grayDark2 dark:text-white text-[20px] ">
              {" "}
              {moment(post.date).format("MMMM DD YYYY")}{" "}
            </h1>
          </div>
        </div>

        {/* content */}
        <div className="mt-4">
          <h1 className=" text-textPrimary dark:text-white w-full text-[30px] lg:text-[50px] font-bold lg:leading-[60px] ">
            {post.title}
          </h1>
          <div
            className="mt-4 text-xl font-calibriregular dark:text-white text-grayDark3 "
            dangerouslySetInnerHTML={{ __html: post.description }}
          />

          {/* by */}
          <div className="mt-20 mb-28">
            <div className="flex items-center">
              {/* <h1 className="text-[24px] font-bold dark:text-white text-grayDark2 ">
                By :{" "}
              </h1> 
              <h1 className=" text-[24px]  ml-1 dark:text-white text-grayDark2 ">
                {post.postby}
              </h1>*/}
            </div>
            <div className="flex items-center">
              <h1 className="text-[24px] font-bold dark:text-white text-grayDark2 ">
                Photo:{" "}
              </h1>
              <h1 className=" text-[24px] ml-1 dark:text-white text-grayDark2 ">
                {post.photolocation}{" "}
              </h1>
            </div>
            <div className="flex items-center">
              <h1 className="text-[24px] dark:text-white font-bold text-grayDark2 ">
                Location:
              </h1>
              <h1 className=" text-[24px] dark:text-white ml-1 text-grayDark2 ">
                {post.location}{" "}
              </h1>
            </div>
          </div>
        </div>
      </div>
      {/* similar stories */}
      <div className="px-4 mx-auto max-w-screen-2xl">
        <h1 className=" uppercase text-[28px] dark:text-white  md:text-[46px] font-bold text-textPrimary ">
          Latest stories
        </h1>
        <div className="flex items-center justify-center w-full pt-10 h-[700px] pb-20">
          <Swiper
            className="py-10"
            // install Swiper modules
            loop={true}
            modules={[Navigation, Pagination, Scrollbar]}
            spaceBetween={20}
            slidesPerView={3}
            pagination={{ clickable: true }}
            scrollbar={false}
            onSwiper={(swiper) => console.log(swiper)}
            centerSlide={true}
            fade={true}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },

              768: {
                slidesPerView: 2,
              },
              950: {
                slidesPerView: 3,
              },
            }}
          >
            {posts.map(
              (post) =>
                post.hidden === "false" && (
                  <SwiperSlide className="pt-4 pb-14">
                    <Card1
                      to={`/ourstories/${post.id}`}
                      title={post.title}
                      date={post.date}
                      image={photo + post.file}
                    />
                  </SwiperSlide>
                )
            )}
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default Content;
