/** @format */

import { Navigate, Route, Routes } from "react-router-dom";
import Admin from "layout/admin";
import WebPage from "layout/Home";
import Login from "layout/Auth";
import ScrollToTop from "components/scrollToTop";

import { AuthContext } from "context/authConext";
import { useContext } from "react";

const App = () => {
  const { currentUser } = useContext(AuthContext);
  return (
    <div className={`w-full dark:bg-black h-full`}>
      <ScrollToTop>
        {/* routes  */}
        <Routes>
          <Route
            path='admin/*'
            element={currentUser ? <Admin /> : <Navigate to={"/login"} />}
          />
          <Route path='/*' element={<WebPage />} />
          <Route path='/login' element={<Login />} />
        </Routes>
      </ScrollToTop>
    </div>
  );
};

export default App;
