/** @format */

import Integrity from "components/icons/Integrity";
import Navbars from "components/navbar/Navbars";
import { useEffect, useState } from "react";
import Header from "./components/Header";
import Monitoring from "./components/Monitoring";
import Stories from "./components/Stories";
import WhatWeDo from "./components/WhatWeDo";
import { CircleLoader } from "react-spinners";
import FooterValidation from "components/footer/FooterValidate";

const Home = () => {
  const [isloading, setIsloading] = useState(false);

  useEffect(() => {
    const loading = () => {
      setIsloading(false);
    };
    setTimeout(loading, 1000);
  }, []);

  return (
    <div className="w-full h-full ">
      {isloading ? (
        <div className="flex items-center justify-center w-full h-screen">
          <CircleLoader color="#36d7b7" />
        </div>
      ) : (
        <div>
          {/* header */}

          <div className="">
            <header className="">
              <div>
                <Navbars
                  bg="bg-textPrimary dark:bg-dark"
                  textColor="text-white"
                  logo={<Integrity color="#1AA8B8" />}
                />
              </div>
              <Header />
            </header>
          </div>
          <div>
            <div className="sweet-loading"></div>
          </div>

          <main className="dark:bg-darkMain">
            <div>
              <WhatWeDo />
            </div>
            <div className="">
              <Stories />
            </div>
            <div>
              <Monitoring />
            </div>
          </main>
        </div>
      )}
    </div>
  );
};

export default Home;
