/** @format */

import parse from "html-react-parser";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import MainCard from "components/cards";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { MdOutlineDateRange } from "react-icons/md";
import ReactQuill from "react-quill";

const SinglePostAdmin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const postId = location.pathname.split("/")[3];
  const [post, setPost] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_Backend_API}/api/posts/${postId}`
        );
        setPost(res.data);
        setFile(res.file);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [postId]);

  const handleDelete = async () => {
    try {
      {
        await axios.delete(
          `${process.env.REACT_APP_Backend_API}/api/posts/${postId}`
        );
        toast.success("Story deleted successfully!");
        navigate("/admin/stories");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const photo = `${process.env.REACT_APP_Backend_API}/upload/stories/`;
  const [open, setOpen] = useState(false);

  // popup
  const MySwal = withReactContent(Swal);
  const popUpDelete = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete();
      }
    });
  };

  // popup for Image Update
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [file, setFile] = useState();
  const [isError, setIsError] = useState("");
  const MySwalImage = withReactContent(Swal);
  const popUpUpdate = async () => {
    const { value: file } = await MySwalImage.fire({
      title: "Select image",
      input: "file",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update it!",
      inputAttributes: {
        accept: "image/*",
        "aria-label": "Upload your profile picture",
        name: "file",
        keys: "file",
      },
    });
    if (file) {
      var formData = new FormData();
      formData.append("file", file);
      try {
        const data = await axios.put(
          `${process.env.REACT_APP_Backend_API}/api/posts/image/${postId}`,
          formData,
          {
            "Content-Type": "multipart/form-data",
          }
        );
        if (data.status === 200) {
          toast.success("Story image updated successfully!");
          navigate("/admin/stories");
          const reader = new FileReader();
          reader.onload = (e) => {
            Swal.fire({
              title: "Your uploaded picture",
              imageUrl: e.target.result,
              imageAlt: "The uploaded picture",
            });
            setFile(file);
          };
          reader.readAsDataURL(file);
        } else {
          Swal.fire({
            title: "Invalid File Type and Size",
            text: "Type should be (jpg, png , jpeg, svg) and Size should less than 1mb :)",
            icon: "error",
          });
        }
      } catch (error) {
        // console.log("error is here :" + error);
        setIsError("Please fill all inputs :", error);
      }
      // handleClickImage();
    }
  };

  //Image update

  const handleClickImage = async (e) => {
    setIsSubmitting(true);
    var formData = new FormData();
    formData.append("file", file);
    try {
      await axios.put(
        `${process.env.REACT_APP_Backend_API}/api/posts/image/${postId}`,
        formData,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      toast.success("Story image updated successfully!");
      navigate("/admin/stories");
    } catch (error) {
      // console.log("error is here :" + error);
      setIsError("Please fill all inputs :", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <MainCard extra={"flex items-center justify-between h-[100px] px-8  "}>
        <Link to="/admin/stories">
          <h1 className="text-textSecondary flex items-center gap-2 uppercase text-[30px] font-banscrift font-bold ">
            <AiOutlineArrowLeft />
            Back
          </h1>
        </Link>
      </MainCard>
      <MainCard extra={"w-full pb-14 h-full mt-4 p-4"}>
        {/* content */}
        <div className="h-full max-w-screen-xl px-6 mx-auto mt-10 lg:px-0 ">
          {/* img */}
          <div>
            <div className="w-full flex items-center justify-center lg:min-h-[400px] h-full bg-no-repeat bg-cover rounded-lg">
              {post.file && (
                <img
                  alt="phot"
                  src={photo + post.file}
                  className="object-cover w-[400px] h-[400px] bg-norepeat rounded-xl "
                />
              )}
            </div>
            <div className="flex items-center gap-2 mt-4">
              <div className="text-xl text-grayDark2 dark:text-white ">
                <MdOutlineDateRange />
              </div>
              <h1 className="text-grayDark2 dark:text-white text-[20px] ">
                {" "}
                {moment(post.date).format("MMMM DD YYYY")}{" "}
              </h1>
            </div>
          </div>

          {/* content */}
          <div className="mt-4">
            <h1 className=" text-textPrimary dark:text-white text-[30px] lg:text-[50px] font-bold lg:leading-[60px] ">
              {post.title}
            </h1>

            {/* <div
                className="mt-4 text-xl font-calibriregular dark:text-white text-grayDark3 "
                dangerouslySetInnerHTML={{
                  __html: post.description,
                }}
              /> */}
            <div>{parse(`${post.description}`)}</div>

            {/* by */}
            <div className="mt-20 mb-28">
              <div className="flex items-center">
                <h1 className="text-[24px] font-bold dark:text-white text-grayDark2 ">
                  By :{" "}
                </h1>
                <h1 className=" text-[24px]  ml-1 dark:text-white text-grayDark2 ">
                  {post.postby}
                </h1>
              </div>
              <div className="flex items-center">
                <h1 className="text-[24px] font-bold dark:text-white text-grayDark2 ">
                  Photo :{" "}
                </h1>
                <h1 className=" text-[24px] ml-1 dark:text-white text-grayDark2 ">
                  {post.photolocation}{" "}
                </h1>
              </div>
              <div className="flex items-center">
                <h1 className="text-[24px] dark:text-white font-bold text-grayDark2 ">
                  Location :
                </h1>
                <h1 className=" text-[24px] dark:text-white ml-1 text-grayDark2 ">
                  {post.location}{" "}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </MainCard>
      <MainCard extra={"flex items-center justify-end h-[100px] px-8 mt-5 "}>
        <div className="flex items-center justify-end gap-5">
          <button
            onClick={popUpDelete}
            className="px-4 py-1 text-white transition-colors duration-200 bg-red-800 rounded-lg hover:bg-red-900"
          >
            Delete Post
          </button>
          <button
            onClick={popUpUpdate}
            className="px-4 py-1 text-white transition-colors duration-200 bg-blue-800 rounded-lg hover:bg-blue-900"
          >
            Update Image
          </button>

          <Link to={`/admin/stories/updatestory/${postId}`}>
            <button className="px-4 py-1 text-white transition-colors duration-200 bg-blue-800 rounded-lg hover:bg-blue-900">
              Update Post
            </button>
          </Link>
        </div>
      </MainCard>
    </div>
  );
};

export default SinglePostAdmin;
