/** @format */

import React, { useEffect, useState } from "react";
import Paragraph from "./Paragraph";
import Volunteer from "components/displayData/Volunteer";

function ExtractiveMethodology() {
  const [extractive, setExtractive] = useState([]);
  const fetchPosts = async () => {
    const response = await fetch(
      "https://api.communitymonitoring.org/api/external/sites-monitored"
    );
    const data = await response.json();

    setExtractive(data.cbme);
    console.log(data);
  };

  useEffect(() => {
    fetchPosts();
  }, []);
  return (
    <>
      <div className='flex flex-col items-center w-full h-full pb-10'>
        {/* header */}
        <h1 className=' text-[24px] dark:text-white px-4 lg:px-0 text-left lg:text-[30px] uppercase text-grayDark2 lg:text-center font-bold'>
          The methodology of{" "}
          <span className=''>
            {" "}
            <br /> Community Based Monitoring of Extractives
          </span>
        </h1>
        {/* main content */}
        <div className='px-4 mt-4 lg:px-10'>
          <Paragraph
            line='h-[100px]'
            title='Coordination'
            description='In the initial phase, CBM-E program staff approach the relevant government department to access a list of forthcoming mining projects. Upon reviewing the contracts, program managers make a selection according to basic criteria of publicly-funded projects that are accessible (including in terms of health, safety, and security) and have potentially significant value for money.
          '
          />
          <Paragraph
            line='h-[100px]'
            title='Community Mobilization'
            description='IWA conducts community resource mapping to (i) identify the communities that will be affected by extractive projects and (ii) gauge interest in CBM-E participation. Communities close to an extractive site receive general information on Integrity Watch and the CBM-E program. Integrity Watch then begins mobilizing participating communities. Three to four communities elect an Integrity Volunteer who is literate and known in their community for their integrity. The Integrity Volunteer needs to live close to the site and have appropriate time to commit to the CBM-E program.'
          />
          <Paragraph
            line='h-[100px]'
            title='Training of Integrity Volunteers'
            description='Elected Integrity Volunteers are given two days of training on community-based monitoring of extractives. This includes subjects of integrity and transparency in the community; types and effects of corruption; and responsibilities of the community, government, and CSOs regarding extractives. The volunteers are also given technical skills training, including how to undertake surveys and fill out the weekly monitoring form (checklist).'
          />
          <Paragraph
            line='h-[100px]'
            title='Baseline Survey and Outreach events'
            description='One of the first tasks of the Integrity Volunteer is to conduct a baseline survey to measure their community’s understanding of its rights to monitor and review government-funded services and projects. Integrity Volunteers also run community outreach events to raise awareness regarding the mining site and contract, and CBM in general.'
          />
          <Paragraph
            line='h-[100px]'
            title='Monitoring of the mining site'
            description='Integrity Volunteers monitor the extractive site on weekly basis and share and discuss findings with a senior mining company representative. The Integrity Volunteers may directly request the company to address various problems found (e.g. a lack of workers’ safety clothes or first aid kits at the site). Each Integrity Volunteer submits a completed weekly monitoring checklist to the Integrity Facilitator, who enters form data into Integrity Watch’s database. '
          />
          <Paragraph
            line='h-[100px]'
            title='Community Feedback Meetings'
            description='The Integrity Volunteer shares monitoring findings with the local community on a monthly basis to keep the community engaged and updated about the CBM-E program. They disclose any irregularities found relating to waste management, the environment, water, and other mining activities. They also discuss their interactions with the contractor and report on the contractor’s willingness or reluctance to take corrective actions.'
          />

          <Paragraph
            line='h-[100px]'
            title='Sectoral Monitoring Group Meetings'
            description='Hosted by IWA or the implementing partner, the Sectorial Monitoring Group (SMG) addresses irregularities that have not been resolved at the community level. The SMG comprises government representatives, the contractor, community elders, CSOs, and Integrity Volunteers. Any issues that cannot be resolved through SMG advocacy are forwarded to the Provincial Integrity Network (PIN) to be advocated by a larger group of local civil society actors at the provincial level with the relevant authorities.'
          />
          <Paragraph
            line='h-[100px]'
            title='Integrity Volunteers’ Coordination Meeting'
            description='Integrity Watch or the implementing partner facilitates this meeting once a month for Integrity Volunteers to share experiences, ideas, fixes, and lessons learned. Integrity Volunteers also prepare their advocacy plans for the upcoming month and receive instructions and advice from the meeting facilitator. '
          />
          <Paragraph
            title='Endline Survey'
            description='The facilitated CBM-E program concludes with a survey to gauge program impact and effectiveness. The outcome of this survey is used as input for evaluating the program’s success overall, before full hand-over to the community of the CBM-E initiative.'
          />
          <div className='flex items-center justify-center w-full my-10'>
            <div className='w-1/2 bg-grayDark  h-[1px]'></div>
          </div>
          <Paragraph
            description={`
          Integrity Watch believes that with background support from CSOs, people can monitor services, identify deficiencies, and hold to account contractors with little government involvement. Since 2014, Integrity Watch Afghanistan has implemented CBM-E programming in Kabul, Panjsher, Parwan, Balkh, Baghlan, and Takhar provinces. The program has promoted good governance of the mining sector, with communities seeking to ensure that extractive activities are being carried out in a transparent manner and with minimum environmental impact. The program has so far engaged ${extractive["volunteers"]} volunteers in the monitoring of ${extractive["sites-monitored"]} mining sites. 
          `}
          />
        </div>
        <p></p>
      </div>
      {/* data from communitymonitoring */}
      <div className='h-full max-w-screen-xl mx-auto mt-8'>
        <Volunteer
          volunteers={extractive.volunteers}
          problemsIdentified={extractive.problems}
          problemsSolved={extractive["problems-solved"]}
          sitesMonitored={extractive["sites-monitored"]}
        />
      </div>
    </>
  );
}

export default ExtractiveMethodology;
