import React from "react";
import { Field, ErrorMessage } from "formik";

const Select = ({ label, name, options, ...props }) => {
  return (
    <div className=" w-full border-none relative mb-2  rounded-[10px]  ">
      <label htmlFor={name}>{label} </label>
      <div>
        <Field
          className={`w-full rounded-lg `}
          as="select"
          id={name}
          name={name}
          {...props}
        >
          {options.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.key}{" "}
              </option>
            );
          })}
        </Field>
      </div>

      <ErrorMessage component="div" className="text-red-500" name={name} />
    </div>
  );
};

export default Select;
