/** @format */

import { Link, useNavigate } from "react-router-dom";
import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import moment from "moment";

function BidsPageTable(props) {
  const { columnsData, tableData, nextClick, prevClick } = props;
  const navigate = useNavigate();

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,
    nextPage,
    previousPage,
    setPageSize,

    setGlobalFilter,
    state,
  } = tableInstance;

  const { pageSize } = state;

  return (
    <div className="w-full h-full rounded-xl dark:bg-dark bg-colorWhite">
      {/* table */}
      <div className="h-full px-1 mt-8 overflow-x-scroll lg:px-8 lg:overflow-x-hidden">
        {/* Search */}
        <div
          onChange={(e) => setGlobalFilter(e.target.value)}
          className="flex w-full md:w-[400px] items-center rounded-xl p-[8px]"
        >
          <input
            type="text"
            placeholder="Search Jobs Here..."
            className="outline-none dark:text-white dark:bg-darkMain dark:border-darkMain text-grayPrimary focus:outline-none focus:border-grayPrimary placeholder:text-sm bg-colorWhite placeholder:font-banscrift w-full placeholder:text-grayPrimary rounded-[10px] border-grayPrimary"
          />
        </div>
        <table
          {...getTableProps()}
          className="w-[500px] overflow-x-scroll md:w-full mt-8 font-banscrift"
        >
          <thead className="">
            {headerGroups.map((headerGroup, index) => (
              <tr
                className=""
                {...headerGroup.getHeaderGroupProps()}
                key={index}
              >
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                  >
                    <div className="w-[140px] mb-4 text-sm text-left capitalize dark:text-white text-textPrimary">
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="">
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  className="w-full md:px-2 dark:border-dark dark:hover:bg-darkMain hover:bg-white hover:border hover:border-gray-300 border border-colorWhite text-grayPrimary mt-1 hover:text-textSecondary hover:cursor-pointer  transition-all dark:text-white duration-300 hover:!font-bold"
                  {...row.getRowProps()}
                  key={index}
                  onClick={() => {
                    row.cells.map((cell, index) => {
                      if (cell.column.Header === "Id") {
                        navigate(`/announcement/bids/${cell.value}`);
                      }
                    });
                  }}
                >
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "Id") {
                      data = (
                        <Link to={`/announcement/bids/${cell.value}`}>
                          <h1 className="text-sm !w-[30px]">{cell.value}</h1>
                        </Link>
                      );
                    } else if (cell.column.Header === "Title") {
                      data = (
                        <div className="text-sm line-clamp-1">{cell.value}</div>
                      );
                    } else if (cell.column.Header === "Posted") {
                      data = (
                        <div className="text-sm ">
                          {moment(cell.value).format("MM DD YYYY")}
                        </div>
                      );
                    } else if (cell.column.Header === "Location") {
                      data = (
                        <div className="text-sm text-bluePrimary">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === "Closing") {
                      data = (
                        <div className="text-sm ">
                          {/* {moment(cell.value).format("MM DD YYYY")} */}
                          {moment(new Date()).format("MM DD YYYY") >
                          moment(cell.value).format("MM DD YYYY")
                            ? "Expired"
                            : moment(cell.value).format("MM DD YYYY")}
                        </div>
                      );
                    }
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        className="items-center py-3 "
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* pagination */}
      <div className="flex justify-center items-center  pl-1 md:pl-6 mt-8 w-full h-[80px]">
        {/* left side */}
        {/* <div className="flex items-center gap-3">
          <h1 className="text-textPrimary dark:text-white text-sm > Show rows per page">
            Show rows per page{" "}
          </h1>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="border text-sm dark:border-darkMain dark:bg-darkMain dark:text-white border-textPrimary focus:border-textPrimary text-textPrimary w-[100px]  px-3 text-lightSecondary  rounded-3xl"
            name=""
            id=""
          >
            <option value="6">6</option>
            <option value="12">12</option>
            <option value="18">18</option>
            <option value="28">28</option>
            <option value="40">40</option>
          </select>
        </div> */}
        {/* right side */}
        <div className="flex items-center gap-2">
          <button
            onClick={prevClick}
            className={`flex items-center justify-center bg-grayPrimary rounded-[3px] w-[20px] h-[20px] bg-brand-500 text-white text-xl `}
          >
            <MdChevronLeft />
          </button>

          <button
            onClick={nextClick}
            className={`flex items-center justify-center bg-grayPrimary w-[20px] h-[20px] rounded-[3px] bg-brand-500 text-white text-xl `}
          >
            <MdChevronRight />
          </button>
        </div>
      </div>
    </div>
  );
}

export default BidsPageTable;
