import React from "react";

function CardData(props) {
  const { title, icon } = props;
  return (
    <div className="w-[140px] hover:scale-105 hover:cursor-pointer transition-all duration-300 ease-in-out flex flex-col items-center justify-center h-[180px] rounded-[20px] bg-[#005362] ">
      <div className="">
        <img src={icon} alt="" />
      </div>
      <div className="px-3  h-[40px] text-center mt-6">
        <h1 className="text-white text-base capitalize">{title} </h1>
      </div>
    </div>
  );
}

export default CardData;
