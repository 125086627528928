/** @format */

import React from "react";
import CardData from "./CardData";
import vector1 from "assets/About us/Who we are/Vector.png";
import vector2 from "assets/About us/Who we are/Vector (1).png";
import vector3 from "assets/About us/Who we are/Vector (2).png";
import vector4 from "assets/About us/Who we are/Vector (3).png";
import vector5 from "assets/About us/Who we are/Vector (4).png";
import vector6 from "assets/About us/Who we are/Vector (5).png";
import vector7 from "assets/About us/Who we are/Vector (6).png";
import mediaActor from "assets/About us/Who we are/mediaActor.svg";
import devPartners from "assets/About us/Who we are/devPartners.svg";

function InnerAbout() {
  return (
    <div className="w-full max-w-screen-lg mx-auto">
      <div className="">
        <div className="lg:max-w-screen-lg px-4 lg:px-3 text-center dark:!bg-darkMain h-full mt-10 lg:!mt-16  mx-auto">
          <h1 className="text-xl dark:text-white  font-calibriregular lg:px-0  lg:text-[22px] text-grayDark2 text-center  "style={{ textAlign: 'justify' }}>
            {" "}
            Integrity Watch is a not-for-profit organization seeking to promote
            greater integrity and accountability of power holders and an end to
            corruption and the abuse of power.
          </h1>
          <h1 className="text-xl dark:text-white font-calibriregular lg:px-0  lg:text-[22px] text-grayDark2 mt-7 lg:mt-14 text-center  "style={{ textAlign: 'justify' }}>
            {" "}
            We empower citizens to demand transparency and accountability in
            public services. We do this by mentoring and building the capacity
            of local communities to monitor infrastructure projects, education
            institutions, healthcare services, extractive sites, and trial
            proceedings. We also work closely with civil society organizations
            to strengthen their internal organizational practices and capacities
            to address issues relating to malpractice and corruption.
          </h1>
          <h1 className="text-xl dark:text-white  font-calibriregular lg:px-0  lg:text-[22px] text-grayDark2 mt-7 lg:mt-14 text-center " style={{ textAlign: 'justify' }}>
            {" "}
            Often in partnership with civil society actors, we conduct research
            to inform advocacy on policies and practices that lead to greater
            transparency, accountability, integrity, and public participation.
          </h1>
        </div>
        {/* scroll */}
        {/* <div className='relative mt-10 lg:mt-0'> */}
        {/* first */}
        {/* <div className='flex items-center justify-center w-full gap-10 lg:justify-start'>
            <div className=''>
              {" "}
              <img src={greater} className='w-[100px] h-[110px] ' alt='' />{" "}
            </div>
            <div className=''>
              <h1 className=' text-[#4E2F87] dark:text-white  text-[30px] font-bold '>
                greater integrity
              </h1>
              <h1 className=' text-[#4E2F87] dark:text-white  text-[30px] font-bold '>
                and accountability
              </h1>
              <h1 className=' text-[#4E2F87] dark:text-white  text-[30px] font-bold '>
                of power holders
              </h1>
            </div>
          </div> */}
        {/* line */}
        {/* <div className='h-[100px] hidden dark:bg-white lg:block ml-24 lg:ml-12 mt-4 w-[3px] bg-grayDark2 '></div> */}
        {/* second */}
        {/* <div className='flex justify-center w-full gap-10 mt-10 lg:justify-start'>
            <div className=''>
              {" "}
              <img src={user} className='w-[100px] h-[100px] ' alt='' />{" "}
            </div>{" "}
            <div className=' text-[#E46710] dark:text-white text-[28px] font-bold  '>
              <h1 className=''>Increased</h1>
              <h1 className=''>participation by</h1>
              <h1 className=''>citizens in</h1>
              <h1 className=''>decision-making</h1>
              <h1 className=''>impacting on their</h1>
              <h1 className=''>lives</h1>
            </div>
          </div> */}
        {/* line */}
        {/* <div className='h-[140px] hidden dark:bg-white lg:block absolute left-24 lg:left-12 bottom-40  w-[3px] bg-grayDark2 '></div> */}
        {/* third */}
        {/* <div className='flex items-center justify-center w-full gap-10 mt-12 dark: lg:justify-start'>
            <div className=''>
              {" "}
              <img src={vector} className='w-[100px] h-[100px]  ' alt='' />{" "}
            </div>
            <div className=''>
              <h1 className=' text-[#852711] dark:text-white  text-[30px] font-bold '>
                and an end to{" "}
              </h1>
              <h1 className=' text-[#852711] dark:text-white text-[30px] font-bold '>
                corruption and
              </h1>
              <h1 className=' text-[#852711] dark:text-white text-[30px] font-bold '>
                abuse of power
              </h1>
            </div>
          </div>
        </div> */}
      </div>
      {/* paragrap & card */}
      <div className="px-3 mt-12 ">
        <h1 className=" text-grayDark2 dark:text-white !font-calibriregular text-[22px] w-full text-center px-4 md:px-10 " style={{ textAlign: 'justify' }}>
          We will work internationally, wherever Integrity Watch can make an
          impact. We have a long history of working in some of the world’s most
          challenging development contexts, confronting all forms of corruption
          and inefficiency by engaging with and mobilizing people on the ground:
        </h1>
      </div>
      {/* Card Data */}
      <div className="w-full mt-10 ">
        <div className="grid items-center justify-center w-full grid-cols-1 gap-4 md:grid-cols-5 justify-items-center lg:flex ">
          <CardData title="Local Community" icon={vector1} />
          <CardData title="Civil Society" icon={vector2} />
          <CardData title="Media actors" icon={mediaActor} />
          <CardData title="Academia" icon={vector3} />
          <CardData title="Government officials" icon={vector4} />
        </div>
        <div className="grid items-center justify-center grid-cols-1 gap-4 mt-4 md:grid-cols-4 justify-items-center lg:flex">
          <CardData title="Judicial actors" icon={vector5} />
          <CardData title="Public representative" icon={vector6} />
          <CardData title="Development partners" icon={devPartners} />
          <CardData title=" Private sector actors" icon={vector7} />
        </div>
      </div>
      <div className="px-3 mt-12 ">
        <h1 className=" text-grayDark2 dark:text-white !font-calibriregular text-[22px] w-full text-center px-4 md:px-10 " style={{ textAlign: 'justify' }}>
          local communities, civil society, media, academia, government
          officials, judicial actors, public representatives, development
          partners, and the private sector. We have earned international
          recognition and contributed to the global fight against corruption
          through knowledge sharing and joining coalitions working on global
          integrity and anti-corruption agendas.
        </h1>
      </div>
      <div className="px-3 mt-14 ">
        <h1 className=" text-grayDark2 dark:text-white font-calibriregular text-[22px] w-full text-center px-10 " style={{ textAlign: 'justify' }}>
          Grassroots collaboration and community-driven advocacy are gaining
          ever greater momentum in the development sector. We are proud to be at
          the forefront of exploring the depth and breadth of this movement,
          firm in the belief it will build a more just, fair, and equitable
          future for all.
        </h1>
      </div>
    </div>
  );
}

export default InnerAbout;
