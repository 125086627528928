/** @format */

import axios from "axios";
import MainCard from "components/cards";
import { useState } from "react";
import { useEffect } from "react";
import ContactTable from "./ContactTable";
import { columnsDataUsersOverview } from "./variables/columnsDataUsersOverview";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/** @format */
const ContactAdmin = () => {
  const [contact, setContact] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_Backend_API}/api/contact/`
        );
        setContact(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  return (
    <div className='w-full '>
      <ToastContainer position='top-center' />
      <MainCard extra={"flex items-center h-[100px] px-8  "}>
        <ToastContainer position='top-center' />
        <h1 className='text-textSecondary uppercase text-[30px] font-banscrift font-bold '>
          Contact Page
        </h1>
      </MainCard>
      {/* contact list */}
      <div>
        <ContactTable
          tableData={contact}
          columnsData={columnsDataUsersOverview}
        />
      </div>
    </div>
  );
};

export default ContactAdmin;
