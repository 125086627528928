/** @format */
import React, { useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { useState } from "react";
import moment from "moment";

function JobsTable(props) {
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,
    nextPage,
    previousPage,
    setPageSize,

    setGlobalFilter,
    state,
  } = tableInstance;

  const { pageSize } = state;

  return (
    <div className="w-full rounded-[24px] bg-white h-full">
      {/* table */}
      <div className="h-full px-1 pt-3 mt-4 overflow-x-scroll lg:px-8 lg:overflow-x-hidden">
        {/* Search */}
        <div
          onChange={(e) => setGlobalFilter(e.target.value)}
          className="flex w-full md:w-[400px] items-center rounded-xl p-[8px]"
        >
          <input
            type="text"
            placeholder="Search Story Here..."
            className="outline-none text-grayPrimary focus:outline-none focus:border-grayPrimary placeholder:text-sm bg-colorWhite placeholder:font-banscrift w-full placeholder:text-grayPrimary rounded-[10px] border-grayPrimary"
          />
        </div>
        <table {...getTableProps()} className="w-full mt-8 font-banscrift">
          <thead className="">
            {headerGroups.map((headerGroup, index) => (
              <tr
                className=""
                {...headerGroup.getHeaderGroupProps()}
                key={index}
              >
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                  >
                    <div className="mb-4 text-sm text-left capitalize text-textPrimary">
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="">
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  className="w-full md:px-2 hover:bg-white hover:border hover:border-gray-300 border border-colorWhite text-grayPrimary mt-1 hover:text-textSecondary hover:cursor-pointer  transition-all duration-300 hover:!font-bold    "
                  {...row.getRowProps()}
                  key={index}
                >
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "Id") {
                      data = (
                        <div className="flex items-center gap-1">
                          <Link
                            className="flex gap-2 itemcs-center"
                            to={`/admin/jobs/${cell.value}`}
                          >
                            <h1 className="text-lg w-[30px] mt-2 text-bluePrimary">
                              {cell.value}
                            </h1>
                            <button className="px-4 py-2 text-white bg-blue-500 hover:bg-blue-600 rounded-xl">
                              Details
                            </button>
                          </Link>
                          {/* <Link to={`/admin/jobs/updatejob/${cell.value}`}>
                            <button className="px-4 py-2 text-white bg-grayPrimary hover:bg-gray-500 rounded-xl">
                              Edit
                            </button>
                          </Link> */}
                        </div>
                      );
                    } else if (cell.column.Header === "Title") {
                      data = (
                        <div className="text-sm line-clamp-1 text-bluePrimary">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === "Posted") {
                      data = (
                        <div className="text-sm text-bluePrimary">
                          {moment(cell.value).format("MMMM DD YYYY")}
                        </div>
                      );
                    } else if (cell.column.Header === "location") {
                      data = (
                        <div className="text-sm w-[250px] text-bluePrimary">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === "Closing") {
                      data = (
                        <div className="text-sm ">
                          {/* {moment(cell.value).format("MM DD YYYY")} */}
                          {moment(new Date()).format("MM DD YYYY") >
                          moment(cell.value).format("MM DD YYYY")
                            ? "Expired"
                            : moment(cell.value).format("MM DD YYYY")}
                        </div>
                      );
                    } else if (cell.column.Header === "vacancy") {
                      data = (
                        <div className="text-sm text-bluePrimary">
                          {cell.value}
                        </div>
                      );
                    }
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        className="items-center py-3 "
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* pagination */}
      <div className="flex justify-start items-center gap-5 pl-1 md:pl-16 mt-4 w-full h-[80px]">
        {/* left side */}
        <div className="flex items-center gap-3">
          <h1 className="text-textPrimary text-sm > Show rows per page">
            Show rows per page{" "}
          </h1>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="border text-sm border-textPrimary focus:border-textPrimary text-textPrimary w-[100px]  px-3 text-lightSecondary  rounded-3xl"
            name=""
            id=""
          >
            <option value="6">6</option>
            <option value="12">12</option>
            <option value="18">18</option>
            <option value="28">28</option>
            <option value="40">40</option>
          </select>
        </div>
        {/* right side */}
        <div className="flex items-center gap-2">
          <button
            onClick={() => previousPage()}
            className={`flex items-center justify-center bg-textPrimary w-[40px] h-[40px] rounded-full bg-brand-500 text-white text-xl `}
          >
            <MdChevronLeft />
          </button>

          <button
            onClick={() => nextPage()}
            className={`flex items-center justify-center bg-textPrimary w-[40px] h-[40px] rounded-full bg-brand-500 text-white text-xl `}
          >
            <MdChevronRight />
          </button>
        </div>
      </div>
    </div>
  );
}

export default JobsTable;
