/** @format */
import React from "react";
import { useState } from "react";
import { HiMenu } from "react-icons/hi";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { BsFillMoonStarsFill } from "react-icons/bs";
import { Link, NavLink } from "react-router-dom";

const Navbars = (props) => {
  const { bg, textColor, logo } = props;
  let Links = [
    { name: "what we do", link: "/whatwedo" },
    { name: "where we work", link: "/wherewework" },
    { name: "our stories", link: "/ourstories" },
    { name: "announcement", link: "/announcement" },
    { name: "about us", link: "/aboutus" },
    { name: "contact us", link: "/contactus" },
  ];

  let [open, setOpen] = useState(false);
  // const [openDropdown, setOpenDropdown] = useState(false);
  const [darkmode, setDarkmode] = useState(true);
  const navLinkStyle = ({ isActive }) => {
    return {
      borderBottom: isActive ? `2px solid #1AA9B8 ` : "none",
    };
  };
  return (
    <nav className={` w-full relative  px-3 lg:px-1 h-[90px] ${bg} `}>
      <div className="flex items-center justify-between w-full h-full px-6 lg:px-0 lg:max-w-screen-xl font-banscrift lg:mx-auto">
        <div className="hover:cursor-pointer dark:text-white">
          <Link className={``} to="/">
            {" "}
            {logo}
          </Link>
        </div>

        <div className="flex items-center">
          <div
            onClick={() => setOpen(!open)}
            className={`flex lg:hidden hover:cursor-pointer dark:text-white justify-around gap-2 ${textColor} text-3xl items-center`}
          >
            {open ? <HiOutlineMenuAlt3 /> : <HiMenu />}
          </div>
          <div
            className={` ${
              open ? "flex" : "hidden"
            } ${bg} justify-center items-center  dark:text-white absolute  z-30  rounded-b-xl pb-8 flex-col pt-4 top-[90px] left-0 w-full  px-6 transition-all duration-300 lg:flex-row lg:items-center  lg:top-0 lg:left-0  lg:pb-0 lg:gap-[14px] xl:gap-[24px] lg:pt-0 lg:px-0 lg:relative lg:flex  `}
          >
            {Links.map((link) => (
              <NavLink
                key={link.name}
                className={`text-lg md:text-base dark:!border-white uppercase dark:text-white w-fit py-3 lg:w-fit lg:pb-0 text-center  transition-all duration-200 hover:scale-105 ${textColor} `}
                style={navLinkStyle}
                to={link.link}
              >
                {link.name}
              </NavLink>
            ))}
            <div
             
              className={`lg:hidden text-xl ml-4 dark:text-white hover:text-[#005362] hover:cursor-pointer w-full pt-40 lg:pt-0 lg:w-fit ${textColor}`}
            >
              <BsFillMoonStarsFill />
            </div>
          </div>
          <div
            onClick={() => {
              if (darkmode) {
                document.body.classList.remove("dark");
                setDarkmode(false);
              } else {
                document.body.classList.add("dark");
                setDarkmode(true);
              }
            }}
            className={`hidden hover:scale-105 dark:text-white transition-all duration-200 lg:flex text-lg  ml-6 hover:cursor-pointer w-full mt-2 lg:pt-0 lg:w-fit lg:hover:text-[#005362] ${textColor}`}
          >
            <BsFillMoonStarsFill />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbars;
