import React from 'react'

function DateIcon() {
  return (
     <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
     <path d="M4.89267 4.05228V1.21021M10.5678 4.05228V1.21021M4.18328 6.89435H11.2772M2.76449 13.9995H12.696C13.0723 13.9995 13.4332 13.8498 13.6992 13.5833C13.9653 13.3168 14.1148 12.9554 14.1148 12.5785V4.05228C14.1148 3.67539 13.9653 3.31395 13.6992 3.04745C13.4332 2.78096 13.0723 2.63124 12.696 2.63124H2.76449C2.38821 2.63124 2.02733 2.78096 1.76126 3.04745C1.49518 3.31395 1.3457 3.67539 1.3457 4.05228V12.5785C1.3457 12.9554 1.49518 13.3168 1.76126 13.5833C2.02733 13.8498 2.38821 13.9995 2.76449 13.9995Z" stroke="#636369" stroke-width="1.23091" stroke-linecap="round" stroke-linejoin="round"/>
     </svg>
     
  )
}

export default DateIcon