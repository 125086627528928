/** @format */

import axios from "axios";
import { useEffect, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import MainCard from "components/cards";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const SingleBidAdmin = () => {
  const [bid, setBid] = useState({});
  const navigate = useNavigate();

  const location = useLocation();
  const bidId = location.pathname.split("/")[3];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_Backend_API}/api/bids/${bidId}`
        );
        setBid(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [bidId]);

  const handleDelete = async () => {
    try {
      {
        await axios.delete(
          `${process.env.REACT_APP_Backend_API}/api/bids/${bidId}`
        );
        toast.success("Bid deleted successfully!");
        navigate("/admin/bids");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const MySwal = withReactContent(Swal);
  const popUpDelete = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete();
      }
    });
  };

  // popup for Image Update
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [file, setFile] = useState();
  const [isError, setIsError] = useState("");
  const MySwalImage = withReactContent(Swal);
  const popUpUpdate = async () => {
    const { value: file } = await MySwalImage.fire({
      title: "Select Your File",
      input: "file",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update it!",
      inputAttributes: {
        accept: "file/*.pdf",
        "aria-label": "Upload your file ",
        name: "file",
        keys: "file",
      },
    });
    if (file) {
      var formData = new FormData();
      formData.append("file", file);
      try {
        const data = await axios.put(
          `${process.env.REACT_APP_Backend_API}/api/bids/file/${bidId}`,
          formData,
          {
            "Content-Type": "multipart/form-data",
          }
        );
        if (data.status === 200) {
          toast.success("Bid File updated successfully!");
          navigate("/admin/bids");
          Swal.fire({
            title: "Your file uploaded successfully!",
            imageAlt: "The uploaded picture",
          });
          setFile(file);
        } else {
          Swal.fire({
            title: "Invalid File Type and Size",
            text: "Size should less than 1mb :)",
            icon: "error",
          });
        }
      } catch (error) {
        // console.log("error is here :" + error);
        setIsError("Please fill all inputs :", error);
      }
      // handleClickImage();
    }
  };

  return (
    <div>
      <MainCard extra={"flex items-center justify-between h-[100px] px-8  "}>
        <Link to="/admin/bids">
          <h1 className="text-textSecondary flex items-center gap-2 uppercase text-[30px] font-banscrift font-bold ">
            <AiOutlineArrowLeft />
            Back
          </h1>
        </Link>
        <h1 className="text-textSecondary flex items-center gap-2 uppercase text-[30px] font-banscrift font-bold">
          Bid Details Page
        </h1>
      </MainCard>
      <MainCard extra={"mt-4 px-14 pt-8 pb-16 rounded-[20px]"}>
        {/* header */}
        <h1 className="text-textSecondary text-[30px] font-bold">
          {bid.title}
        </h1>
        {/* date */}
        <div className="grid grid-cols-3 mt-6">
          <div className="flex items-center justify-between">
            <h1 className="text-xl font-bold text-textSecondary">
              Date Posted:
            </h1>
            <p className="text-xl text-textSecondary">
              {" "}
              {moment(bid.posted).format("MMMM DD YYYY")}{" "}
            </p>
          </div>
          <div></div>
          <div className="flex items-center justify-between">
            <h1 className="text-xl font-bold text-textSecondary">
              Closing Posted:
            </h1>
            <p className="text-xl text-textSecondary">
              {" "}
              {moment(bid.closing).format("MMMM DD YYYY")}{" "}
            </p>
          </div>
          <div className="flex items-center justify-between mt-5">
            <h1 className="text-xl font-bold text-textSecondary">Location:</h1>
            <p className="text-xl text-left text-textSecondary">
              {bid.location}{" "}
            </p>
          </div>
        </div>
        {/* description */}
        <div>
          <h1 className="text-textSecondary mt-4 text-[30px] font-bold">
            Description
          </h1>
          <p className="text-[18px] mt-3 font-calibriregular">
            {bid.description}
          </p>
        </div>
      </MainCard>
      <MainCard extra={"flex mt-4 items-center justify-end h-[100px] px-8  "}>
        <div className="flex items-center justify-end gap-5">
          <button
            onClick={popUpDelete}
            className="px-4 py-1 text-white transition-colors duration-200 bg-red-800 rounded-lg hover:bg-red-900"
          >
            Delete Bid
          </button>
          <button
            onClick={popUpUpdate}
            className="px-4 py-1 text-white transition-colors duration-200 bg-blue-800 rounded-lg hover:bg-blue-900"
          >
            Update File
          </button>

          <Link to={`/admin/bids/updatebid/${bidId}`}>
            <button className="px-4 py-1 text-white transition-colors duration-200 bg-blue-800 rounded-lg hover:bg-blue-900">
              Update Bid
            </button>
          </Link>
        </div>
      </MainCard>
    </div>
  );
};

export default SingleBidAdmin;
