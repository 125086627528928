/** @format */

import axios from "axios";
import MainCard from "components/cards";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import * as Yup from "yup";
import EditorToolbar, {
  modules,
  formats,
} from "components/Editor/EditorToolbar";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import TextField from "../bidsAdmin/variables/TextField";

function AddJobValidate() {
  const ValidateSchema = Yup.object().shape({
    title: Yup.string().required("Title is Required!"),
    posted: Yup.string().required("Posted Date is Required!"),
    closing: Yup.string().required("Closing Date is Required!"),
    vacancy: Yup.number().required("Vacancy is Required!"),
    reference: Yup.string().required("Reference is Required!"),
    worktype: Yup.string().required("Work type is Required!"),
    // gender: Yup.string().required("gender is Required!"),
    nationality: Yup.string().required("nationality is Required!"),
    functionarea: Yup.string().required("functionarea is Required!"),
    constractarea: Yup.string().required("Contract Duration is Required!"),
    experience: Yup.number().required("experience is Required!"),
    languages: Yup.string().required("languages is Required!"),
    location: Yup.string().required("location is Required!"),
    // extension: Yup.string().required("extension is Required!"),
    requirement: Yup.string().required("requirement is Required!"),
    workexperience: Yup.string().required("workexperience is Required!"),
  });
  const [submitting, setSubmitting] = useState(false);

  const [title, setTitle] = useState("");
  // const [description, setDescription] = useState("");
  const [value, setValue] = useState("");
  const [posted, setPosted] = useState("");
  const [closing, setClosing] = useState("");
  const [vacancy, setVacancy] = useState("");
  const [reference, setReference] = useState("");
  const [worktype, setWorktype] = useState("");
  const [gender, setGender] = useState("");
  const [nationality, setNationality] = useState("");
  const [functionarea, setFunctionarea] = useState("");
  const [constractarea, setConstractarea] = useState("");
  const [experience, setExperience] = useState("");
  const [languages, setLanguages] = useState("");
  const [duties, setDuties] = useState("");
  const [skills, setSkills] = useState("");
  const [location, setLocation] = useState("");

  const [guidline, setGuidline] = useState("");
  const [extension, setExtension] = useState("");
  const [requirement, setRequirement] = useState("");
  const [workexperience, setWorkexperience] = useState("");
  const [showjob, setShowjob] = useState("");

  const navigate = useNavigate();

  const handleClick = async (e) => {
    // e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_Backend_API}/api/jobs/`, {
        title,
        description: value,
        posted,
        closing,
        vacancy,
        reference,
        worktype,
        gender,
        nationality,
        functionarea,
        constractarea,
        experience,
        languages,
        duties: duties,
        skills: skills,
        location,
        guidline: guidline,
        extension,
        requirement,
        workexperience,
        showjob,
      });
      toast.success("Job added successfully!");
      navigate("/admin/jobs");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Formik
        initialValues={{
          title: title,
          post: posted,
          closing: closing,
          vacancy: vacancy,
          reference: reference,
          worktype: worktype,
          // gender: gender,
          nationality: nationality,
          functionarea: functionarea,
          constractarea: constractarea,
          experience: experience,
          languages: languages,
          location: location,

          // extension: extension,
          requirement: requirement,
          workexperience: workexperience,
        }}
        validationSchema={ValidateSchema}
        onSubmit={handleClick}
      >
        {(props) => (
          <div>
            <MainCard
              extra={"flex items-center justify-between h-[100px] px-8  "}
            >
              <Link to="/admin/jobs">
                <h1 className="text-textSecondary flex items-center gap-2 uppercase text-[30px] font-banscrift font-bold ">
                  <AiOutlineArrowLeft />
                  Back
                </h1>
              </Link>
              <div>
                <h1 className="text-textSecondary flex items-center gap-2 uppercase text-[30px] font-banscrift font-bold ">
                  Add Job Page
                </h1>
              </div>
            </MainCard>
            <MainCard extra={"mt-4 pt-8 pb-14 px-8"}>
              <Form>
                <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                  <div>
                    <TextField
                      name="title"
                      onChange={(e) => {
                        props.handleChange(e);
                        setTitle(e.target.value);
                      }}
                      type="text"
                      label="title "
                    />

                    <TextField
                      name="posted"
                      type="date"
                      label="Posted date "
                      onChange={(e) => {
                        props.handleChange(e);
                        setPosted(e.target.value);
                      }}
                    />
                    <TextField
                      name="closing"
                      type="date"
                      label="Closing date "
                      onChange={(e) => {
                        props.handleChange(e);
                        setClosing(e.target.value);
                      }}
                    />
                    <TextField
                      name="vacancy"
                      type="number"
                      label="Number of Vacancy "
                      onChange={(e) => {
                        props.handleChange(e);
                        setVacancy(e.target.value);
                      }}
                    />
                    <TextField
                      name="reference"
                      type="text"
                      label="Reference "
                      onChange={(e) => {
                        props.handleChange(e);
                        setReference(e.target.value);
                      }}
                    />
                    <TextField
                      name="worktype"
                      type="text"
                      label="Work type "
                      onChange={(e) => {
                        props.handleChange(e);
                        setWorktype(e.target.value);
                      }}
                    />
                    <div className="mb-4">
                      <label
                        className={`text-sm text-bluePrimary dark:text-white mb-2 `}
                      >
                        Gender
                      </label>
                      <select
                        placeholder="Gender"
                        className="outline-none dark:bg-navy-800 dark:!border-navy-700 border text-sm flex items-center justify-center border-lightPrimary w-full p-3 h-12 rounded-xl"
                        onChange={(e) => setGender(e.target.value)}
                        name="gender"
                        id=""
                      >
                        <option value="male">male</option>
                        <option value="female">female</option>
                        <option selected value="any">
                          any
                        </option>
                      </select>
                    </div>

                    <TextField
                      name="nationality"
                      onChange={(e) => {
                        props.handleChange(e);
                        setNationality(e.target.value);
                      }}
                      type="text"
                      label="Nationality"
                    />
                    <TextField
                      name="functionarea"
                      onChange={(e) => {
                        props.handleChange(e);
                        setFunctionarea(e.target.value);
                      }}
                      type="text"
                      label="Functional area"
                    />
                    <TextField
                      name="constractarea"
                      onChange={(e) => {
                        props.handleChange(e);
                        setConstractarea(e.target.value);
                      }}
                      type="text"
                      label="Contract Duration"
                    />
                    <TextField
                      name="experience"
                      onChange={(e) => {
                        props.handleChange(e);
                        setExperience(e.target.value);
                      }}
                      type="number"
                      label="Years of Experience"
                    />
                    <div className="mb-4">
                      <label
                        className={`text-sm text-bluePrimary dark:text-white mb-2 `}
                      >
                        Contract Extension
                      </label>
                      <select
                        placeholder="Contract Extension"
                        className="outline-none dark:bg-navy-800 dark:!border-navy-700 border text-sm flex items-center justify-center border-lightPrimary w-full p-3 h-12 rounded-xl"
                        onChange={(e) => setExtension(e.target.value)}
                        name="extension"
                        id=""
                      >
                        <option>Select Contract Extension</option>
                        <option value="yes">yes</option>
                        <option value="no">no</option>
                      </select>
                    </div>
                    <TextField
                      name="languages"
                      onChange={(e) => {
                        props.handleChange(e);
                        setLanguages(e.target.value);
                      }}
                      type="text"
                      label="Required Lanuages"
                    />
                    <TextField
                      name="requirement"
                      onChange={(e) => {
                        props.handleChange(e);
                        setRequirement(e.target.value);
                      }}
                      type="text"
                      label="Job Requirement"
                    />
                    <TextField
                      name="workexperience"
                      onChange={(e) => {
                        props.handleChange(e);
                        setWorkexperience(e.target.value);
                      }}
                      type="text"
                      label="Work Experience"
                    />
                    <TextField
                      name="location"
                      onChange={(e) => {
                        props.handleChange(e);
                        setLocation(e.target.value);
                      }}
                      type="text"
                      label="Location"
                    />

                    <div className="mb-4">
                      <label
                        className={`text-sm text-bluePrimary dark:text-white mb-2 `}
                      >
                        Job Visiblity
                      </label>
                      <select
                        placeholder="Job Visiblity"
                        className="outline-none dark:bg-navy-800 dark:!border-navy-700 border text-sm flex items-center justify-center border-lightPrimary w-full p-3 h-12 rounded-xl"
                        onChange={(e) => setShowjob(e.target.value)}
                        name="showjob"
                        id=""
                      >
                        <option>Select Job Visiblity</option>
                        <option value="show">Show Job</option>
                        <option value="hide">Hide Job</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    {/* job descrition  */}
                    <div className="mt-3 rounded-xl ">
                      <label
                        className={`text-sm text-bluePrimary dark:text-white mb-2 `}
                      >
                        job Description
                      </label>
                      <EditorToolbar toolbarId={"t1"} />
                      <ReactQuill
                        className="h-[200px] text-2xl"
                        theme="snow"
                        onChange={setValue}
                        placeholder={"Write post content here..."}
                        modules={modules("t1")}
                        formats={formats}
                        name="description"
                      />
                    </div>

                    <div className="mt-4 mb-4">
                      <label
                        className={`text-sm text-bluePrimary dark:text-white mb-2 `}
                      >
                        Duties & responsibilities:
                      </label>
                      <ReactQuill
                        theme="snow"
                        name="duties"
                        className="h-[200px] rounded-xl"
                        onChange={setDuties}
                      />
                    </div>
                    <div className="!mt-16">
                      <label
                        className={`text-sm text-bluePrimary dark:text-white mb-2 `}
                      >
                        Skills
                      </label>

                      <EditorToolbar toolbarId={"t2"} />
                      <ReactQuill
                        className="h-[200px] text-2xl"
                        theme="snow"
                        onChange={setSkills}
                        placeholder={"Write post content here..."}
                        modules={modules("t2")}
                        formats={formats}
                        name="skills"
                      />
                    </div>
                    <div className="mt-4">
                      <label
                        className={`text-sm text-bluePrimary dark:text-white mb-2 `}
                      >
                        Submission Guidline
                      </label>
                      <EditorToolbar toolbarId={"t3"} />
                      <ReactQuill
                        className="h-[300px] text-2xl"
                        theme="snow"
                        onChange={setGuidline}
                        placeholder={"Write post content here..."}
                        modules={modules("t3")}
                        formats={formats}
                        name="guidline"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex justify-end w-full mt-4">
                  <button
                    type="submit"
                    disabled={submitting}
                    className="px-4 py-1 text-white bg-blue-900 rounded-lg"
                  >
                    {submitting ? "Publishing..." : "Publish"}
                  </button>
                  <button
                    type="reset"
                    className="px-4 py-1 ml-3 text-white bg-red-900 rounded-lg"
                  >
                    Reset
                  </button>
                </div>
              </Form>
            </MainCard>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default AddJobValidate;
