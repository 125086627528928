/** @format */

import React, { useEffect, useState } from "react";
import Paragraph from "./Paragraph";
import Volunteer from "components/displayData/Volunteer";

function HealthMethodology() {
  const [health, setHealth] = useState([]);
  const fetchPosts = async () => {
    const response = await fetch(
      "https://api.communitymonitoring.org/api/external/sites-monitored"
    );
    const data = await response.json();

    setHealth(data.cbmh);
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <>
      <div className='flex flex-col items-center w-full h-full pb-10 '>
        {/* header */}
        <h1 className='text-left dark:text-white px-4 text-[30px] uppercase text-grayDark2 lg:text-center font-bold'>
          The methodology of{" "}
          <span className=''>
            {" "}
            <br /> Community Based Monitoring of health
          </span>
        </h1>
        {/* main content */}
        <div className='px-4 mt-4 lg:px-10'>
          <Paragraph
            title='Community Mobilization'
            line='h-[100px]'
            description='Similar to other Integrity Watch CBM initiatives, the Community-Based Monitoring of Health (CBM-H) draws on community participation to establish the legitimacy of its objectives. “Community” for this program is defined by Community Development Councils, civil society actors, health service providers, and community representatives. '
            description2='Once a community is chosen, Integrity Watch or implementing partner staff meet with the community to explain CBM and its benefits. The community elects a qualified person as an Integrity Volunteer (or Community Representative) to monitor a local healthcare facility. The volunteer should live close to the health facility and be literate, of good reputation within the community, and able to give several hours each week to monitoring tasks.'
          />
          <Paragraph
            line='h-[100px]'
            title='Training of Integrity Volunteer'
            description='After their election, Integrity Volunteers receive social and technical training from Integrity Watch staff. Social training covers the topics of corruption, community power mapping, effective communication, and problem-solving. Technical training covers the flowchart of the program, monitoring forms, survey tools, and use of the SehatSar (health facility monitoring) app.  By the end of their training, the Integrity Volunteers have knowledge of problem-resolution approaches, resource mobilization, and advocacy with duty bearers.'
          />
          <Paragraph
            line='h-[100px]'
            title='Collecting Information and Baseline Survey '
            description='The Integrity Volunteer requests and obtains all relevant health facility documents from government officials and the health directorate. The Integrity Volunteer then conducts a baseline survey of the community, interviewing 20 people over the age of 18 who are stakeholders in their local clinic. This survey helps introduce the monitoring program to the community and collects information on community attitudes, perceptions, and engagement. 
        '
          />
          <Paragraph
            title='Monitoring of Health Facilities and Participation in HMS meetings'
            line='h-[100px]'
            description='Monitoring of health facilities and overseeing the proper functioning of Health Management Shuras (HMS) are the core objectives of the CBM-H Program. The Integrity Volunteer visits the clinic twice a week, meets with the head of the facility, and completes the monitoring forms by checking the environment, services, and quality of equipment. If problems are identified, the Integrity Volunteer (sometimes with the Integrity Facilitator or Integrity Watch’s Provincial Coordinator) first tries to resolve the problems directly at the health facility. If not resolved, they escalate the issue to the health directorate in order to seek resolution from the Head Director. The Integrity Volunteer also facilitates and attends monthly HMS meetings to share findings and monitor HMS performance.'
          />
          <Paragraph
            title='Data Reporting'
            line='h-[100px]'
            description='The provincial office ensures that data from both weekly and quarterly monitoring forms are recorded in Integrity Watch’s database. A monthly feedback sheet is prepared from the recorded information and submitted to the health facility management and community representatives.'
          />
          <Paragraph
            title='Community Feedback Meetings'
            line='h-[100px]'
            description='The Integrity Volunteer conducts Community Feedback Meetings once a month to provide CBM-H activity feedback to community representatives. They discuss problems that remain unresolved at the health facility and report on HMS performance. The meetings are an important opportunity for building support and empowering the community to effect change by mobilizing local-level resources.'
          />

          <Paragraph
            title='Integrity Volunteers’ Meeting'
            line='h-[100px]'
            description='Integrity Volunteers from
           different communities meet together once a month. Hosted by IWA or an implementing partner, this meeting allows Integrity Volunteers involved in CBM-H to share experiences, exchange ideas, determine common challenges, and jointly seek solutions.'
          />
          <Paragraph
            title='Sectoral Monitoring Group'
            line='h-[100px]'
            description='The Sectoral Monitoring Group (SMG) comprises IWA or implementing partner staff, Integrity Volunteers, line ministry representatives, Provincial Council members, media, and aid actors. SMG meetings take place twice quarterly to address problems that remain unresolved by community-level advocacy efforts. The SMGs enable high-level advocacy to promote the accountability role of local institutions.'
          />
          <Paragraph
            title='Endline Survey'
            description='The CBM-H program concludes with an endline survey to gauge program impact and effectiveness on the community’s engagement, attitudes and perceptions. IWA then hands over coordination of the CBM program to the community, though remains available to provide technical assistance where needed.
          '
          />
          <div className='flex items-center justify-center w-full my-10'>
            <div className='w-1/2 bg-grayDark  h-[1px]'></div>
          </div>
          <Paragraph
            description={`Since its establishment, the CBM-H program has involved ${health["volunteers"]} volunteers and monitored ${health["sites-monitored"]} health centers in 14 provinces: Kabul, Bamyan, Kapisa, Parwan, Kandahar, Helmand, Herat, Ghor, Laghman, Kunar, Nangarhar, Balkh, Jawzjan, and Samangan. It is promising to see that the program has achieved a ${health["fixrate"]} fix-rate. CBM-H has empowered many communities to engage in the oversight of health service delivery and mobilize local-level resources to raise the quality and responsiveness of healthcare facilities. `}
          />
        </div>
        <p></p>
      </div>

      {/* data from communitymonitoring */}
      <div className='h-full max-w-screen-xl mx-auto mt-8'>
        <Volunteer
          volunteers={health.volunteers}
          problemsIdentified={health.problems}
          problemsSolved={health["problems-solved"]}
          sitesMonitored={health["sites-monitored"]}
        />
      </div>
    </>
  );
}

export default HealthMethodology;
