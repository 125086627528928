import React from "react";
import { Field, ErrorMessage } from "formik";

function TextArea({ label, name, ...props }) {
  // const { label, name, ...rest } = props;
  return (
    <div className=" w-full border-none h-[240px] relative mb-2  rounded-[10px]  ">
      <label htmlFor={name}>{label} </label>
      <div className="w-full   text-white h-[200px] rounded-[10px] dark:!bg-dark bg-[#179DAB]">
        <Field
          className="outline-none resize-none dark:bg-darkMain placeholder:text-white h-full rounded-[10px] px-2 text-white bg-[#179DAB] border-none focus:border-textPrimary focus:!outline-textPrimary w-full pt-1 bg[#179DAB] "
          as="textarea"
          id={name}
          name={name}
          {...props}
        />
      </div>

      <ErrorMessage component="div" className="text-red-500" name={name} />
    </div>
  );
}

export default TextArea;
