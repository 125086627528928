/** @format */
import React, { useEffect, useState } from "react";
import { FiAlignJustify } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "context/authConext";
import axios from "axios";
import profile from "assets/profile.png";

const Navbar = (props) => {
  const { onOpenSidenav } = props;

  const [open, setOpen] = React.useState(false);
  const [profileopen, setprofileopen] = React.useState(false);

  const { currentUser, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const handleLogOut = () => {
    logout();
    navigate("/");
  };

  if (currentUser) {
    setTimeout(() => {
      handleLogOut();
    }, 43200 * 1000);
  }
  const profilephoto = `${process.env.REACT_APP_Backend_API}/upload/profile/`;

  const [user, setUser] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_Backend_API}/api/auth/register/${currentUser.id}`
        );

        setUser(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [currentUser.id]);

  return (
    <nav className="sticky z-10 flex flex-row flex-wrap justify-between p-2 mr-1 top-4 bg-white/40 rounded-xl backdrop-blur-md">
      <div className="mt-1 ml-[6px] font-poppins ">
        <h2 className=" shrink capitalize font-poppins font-semibold text-[31px]    text-[#2B3674] w-[299px] h-[42px]     ">
          <Link to="/admin" className="hover:text-[#2B3674] capitalize ">
            Main Dashboard
          </Link>
        </h2>
      </div>

      <div className="flex justify-around gap-1 relative flex-grow md:flex-grow-0 w-full md:w-[50px] lg:h-[51px]   items-center mr-2 mt-[3px] shadow-lg shadow-white rounded-full p-[10px]   ">
        <span
          className="flex text-xl text-gray-600 cursor-pointer xl:hidden"
          onClick={onOpenSidenav}
        >
          {" "}
          <FiAlignJustify className="w-[22px] h-[20px]" />
        </span>{" "}
        {/* Profile & and card */}
        <div
          className="relative flex items-center justify-center rounded-lg cursor-pointer w-fit h-fit"
          profileOpen={profileopen}
          onClick={() => setprofileopen(!profileopen)}
        >
          <div className="w-[40px] h-[40px] rounded-full bg-[#F4F7FE] ">
            {currentUser?.image ? (
              <img
                src={profilephoto + currentUser?.image}
                className="w-full h-full rounded-full"
                rounf
                alt=""
              />
            ) : (
              <img
                src={profile}
                className="w-full h-full rounded-full"
                rounf
                alt=""
              />
            )}
          </div>
          {profileopen ? (
            <div className="absolute flex flex-col justify-start w-56 h-40 bg-white bg-no-repeat bg-cover shadow-xl shadow-gray-200 rounded-2xl right-1 top-10">
              <div className="mt-3 ml-4 ">
                <div className="flex items-center gap-2 ">
                  <h1 className="text-sm font-bold text-bluePrimary">
                    Hey, {currentUser?.username}
                  </h1>{" "}
                </div>
              </div>
              <div className="w-full h-px mt-3 bg-gray-200 " />

              <div className="flex flex-col pb-3 mt-4 ml-4 ">
                <Link
                  to="/admin/profile"
                  className="text-sm font-normal text-gray-800 hover:font-bold hover:text-gray-800"
                >
                  Profile Settings
                </Link>
                {user.role === "admin" ? (
                  <Link
                    to="/admin/register"
                    className="mt-3 text-sm font-normal text-gray-800 hover:font-bold hover:text-gray-800"
                  >
                    Register User
                  </Link>
                ) : null}

                <span
                  onClick={handleLogOut}
                  className="mt-3 text-sm font-semibold text-red-500 hover:font-bold hover:text-red-600"
                >
                  logout
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
