/** @format */

import React from "react";
import { motion } from "framer-motion";

function Plus() {
  const svgVariant = {
    hidden: { rotate: -360 },

    visible: {
      rotate: 0,
      transition: { duration: 1 },
    },
  };
  const pathVariants = {
    hidden: {
      opacity: 0,
      pathLength: 0,
    },
    visible: {
      opacity: 1,
      pathLength: 1,
      transition: {
        duration: 3,
        ease: "easeInOut",
      },
    },
  };
  return (
    <motion.svg
      width='75'
      height='75'
      viewBox='0 0 75 75'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      variants={svgVariant}
      initial='hidden'
      animate='visible'
    >
      <motion.path
        fill='#852711'
        d='M34.8438 16.25H40.1562V32.1875H34.8438V16.25ZM34.8438 42.8125H40.1562V58.75H34.8438V42.8125ZM42.8125 34.8438H58.75V40.1562H42.8125V34.8438ZM16.25 34.8438H32.1875V40.1562H16.25V34.8438ZM5.625 21.5625H0.3125V5.625C0.313906 4.21647 0.874066 2.86603 1.87005 1.87005C2.86603 0.874066 4.21647 0.313906 5.625 0.3125H21.5625V5.625H5.625V21.5625ZM21.5625 74.6875H5.625C4.21647 74.6861 2.86603 74.1259 1.87005 73.13C0.874066 72.134 0.313906 70.7835 0.3125 69.375V53.4375H5.625V69.375H21.5625V74.6875ZM69.375 74.6875H53.4375V69.375H69.375V53.4375H74.6875V69.375C74.6861 70.7835 74.1259 72.134 73.13 73.13C72.134 74.1259 70.7835 74.6861 69.375 74.6875ZM74.6875 21.5625H69.375V5.625H53.4375V0.3125H69.375C70.7835 0.313906 72.134 0.874066 73.13 1.87005C74.1259 2.86603 74.6861 4.21647 74.6875 5.625V21.5625Z'
        variants={pathVariants}
      />
    </motion.svg>
  );
}

export default Plus;
