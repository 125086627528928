import { Formik, Form, FormikProps } from "formik";
import React, { useContext, useEffect, useState } from "react";
import TextField from "./TextField";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "context/authConext";
import { BsEyeFill } from "react-icons/bs";

const Edit = () => {
  const ValidateSchema = Yup.object().shape({
    username: Yup.string()
      .max(20, "must be 20 characters or less")
      .required("Required!"),
    email: Yup.string()
      .email("Email is Invalid")
      .required("Email is Required!"),
    password: Yup.string()
      .min(6, "password should be at least 6 characters")
      .required("Password is Required!"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "password should match")
      .required("Confirm password is Required!"),
    file: Yup.mixed().required("File is required"),
  });

  const { currentUser, logout } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [file, setFile] = useState("");
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const handleClick = async (e) => {
    setSubmitting(true);

    var formData = new FormData();
    formData.append("username", username);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("file", file);

    try {
      await axios.put(
        `${process.env.REACT_APP_Backend_API}/api/auth/register/${currentUser.id}`,
        formData,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      toast.success("User updated successfully!");
      navigate("/admin");
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_Backend_API}/api/auth/register/${currentUser.id}`
        );
        setUsername(res.data.username);
        setEmail(res.data.email);
        setPassword(res.data.password);
        setFile(res.data.file);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [currentUser.id]);

  //---------------------
  // const handleClick = async (e) => {
  //   // e.preventDefault();
  //   setSubmitting(true);

  //   var formData = new FormData();
  //   formData.append("username", username);
  //   formData.append("email", email);
  //   formData.append("password", password);
  //   formData.append("file", file);
  //   formData.append("role", role);

  //   try {
  //     await axios.put(
  //       `${process.env.REACT_APP_Backend_API}/api/users/updateuser/${userId}`,
  //       formData,
  //       {
  //         "Content-Type": "multipart/form-data",
  //       }
  //     );
  //     toast.success("User updated successfully!");
  //     navigate("/admin/users");
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setSubmitting(false);
  //   }
  // };

  //-----------------------------

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const res = await axios.get(
  //         `${process.env.REACT_APP_Backend_API}/api/users/${userId}`
  //       );
  //       setUsername(res.data.username);
  //       setEmail(res.data.email);
  //       setPassword(res.data.password);
  //       setFile(res.data.file);
  //       setRole(res.data.role);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   fetchData();
  // }, [userId]);

  const userphoto = `${process.env.REACT_APP_Backend_API}/upload/profile/`;

  //toggle password
  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <div>
      <Formik
        initialValues={{
          username: username,
          email: email,
          password: password,
          confirmPassword: confirmPassword,
        }}
        validationSchema={ValidateSchema}
        onSubmit={handleClick}
      >
        {(props) => (
          <div>
            <Form>
              <TextField
                name="username"
                value={username}
                onChange={(e) => {
                  props.handleChange(e);
                  setUsername(e.target.value);
                }}
                type="text"
                label="user Name"
              />
              <TextField
                name="email"
                value={email}
                onChange={(e) => {
                  props.handleChange(e);
                  setEmail(e.target.value);
                }}
                type="text"
                label="Email"
              />
              {/* <TextField
                name="password"
                onChange={(e) => {
                  props.handleChange(e);
                  setPassword(e.target.value);
                }}
                type="password"
                label="Password"
              /> */}
              <div className="flex items-center gap-2">
                <TextField
                  name="password"
                  onChange={(e) => {
                    props.handleChange(e);
                    setPassword(e.target.value);
                  }}
                  type={passwordType}
                  label="Password"
                />
                <BsEyeFill onClick={togglePassword} className="w-5 h-5 mb-2 " />
              </div>
              <TextField
                name="confirmPassword"
                type="password"
                label="Confirm Password"
              />
              <TextField
                type="file"
                name="file"
                onChange={(e) => {
                  props.handleChange(e);
                  setFile(e.target.files[0]);
                }}
              />

              <button
                type="submit"
                disabled={submitting}
                className="px-4 py-1 text-white bg-blue-900 rounded-lg"
              >
                {submitting ? "Updating Profile..." : "Update Profile"}
              </button>
              <button
                type="reset"
                className="px-4 py-1 ml-3 text-white bg-red-900 rounded-lg"
              >
                Reset
              </button>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default Edit;
