/** @format */

import DateIcon from "components/icons/DateIcon";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

function Card1(props) {
  const { title, date, image, to, buttonBg } = props;
  return (
    <div className='w-full md:w-[390px] cursor-pointer  ease-in hover:scale-95 transition-all duration-300 h-[540px]   hover:shadow-2xl  rounded-3xl shadow-white'>
      <Link to={to}>
        <div
          style={{ backgroundImage: `url(${image})` }}
          className='w-full bg-no-repeat bg-cover h-[250px] sm:h-[290px] md:h-[250px] rounded-[23px]  '
        ></div>
        <div className='w-full flex items-center px-[10px] h-[130px] md:px-[27px] pb-[10px] pt-[15px]  '>
          <h1 className=' md:text-[20px] dark:text-white text-[15px] leading-7  text-[#808080] '>
            {" "}
            {title}{" "}
          </h1>
        </div>
        <Link></Link>
        <div className='flex items-center dark:text-white gap-3 mt-2 px-[30px] text-[#636369] '>
          <p>
            <DateIcon />{" "}
          </p>
          <p>{moment(date).format("MMMM DD YYYY")} </p>
        </div>
        {/* success button */}
        <div className='w-full px-[30px] '>
          <button
            className={`flex items-center justify-center h-[31px] mt-[24px] px-4 ${buttonBg}  rounded-full bg-[#005362] font-semibold text-white`}
          >
            Read more
          </button>
        </div>
      </Link>
    </div>
  );
}

export default Card1;
