/** @format */

import { TiSocialFacebook } from "react-icons/ti";
import { FaTwitter } from "react-icons/fa";
import { AiOutlineInstagram } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { useAnimation } from "framer-motion";

import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import { BsTwitter } from "react-icons/bs";
import TextField from "./TextFiled";
import TextArea from "components/Formik/TextArea";

const emailRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
const ValidateSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required("Required")
    .email("Invalid email")
    .matches(emailRegex, "Invalid email"),
  message: Yup.string().required("Required!"),
});

const FooterValidation = () => {
  // const load = () => {
  //   window.location.reload();
  // };

  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);

  const handleClick = async (e) => {
    if (email && message) {
      setSubmitting(true);

      // var formData = new FormData();
      // formData.append("email", email);
      // formData.append("message", message);
      const { result, error } = await axios.post(
        `${process.env.REACT_APP_Backend_API}/api/contact/`,
        {
          email,
          message,
        },
        {
          "Content-Type": "multipart/form-data",
        }
      );

      if (error) {
        return console.log(error);
      }

      setTimeout(() => {
        setIsFormSubmitted(false);
      }, 4000);

      setEmail("");
      setMessage("");
      setIsFormSubmitted(true);
      setSubmitting(false);

      // try {
      //   await axios.post(
      //     `${process.env.REACT_APP_Backend_API}/api/contact/`,
      //     {
      //       email,
      //       message,
      //     },
      //     {
      //       "Content-Type": "multipart/form-data",
      //     }
      //   );
      //   // window.location.reload();
      //   // navigate("/");
      //   // e.target.reset();

      // } catch (error) {
      //   console.log(error);
      //   setMsg({ type: "error", error });
      // } finally {
      //   setSubmitting(false);
      // }
    } else {
      console.log("Set formik error");
    }
  };

  return (
    <div className="w-full">
      <Formik
        initialValues={{
          email: email,
          message: message,
        }}
        validationSchema={ValidateSchema}
        onSubmit={handleClick}
      >
        {(props) => (
          <div>
            <Form>
              <TextField
                name="email"
                onChange={(e) => {
                  props.handleChange(e);
                  setEmail(e.target.value);
                }}
                type="email"
                value={email}
                placeholder="Enter you Email"
              />
              <TextArea
                name="message"
                onChange={(e) => {
                  props.handleChange(e);
                  setMessage(e.target.value);
                }}
                value={message}
                placeholder="Enter you Message"
              />

              {/* <div className=" w-full border-none h-[240px] relative  rounded-[10px]  ">
                <textarea
                  placeholder="Your message here"
                  className="outline-none resize-none dark:bg-darkMain placeholder:text-white h-full rounded-[10px] px-2 text-white bg-[#179DAB] border-none focus:border-textPrimary focus:!outline-textPrimary w-full pt-1 bg[#179DAB] "
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div> */}

              {isFormSubmitted ? (
                <div className="flex mt-1 rounded-lg shadow-lg">
                  <div className="flex items-center px-6 py-2 bg-green-600 rounded-l-lg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-white fill-current"
                      viewBox="0 0 16 16"
                      width="20"
                      height="20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M13.78 4.22a.75.75 0 010 1.06l-7.25 7.25a.75.75 0 01-1.06 0L2.22 9.28a.75.75 0 011.06-1.06L6 10.94l6.72-6.72a.75.75 0 011.06 0z"
                      ></path>
                    </svg>
                  </div>
                  <p className="flex items-center w-full px-4 py-6 text-sm bg-white border border-gray-200 rounded-r-lg md:text-base border-l-transparent dark:text-gray-600">
                    Message sent! Thank you for reaching out. Well get back
                    soon!
                  </p>
                </div>
              ) : (
                <button
                  disabled={submitting}
                  type="submit"
                  className=" w-[80px] pt-1 opacity-90 hover:opacity-100 transition-opacity mt-[20px] py-[4px] px-1 h-[32px] rounded-[8px] text-white bg-[#005462] "
                >
                  {submitting && (
                    <div className="flex items-center justify-center w-full h-full">
                      <svg
                        className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        data-motion-id="svg 1"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </div>
                  )}
                  <span className="">{submitting ? "Sending" : "Send"}</span>
                </button>
              )}
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default FooterValidation;
