import { Formik, Form, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import TextField from "./TextField";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { BsEyeFill } from "react-icons/bs";

const Update = () => {
  const ValidateSchema = Yup.object().shape({
    username: Yup.string()
      .max(20, "must be 20 characters or less")
      .required("Required!"),
    email: Yup.string()
      .email("Email is Invalid")
      .required("Email is Required!"),
    password: Yup.string()
      .min(6, "password should be at least 6 characters")
      .required("Password is Required!"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "password should match")
      .required("Confirm password is Required!"),
    file: Yup.mixed().required("File is required"),
    // role: Yup.string().required("Role is Required!"),
  });

  const location = useLocation();
  const userId = location.pathname.split("/")[4];

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [role, setRole] = useState("");
  const [file, setFile] = useState("");
  const [avatarPreview, setAvatarPreview] = useState();
  const reader = new FileReader();
  const [user, setUser] = useState([]);

  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();

  const handleClick = async (e) => {
    // e.preventDefault();
    setSubmitting(true);

    var formData = new FormData();
    formData.append("username", username);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("file", file);
    formData.append("role", role);

    try {
      await axios.put(
        `${process.env.REACT_APP_Backend_API}/api/users/updateuser/${userId}`,
        formData,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      toast.success("User updated successfully!");
      navigate("/admin/users");
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  //-----------------------------

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_Backend_API}/api/users/${userId}`
        );
        setUsername(res.data.username);
        setEmail(res.data.email);
        setPassword(res.data.password);
        setFile(res.data.file);
        setRole(res.data.role);
        setUser(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [userId]);

  console.log(user);

  const userphoto = `${process.env.REACT_APP_Backend_API}/upload/profile/`;

  //toggle password
  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <div>
      <Formik
        initialValues={{
          username: username,
          email: email,
          password: password,
          confirmPassword: confirmPassword,
          // role: role,
        }}
        validationSchema={ValidateSchema}
        onSubmit={handleClick}
      >
        {(props) => (
          <div>
            <Form>
              <TextField
                name="username"
                value={username}
                onChange={(e) => {
                  props.handleChange(e);
                  setUsername(e.target.value);
                }}
                type="text"
                label="Full Name"
              />
              <TextField
                name="email"
                value={email}
                onChange={(e) => {
                  props.handleChange(e);
                  setEmail(e.target.value);
                }}
                type="text"
                label="Email"
              />

              <div className="flex items-center gap-2">
                <TextField
                  name="password"
                  onChange={(e) => {
                    props.handleChange(e);
                    setPassword(e.target.value);
                  }}
                  type={passwordType}
                  label="Password"
                />
                <BsEyeFill onClick={togglePassword} className="w-5 h-5 mb-2 " />
              </div>
              <TextField
                name="confirmPassword"
                type="password"
                label="Confirm Password"
              />
              {/* <TextField
                type="file"
                name="file"
                onChange={(e) => {
                  props.handleChange(e);
                  setFile(e.target.files[0]);
                }}
              /> */}
              <div className="flex flex-col items-center  !w-full mt-8  gap-x-14  md:flex-row md:justify-between ">
                <div className="w-full">
                  <label
                    className={`text-base block text-bluePrimary dark:text-white mb-3 `}
                  >
                    New Photo Preview
                  </label>
                  <div className="">
                    <img
                      src={avatarPreview}
                      width={55}
                      alt="new photo"
                      height={55}
                      className="object-cover rounded-2xl "
                    />
                  </div>
                </div>

                <div className="w-full">
                  <TextField
                    name="file"
                    type="file"
                    label="User Profile  "
                    onChange={(e) => {
                      props.handleChange(e);
                      // setFile(e.target.files[0]);
                      reader.onload = () => {
                        if (reader.readyState === 2) {
                          setAvatarPreview(reader.result);
                        }
                      };
                      reader.readAsDataURL(e.target.files[0]);
                      setFile(e.target.files[0]);
                    }}
                  />
                </div>
                <div className="w-full">
                  <label
                    className={`text-base block text-bluePrimary dark:text-white mb-3 `}
                  >
                    Old Profile
                  </label>
                  <div className="">
                    <img
                      src={userphoto + user.image}
                      width={55}
                      alt="old"
                      height={55}
                      className="object-cover rounded-2xl "
                    />
                  </div>
                </div>
              </div>

              <div className="my-8">
                <label
                  className={`text-sm text-bluePrimary dark:text-white mb-2 `}
                >
                  Role
                </label>
                <select
                  className="outline-none dark:bg-navy-800 dark:!border-navy-700 border text-sm flex items-center justify-center border-lightPrimary w-full p-3 full rounded-xl"
                  name="role"
                  onChange={(e) => {
                    setRole(e.target.value);
                  }}
                  id=""
                >
                  <option>Select User Role</option>
                  <option value="jobs">Jobs & Bids</option>
                  <option value="admin">Admin</option>
                  <option value="posts">Stories</option>
                </select>
              </div>

              {/* <TextField
                name="role"
                value={role}
                onChange={(e) => {
                  props.handleChange(e);
                  setRole(e.target.value);
                }}
                type="text"
                label="Role : admin, jobs, posts"
                placeholder="admin, jobs, posts"
              /> */}

              <button
                type="submit"
                disabled={submitting}
                className="px-4 py-1 text-white bg-blue-900 rounded-lg"
              >
                {submitting ? "Registering User..." : "Register User"}
              </button>
              <button
                type="reset"
                className="px-4 py-1 ml-3 text-white bg-red-900 rounded-lg"
              >
                Reset
              </button>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default Update;
