/** @format */

import axios from "axios";
import MainCard from "components/cards";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { toast } from "react-toastify";
import "react-quill/dist/quill.snow.css";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextField from "./variables/TextField";
const reader = new FileReader();

function AddBidValidate() {
  const ValidateSchema = Yup.object().shape({
    title: Yup.string().required("Title is Required!"),
    description: Yup.string().required("Description is Required!"),
    location: Yup.string().required("Location is Required!"),
    posted: Yup.date().required("Posted Date is Required!"),
    closing: Yup.date().required("Closing Date is Required!"),
    file: Yup.mixed().required("Photo is Required!"),
  });
  const [description, setDescription] = useState("");
  const [posted, setPosted] = useState("");
  const [closing, setClosing] = useState("");
  const [location, setLocation] = useState("");
  const [file, setFile] = useState("");
  const [title, setTitle] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();

  const handleClick = async (e) => {
    setSubmitting(true);

    var formData = new FormData();
    formData.append("description", description);
    formData.append("posted", posted);
    formData.append("closing", closing);

    formData.append("location", location);
    formData.append("file", file);
    formData.append("title", title);

    try {
      await axios.post(
        `${process.env.REACT_APP_Backend_API}/api/bids/`,
        formData,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      toast.success("Bid added successfully!");
      navigate("/admin/bids");
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          title: title,
          location: location,
          description: description,
          post: posted,
          closing: closing,
          file: file,
        }}
        validationSchema={ValidateSchema}
        onSubmit={handleClick}
      >
        {(props) => (
          <div>
            <MainCard
              extra={"flex items-center justify-between h-[100px] px-8  "}
            >
              <Link to="/admin/bids">
                <h1 className="text-textSecondary flex items-center gap-2 uppercase text-[30px] font-banscrift font-bold ">
                  <AiOutlineArrowLeft />
                  Back
                </h1>
              </Link>
              <div>
                <h1 className="text-textSecondary flex items-center gap-2 uppercase text-[30px] font-banscrift font-bold ">
                  Add Bid Page
                </h1>
              </div>
            </MainCard>
            <MainCard extra={"mt-4 pt-8 pb-14 px-8"}>
              <Form>
                <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                  <TextField
                    name="title"
                    onChange={(e) => {
                      props.handleChange(e);
                      setTitle(e.target.value);
                    }}
                    type="text"
                    label="title "
                  />
                  <TextField
                    name="description"
                    onChange={(e) => {
                      props.handleChange(e);
                      setDescription(e.target.value);
                    }}
                    type="text"
                    label="Description"
                  />

                  <TextField
                    name="posted"
                    type="date"
                    label="Posted date "
                    onChange={(e) => {
                      props.handleChange(e);
                      setPosted(e.target.value);
                    }}
                  />
                  <TextField
                    name="closing"
                    type="date"
                    label="Closing date "
                    onChange={(e) => {
                      props.handleChange(e);
                      setClosing(e.target.value);
                    }}
                  />
                  <TextField
                    name="location"
                    onChange={(e) => {
                      props.handleChange(e);
                      setLocation(e.target.value);
                    }}
                    type="text"
                    label="location"
                  />

                  {/* <TextField
                    name="hidden"
                    type="select"
                    label="posted date "
                    onChange={(e) => {
                      props.handleChange(e);
                      setHidden(e.target.value);
                    }}
                  /> */}
                  <TextField
                    name="file"
                    type="file"
                    label="flle  "
                    onChange={(e) => {
                      props.handleChange(e);
                      setFile(e.target.files[0]);
                    }}
                  />
                </div>

                <div className="flex justify-end w-full mt-4">
                  <button
                    type="submit"
                    disabled={submitting}
                    className="px-4 py-1 text-white bg-blue-900 rounded-lg"
                  >
                    {submitting ? "Publishing..." : "Publish"}
                  </button>
                  <button
                    type="reset"
                    className="px-4 py-1 ml-3 text-white bg-red-900 rounded-lg"
                  >
                    Reset
                  </button>
                </div>
              </Form>
            </MainCard>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default AddBidValidate;
