/** @format */
import EditorToolbar, {
  modules,
  formats,
} from "components/Editor/EditorToolbar";
import axios from "axios";
import MainCard from "components/cards";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function UpdateStory() {
  const [value, setValue] = useState("");
  const [title, setTitle] = useState("");
  const [date, setDate] = useState(null);
  const [file, setFile] = useState();
  const [postby, setPostby] = useState("");
  const [location, setLocation] = useState("");
  const [photolocation, setPhotolocation] = useState("");
  const [hidden, setHidden] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [avatarPreview, setAvatarPreview] = useState();
  const [startdate, setStartdate] = useState(new Date());
  const [isError, setIsError] = useState("");
  const navigate = useNavigate();
  const locations = useLocation();
  const postId = locations.pathname.split("/")[4];

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_Backend_API}/api/posts/${postId}`)
      .then((resp) => {
        setTitle(resp.data.title);
        setValue(resp.data.description);

        setDate(resp.data.date);
        setPostby(resp.data.postby);
        setLocation(resp.data.location);
        setPhotolocation(resp.data.photolocation);
        setDate(resp.data.date);
        setFile(resp.data.file);
        // setStartdate(resp.data.date);

        setHidden(resp.data.hidden);
      });
  }, [postId]);

  const handleClick = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    var formData = new FormData();
    formData.append("title", title);
    formData.append("description", value);
    formData.append("file", file);

    formData.append("date", date);
    formData.append("postby", postby);
    formData.append("location", location);
    formData.append("photolocation", photolocation);

    formData.append("hidden", hidden);

    console.log(file);

    try {
      await axios.put(
        `${process.env.REACT_APP_Backend_API}/api/posts/${postId}`,
        formData,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      toast.success("Story updated successfully!");
      navigate("/admin/stories");
    } catch (error) {
      // console.log("error is here :" + error);
      setIsError("Please fill all inputs :", error);
    } finally {
      setIsSubmitting(false);
    }
  };
  const photo = `${process.env.REACT_APP_Backend_API}/upload/stories/`;

  //showing photo
  const onChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatarPreview(reader.result);
      }
    };
    setFile(e.target.files[0]);
    reader.readAsDataURL(e.target.files[0]);
  };

  const aRef = useRef(null);
  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
  };

  return (
    <div className={"w-full h-full  "}>
      <MainCard extra={"flex items-center justify-between h-[100px] px-8  "}>
        <Link to="/admin/stories">
          <h1 className="text-textSecondary flex items-center gap-2 uppercase text-[30px] font-banscrift font-bold ">
            <AiOutlineArrowLeft />
            Back
          </h1>
        </Link>
      </MainCard>
      <MainCard extra={"mt-4 pt-8 pb-14 px-8"}>
        <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
          {/* post title */}
          <div className="mb-4">
            <label className={`text-sm text-bluePrimary dark:text-white mb-2 `}>
              Post Title
            </label>
            <input
              type="text"
              value={title}
              name="title"
              placeholder="title"
              onChange={(e) => setTitle(e.target.value)}
              className="outline-none dark:bg-navy-800 dark:!border-navy-700 border text-sm flex items-center justify-center border-lightPrimary w-full p-3 h-12 rounded-xl"
            />
          </div>
          {/* post by  */}
          <div className="mb-4">
            <label className={`text-sm text-bluePrimary dark:text-white mb-2 `}>
              Post By
            </label>
            <input
              type="text"
              name="postby"
              value={postby}
              placeholder="Post By"
              onChange={(e) => setPostby(e.target.value)}
              className="outline-none dark:bg-navy-800 dark:!border-navy-700 border text-sm flex items-center justify-center border-lightPrimary w-full p-3 h-12 rounded-xl"
            />
          </div>
          {/* post location */}
          <div className="mb-4">
            <label className={`text-sm text-bluePrimary dark:text-white mb-2 `}>
              Location
            </label>
            <input
              type="text"
              name="location"
              value={location}
              placeholder="Location"
              onChange={(e) => setLocation(e.target.value)}
              className="outline-none dark:bg-navy-800 dark:!border-navy-700 border text-sm flex items-center justify-center border-lightPrimary w-full p-3 h-12 rounded-xl"
            />
          </div>

          {/* photo location */}
          <div className="mb-4">
            <label className={`text-sm text-bluePrimary dark:text-white mb-2 `}>
              Photo Location
            </label>
            <input
              type="text"
              value={photolocation}
              name="photolocation"
              placeholder="Photo Location"
              onChange={(e) => setPhotolocation(e.target.value)}
              className="outline-none dark:bg-navy-800 dark:!border-navy-700 border text-sm flex items-center justify-center border-lightPrimary w-full p-3 h-12 rounded-xl"
            />
          </div>
          {/* date  */}
          <div className="mb-4">
            <label className={`text-sm text-bluePrimary dark:text-white mb-2 `}>
              Post Date
            </label>
            <input
              name="date"
              onChange={(e) => {
                setDate(e.target.value);
              }}
              type="date"
              min={new Date()}
              value={moment(date).format("YYYY-MM-DD")}
              placeholder="Post Date"
              className="outline-none dark:bg-navy-800 dark:!border-navy-700 border text-sm flex items-center justify-center border-lightPrimary w-full p-3 full rounded-xl"
            />
            {isError && (
              <div className="font-medium text-red-500">
                Please Select Correct Date
              </div>
            )}
          </div>
          <div className="mb-4">
            <label className={`text-sm text-bluePrimary dark:text-white mb-2 `}>
              Hidden
            </label>
            <select
              value={hidden}
              className="outline-none dark:bg-navy-800 dark:!border-navy-700 border text-sm flex items-center justify-center border-lightPrimary w-full p-3 full rounded-xl"
              name="hidden"
              onChange={(e) => {
                setHidden(e.target.value);
              }}
              id=""
            >
              <option>Select Story Visiblity</option>
              <option value="false">Show</option>
              <option value="true">Hide</option>
            </select>
          </div>

          {/* <div>
            <label
              htmlFor="file"
              className={`text-sm text-bluePrimary dark:text-white mb-2 `}
            >
              Upload File
            </label>
            <input
              onChange={(e) => setFile(e.target.files[0])}
              type="file"
              id="file"
              name="file"
            />
          </div> */}
          <div className="flex flex-col items-center w-full col-span-2 mb-3 gap-x-4 md:flex-row">
            <div className="w-full">
              <label
                className={`text-base block text-bluePrimary dark:text-white mb-3 `}
              >
                New photo Preview
              </label>
              <div className="">
                <img
                  src={avatarPreview}
                  width={55}
                  alt="new photo"
                  height={55}
                  className="object-cover rounded-2xl "
                />
              </div>
            </div>
            <div>
              <input
                id="file"
                placeholder="select photo"
                name="file"
                onChange={onChange}
                type="file"
              />
            </div>
            <div className="w-full">
              <label
                className={`text-base block text-bluePrimary dark:text-white mb-3 `}
              >
                Old photo
              </label>
              <div className="">
                <img
                  src={photo + file}
                  width={55}
                  alt="old"
                  height={55}
                  className="object-cover rounded-2xl "
                />
              </div>
            </div>
          </div>
        </div>
        {/* post description */}
        <div className="mt-3 rounded-xl ">
          <label className={`text-sm text-bluePrimary dark:text-white mb-2 `}>
            Post Description
          </label>

          {/* <ReactQuill theme='snow' className='h-[420px] ' onChange={setValue} /> */}
          <EditorToolbar toolbarId={"t1"} />
          <ReactQuill
            className="h-[500px] text-2xl"
            theme="snow"
            onChange={setValue}
            placeholder={"Write post content here..."}
            modules={modules("t1")}
            formats={formats}
            value={value}
          />
        </div>

        {/* button */}
        <div className="flex justify-end w-full mt-4">
          <button
            type="submit"
            disabled={isSubmitting}
            onClick={handleClick}
            className="px-4 py-1 text-white transition-colors duration-200 bg-blue-800 rounded-lg hover:bg-blue-900"
          >
            {isSubmitting ? "Updating Post..." : "Update Post"}
          </button>
          <button
            type="reset"
            className="px-4 py-1 ml-3 text-white bg-red-900 rounded-lg"
          >
            Reset
          </button>
        </div>
      </MainCard>
    </div>
  );
}

export default UpdateStory;
