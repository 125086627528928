/** @format */

import React from "react";
import { motion } from "framer-motion";

function Integrity() {
  return (
    <motion.svg
      width='143'
      height='45'
      viewBox='0 0 143 45'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <motion.path
        d='M31.7 8.9C28.2 10.6 24.4 11.4 20.5 11.4C16.7 11.4 12.9 10.5 9.4 8.9C7 7.8 4.8 6.3 2.8 4.5H0C2.5 7.1 5.4 9.1 8.6 10.6C12.3 12.3 16.3 13.3 20.5 13.3C24.7 13.3 28.8 12.4 32.5 10.6C35.6 9.1 38.4 7.1 40.9 4.6H38.1C36.1 6.4 34 7.8 31.7 8.9Z'
        fill='white'
      />
      <motion.path
        d='M20.5 9.2C23.1 9.2 25.1 7.1 25.1 4.6C25.1 2 23 0 20.5 0C18 0 15.9 2.1 15.9 4.6C15.8 7.2 17.9 9.2 20.5 9.2Z'
        fill='white'
      />
      <motion.path
        d='M20.1 29C20.2 29 20.3 29 20.4 29C24.6 29 28.7 28.1 32.4 26.3C35.5 24.8 38.3 22.8 40.8 20.3H38C36 22.1 33.8 23.5 31.5 24.6C28.8 25.9 25.9 26.7 22.9 27C24.1 26.2 24.9 24.8 24.9 23.2C24.9 20.6 22.8 18.6 20.3 18.6C17.8 18.6 15.7 20.7 15.7 23.2C15.7 24.8 16.5 26.2 17.7 27C14.8 26.7 11.9 25.9 9.3 24.7C7 23.5 4.8 22 2.8 20.2C4.8 18.4 7 16.9 9.4 15.8C11.6 14.8 14 14 16.4 13.7C15 13.5 13.6 13.2 12.2 12.7C11 13.1 9.8 13.5 8.6 14.1C5.5 15.6 2.5 17.6 0 20.2C2.5 22.8 5.4 24.8 8.6 26.3C10.1 27 11.6 27.6 13.2 28C11.6 28.4 10.1 29 8.6 29.7C5.5 31.2 2.5 33.2 0 35.8C2.5 38.4 5.4 40.4 8.6 41.9C12.3 43.6 16.3 44.6 20.5 44.6C24.7 44.6 28.8 43.7 32.5 41.9C35.6 40.4 38.4 38.4 40.9 35.9H38.1C36.1 37.7 33.9 39.1 31.6 40.2C28.9 41.5 26 42.3 23 42.6C24.2 41.8 25 40.4 25 38.8C25 36.2 22.9 34.2 20.4 34.2C17.9 34.2 15.8 36.3 15.8 38.8C15.8 40.4 16.6 41.8 17.8 42.6C14.9 42.3 12 41.5 9.4 40.3C7 39.2 4.8 37.7 2.8 35.9C4.8 34.1 7 32.6 9.4 31.5C12.7 29.9 16.4 29 20.1 29Z'
        fill='white'
      />
      <motion.path
        d='M57.2 16.7C57.3 16.7 57.4 16.7 57.5 16.8C57.6 16.9 57.7 16.8 57.9 16.8C58.1 16.8 58.2 16.8 58.3 16.8C58.4 16.8 58.5 16.8 58.6 16.7C58.7 16.6 58.7 16.6 58.7 16.6V16.5V4C58.7 3.9 58.7 3.9 58.7 3.9L58.6 3.8C58.5 3.8 58.4 3.8 58.3 3.7C58.2 3.7 58.1 3.7 57.9 3.7C57.7 3.7 57.6 3.7 57.5 3.7C57.4 3.7 57.3 3.7 57.2 3.8C57.1 3.8 57.1 3.9 57 3.9V4V16.4C57 16.5 57 16.5 57 16.5C57 16.5 57.2 16.6 57.2 16.7Z'
        fill='white'
      />
      <motion.path
        d='M62.4 16.7C62.5 16.7 62.5 16.7 62.7 16.8C62.8 16.8 62.9 16.8 63.1 16.8C63.3 16.8 63.4 16.8 63.5 16.8C63.6 16.8 63.7 16.8 63.8 16.7C63.9 16.7 63.9 16.6 63.9 16.6V16.5V8.7C63.9 8.2 63.9 7.7 63.9 7.2C63.9 6.7 63.9 6.2 63.9 5.7C64.1 6.1 64.3 6.5 64.5 6.9C64.7 7.3 64.9 7.7 65.1 8.1L69 15.4C69.1 15.7 69.3 15.9 69.4 16C69.5 16.1 69.6 16.3 69.8 16.4C69.9 16.5 70 16.6 70.2 16.6C70.3 16.6 70.5 16.7 70.7 16.7H71.3C71.4 16.7 71.5 16.7 71.6 16.7C71.7 16.7 71.8 16.6 71.8 16.6C71.9 16.5 71.9 16.5 72 16.4C72 16.3 72.1 16.2 72.1 16.1V4.1V4L72 3.9C71.9 3.9 71.8 3.8 71.7 3.8C71.6 3.8 71.5 3.8 71.3 3.8C71.1 3.8 71 3.8 70.9 3.8C70.8 3.8 70.7 3.8 70.6 3.9C70.5 3.9 70.5 4 70.5 4V4.1V11.1C70.5 11.6 70.5 12.2 70.5 12.7C70.5 13.3 70.5 13.8 70.5 14.3C70.3 14 70.2 13.7 70 13.4C69.8 13.1 69.7 12.8 69.5 12.5C69.3 12.2 69.2 11.9 69 11.5C68.8 11.2 68.6 10.8 68.5 10.5L65.5 4.8C65.4 4.6 65.3 4.4 65.2 4.3C65.1 4.2 65 4.1 64.9 4C64.8 3.9 64.7 3.9 64.5 3.8C64.3 3.7 64.2 3.7 64 3.7H63.2C63 3.7 62.9 3.8 62.7 3.9C62.5 4 62.5 4.2 62.5 4.5V16.4V16.5C62.3 16.6 62.3 16.6 62.4 16.7Z'
        fill='white'
      />
      <motion.path
        d='M73.9 5.1C73.9 5.2 74 5.2 74 5.2H74.1H77.7V16.4C77.7 16.5 77.7 16.5 77.7 16.5L77.8 16.6C77.8 16.6 78 16.6 78.1 16.7C78.2 16.8 78.3 16.7 78.5 16.7C78.7 16.7 78.8 16.7 78.9 16.7C79 16.7 79.1 16.7 79.2 16.6C79.3 16.5 79.3 16.5 79.3 16.5V16.4V5.2H82.9C83 5.2 83 5.2 83 5.2L83.1 5.1C83.1 5 83.2 5 83.2 4.9C83.2 4.8 83.2 4.7 83.2 4.6C83.2 4.5 83.2 4.4 83.2 4.3C83.2 4.2 83.2 4.1 83.1 4.1C83.1 4 83 4 83 4H82.9H73.9C73.8 4 73.8 4 73.8 4L73.7 4.1C73.7 4.2 73.7 4.2 73.6 4.3C73.6 4.4 73.6 4.5 73.6 4.6C73.6 4.7 73.6 4.8 73.6 4.9C73.6 5 73.9 5 73.9 5.1Z'
        fill='white'
      />
      <motion.path
        d='M86 16.7H92.2C92.3 16.7 92.3 16.7 92.3 16.7L92.4 16.6C92.4 16.5 92.5 16.5 92.5 16.4C92.5 16.3 92.5 16.2 92.5 16.1C92.5 16 92.5 15.9 92.5 15.8C92.5 15.7 92.5 15.6 92.4 15.6C92.4 15.5 92.3 15.5 92.3 15.5H92.2H87.1V10.9H91.4C91.5 10.9 91.5 10.9 91.5 10.9L91.6 10.8C91.6 10.7 91.7 10.7 91.7 10.6C91.7 10.5 91.7 10.4 91.7 10.3C91.7 10.2 91.7 10.1 91.7 10C91.7 9.9 91.7 9.8 91.6 9.8C91.6 9.7 91.5 9.7 91.5 9.7H91.4H87.1V5.7H92.1C92.2 5.7 92.2 5.7 92.2 5.7L92.3 5.6C92.3 5.5 92.3 5.5 92.4 5.4C92.5 5.3 92.4 5.2 92.4 5.1C92.4 5 92.4 4.9 92.4 4.8C92.4 4.7 92.4 4.6 92.3 4.6C92.3 4.5 92.2 4.5 92.2 4.5H92.1H86C85.8 4.5 85.7 4.6 85.6 4.7C85.5 4.8 85.4 5 85.4 5.2V16.7C85.4 17 85.5 17.1 85.6 17.2C85.7 16.6 85.8 16.7 86 16.7Z'
        fill='white'
      />
      <motion.path
        d='M104.5 4.7C104.4 4.6 104.3 4.50001 104 4.40001C103.8 4.30001 103.5 4.10001 103.2 4.00001C102.9 3.90001 102.5 3.8 102 3.7C101.6 3.6 101.1 3.60001 100.6 3.60001C99.6 3.60001 98.8 3.80001 98 4.10001C97.2 4.40001 96.5 4.90001 96 5.50001C95.4 6.10001 95 6.80001 94.7 7.60001C94.4 8.40001 94.2 9.40001 94.2 10.4C94.2 11.4 94.3 12.3 94.6 13.1C94.9 13.9 95.3 14.6 95.8 15.2C96.3 15.8 97 16.2 97.8 16.5C98.6 16.8 99.5 17 100.5 17C100.8 17 101.2 17 101.5 16.9C101.8 16.9 102.2 16.8 102.5 16.7C102.8 16.6 103.1 16.5 103.4 16.4C103.7 16.3 104 16.2 104.1 16.1C104.3 16 104.4 15.9 104.5 15.8C104.6 15.7 104.6 15.5 104.6 15.4V10.5C104.6 10.4 104.6 10.3 104.6 10.2C104.6 10.1 104.5 10 104.5 10C104.5 10 104.4 9.90001 104.3 9.90001C104.2 9.90001 104.2 9.90001 104.1 9.90001H100C99.9 9.90001 99.9 9.90001 99.9 9.90001L99.8 10C99.8 10.1 99.7 10.1 99.7 10.2C99.7 10.3 99.7 10.4 99.7 10.5C99.7 10.7 99.7 10.9 99.8 11C99.9 11.1 99.9 11.2 100.1 11.2H103.1V15C102.7 15.2 102.4 15.3 102 15.4C101.6 15.5 101.2 15.5 100.8 15.5C100.1 15.5 99.5 15.4 99 15.2C98.5 15 98 14.6 97.5 14.2C97.1 13.8 96.8 13.2 96.5 12.6C96.3 12 96.2 11.2 96.2 10.4C96.2 9.70001 96.3 9.00001 96.5 8.30001C96.7 7.70001 97 7.1 97.4 6.7C97.8 6.2 98.3 5.90001 98.8 5.60001C99.3 5.30001 100 5.2 100.7 5.2C101.3 5.2 101.8 5.30001 102.3 5.40001C102.7 5.50001 103.1 5.70001 103.4 5.80001C103.7 6.00001 104 6.1 104.1 6.2C104.3 6.3 104.4 6.40001 104.5 6.40001H104.6L104.7 6.30001C104.7 6.20001 104.7 6.20001 104.8 6.10001C104.8 6.00001 104.8 5.90001 104.8 5.80001C104.8 5.60001 104.8 5.40001 104.8 5.30001C104.7 4.90001 104.6 4.8 104.5 4.7Z'
        fill='white'
      />
      <motion.path
        d='M116.3 15.7L115.1 12.9C115 12.6 114.8 12.3 114.7 12C114.6 11.7 114.4 11.5 114.3 11.3C114.1 11.1 114 10.9 113.8 10.8C113.6 10.7 113.4 10.5 113.2 10.4C113.6 10.3 113.9 10.1 114.2 9.9C114.5 9.7 114.8 9.5 115 9.2C115.2 8.9 115.4 8.6 115.5 8.3C115.6 8 115.7 7.6 115.7 7.1C115.7 6.7 115.6 6.2 115.5 5.9C115.4 5.6 115.2 5.2 114.9 4.9C114.6 4.6 114.3 4.4 113.9 4.2C113.5 4 113.1 3.9 112.6 3.8C112.4 3.8 112.2 3.8 112 3.7C111.8 3.7 111.5 3.7 111.2 3.7H108.5C108.3 3.7 108.2 3.8 108.1 3.9C108 4 107.9 4.2 107.9 4.4V16.3C107.9 16.4 107.9 16.4 107.9 16.4L108 16.5C108 16.5 108.2 16.5 108.3 16.6C108.4 16.7 108.5 16.6 108.7 16.6C108.9 16.6 109 16.6 109.1 16.6C109.2 16.6 109.3 16.6 109.4 16.5C109.5 16.4 109.5 16.4 109.5 16.4V16.3V10.8H110.6C111 10.8 111.3 10.9 111.6 11C111.9 11.1 112.1 11.3 112.3 11.5C112.5 11.7 112.7 12 112.8 12.2C112.9 12.5 113.1 12.8 113.2 13.2L114.4 16.2C114.4 16.3 114.5 16.3 114.5 16.4C114.5 16.5 114.6 16.5 114.7 16.5C114.8 16.5 114.9 16.6 115 16.6C115.1 16.6 115.3 16.6 115.4 16.6C115.6 16.6 115.8 16.6 115.9 16.6C116 16.6 116.1 16.6 116.2 16.5C116.3 16.4 116.3 16.4 116.3 16.4V16.3C116.3 16.3 116.3 16.2 116.3 16.1C116.5 16.1 116.4 15.9 116.3 15.7ZM112.5 9.4C112.2 9.5 111.8 9.6 111.3 9.6H109.6V5.3H111C111.3 5.3 111.6 5.3 111.8 5.3C112 5.3 112.2 5.4 112.4 5.4C112.9 5.6 113.3 5.8 113.5 6.2C113.7 6.5 113.8 7 113.8 7.5C113.8 7.8 113.7 8.1 113.6 8.4C113.5 8.7 113.3 8.9 113.1 9.1C113.1 9.1 112.8 9.3 112.5 9.4Z'
        fill='white'
      />
      <motion.path
        d='M120.6 3.8C120.5 3.8 120.4 3.8 120.3 3.7C120.2 3.7 120.1 3.7 119.9 3.7C119.7 3.7 119.6 3.7 119.5 3.7C119.4 3.7 119.3 3.7 119.2 3.8C119.1 3.8 119.1 3.9 119 3.9V4V16.4C119 16.5 119 16.5 119 16.5L119.1 16.6C119.1 16.6 119.3 16.6 119.4 16.7C119.5 16.8 119.6 16.7 119.8 16.7C120 16.7 120.1 16.7 120.2 16.7C120.3 16.7 120.4 16.7 120.5 16.6C120.6 16.5 120.6 16.5 120.6 16.5V16.4V4C120.6 3.9 120.6 3.9 120.6 3.9C120.7 3.9 120.6 3.8 120.6 3.8Z'
        fill='white'
      />
      <motion.path
        d='M132.2 3.9C132.2 3.8 132.1 3.8 132.1 3.8H132H123C122.9 3.8 122.9 3.8 122.9 3.8L122.8 3.9C122.8 4 122.8 4 122.7 4.1C122.7 4.2 122.7 4.3 122.7 4.4C122.7 4.5 122.7 4.6 122.7 4.7C122.7 4.8 122.7 4.9 122.8 4.9C122.8 5 122.9 5 122.9 5H123H126.6V16.2C126.6 16.3 126.6 16.3 126.6 16.3L126.7 16.4C126.7 16.4 126.9 16.4 127 16.5C127.1 16.6 127.2 16.5 127.4 16.5C127.6 16.5 127.7 16.5 127.8 16.5C127.9 16.5 128 16.5 128.1 16.4C128.2 16.3 128.2 16.3 128.2 16.3V16.2V5.2H131.8C131.9 5.2 131.9 5.2 131.9 5.2L132 5.1C132 5 132.1 5 132.1 4.9C132.1 4.8 132.1 4.7 132.1 4.6C132.1 4.5 132.1 4.4 132.1 4.3C132.2 4.1 132.2 4 132.2 3.9Z'
        fill='white'
      />
      <motion.path
        d='M142 3.80001C141.9 3.80001 141.9 3.7 141.7 3.7C141.6 3.7 141.4 3.7 141.1 3.7C140.9 3.7 140.8 3.7 140.7 3.7C140.6 3.7 140.5 3.70001 140.4 3.80001C140.3 3.80001 140.3 3.9 140.3 3.9C140.3 3.9 140.2 4 140.2 4.1L138.4 7.80001C138.2 8.20001 138.1 8.5 137.9 8.9C137.7 9.3 137.6 9.7 137.4 10C137.2 9.6 137.1 9.20001 136.9 8.80001C136.7 8.40001 136.6 8 136.4 7.7L134.6 4C134.6 3.9 134.5 3.90001 134.5 3.80001C134.5 3.80001 134.4 3.7 134.3 3.7C134.2 3.7 134.1 3.7 134 3.6C133.9 3.5 133.7 3.6 133.5 3.6C133.3 3.6 133.1 3.6 133 3.6C132.9 3.6 132.8 3.6 132.7 3.7C132.7 3.7 132.6 3.8 132.7 3.9C132.8 4 132.8 4.10001 132.8 4.30001L136.4 11.6V16.4C136.4 16.5 136.4 16.5 136.4 16.5L136.5 16.6C136.5 16.6 136.7 16.6 136.8 16.7C136.9 16.7 137 16.7 137.2 16.7C137.4 16.7 137.5 16.7 137.6 16.7C137.7 16.7 137.8 16.7 137.9 16.6C138 16.5 138 16.5 138 16.5V16.4V11.6L141.6 4.30001C141.7 4.20001 141.7 4 141.7 4C142.1 3.9 142 3.90001 142 3.80001Z'
        fill='white'
      />
      <motion.path
        d='M72.6 28.5C72.5 28.5 72.3 28.5 72.1 28.5C71.9 28.5 71.8 28.5 71.6 28.5C71.4 28.5 71.4 28.5 71.3 28.6C71.2 28.6 71.2 28.7 71.2 28.7C71.2 28.7 71.2 28.8 71.1 28.9L68.3 39.7L65.3 28.9C65.3 28.8 65.3 28.8 65.2 28.7C65.1 28.6 65.1 28.6 65.1 28.6C65 28.6 64.9 28.6 64.8 28.5C64.7 28.5 64.5 28.5 64.4 28.5C64.2 28.5 64 28.5 63.9 28.5C63.8 28.5 63.7 28.5 63.6 28.6C63.5 28.6 63.5 28.7 63.5 28.7C63.5 28.7 63.4 28.8 63.4 28.9L60.6 39.7L57.7 28.9C57.7 28.8 57.7 28.8 57.6 28.7L57.5 28.6C57.4 28.6 57.3 28.6 57.2 28.5C57.1 28.5 56.9 28.5 56.7 28.5C56.5 28.5 56.3 28.5 56.2 28.5C56.1 28.5 56 28.5 55.9 28.6C55.8 28.6 55.8 28.7 55.8 28.8C55.8 28.9 55.8 29 55.9 29.2L59.3 41.1C59.3 41.2 59.4 41.3 59.4 41.3C59.4 41.4 59.5 41.4 59.6 41.4C59.7 41.4 59.8 41.5 60 41.5C60.1 41.5 60.3 41.5 60.5 41.5C60.7 41.5 60.9 41.5 61 41.5C61.1 41.5 61.2 41.5 61.3 41.4C61.4 41.4 61.5 41.3 61.5 41.3C61.5 41.2 61.6 41.2 61.6 41.1L64.1 31.4L66.8 41.1C66.8 41.2 66.9 41.3 66.9 41.3C66.9 41.4 67 41.4 67.1 41.4C67.2 41.4 67.3 41.5 67.5 41.5C67.6 41.5 67.8 41.5 68 41.5C68.2 41.5 68.4 41.5 68.5 41.5C68.6 41.5 68.8 41.5 68.8 41.4C68.9 41.4 69 41.3 69 41.3C69.1 41.2 69.1 41.2 69.1 41.1L72.5 29.2C72.5 29 72.6 28.9 72.6 28.8C72.6 28.7 72.6 28.6 72.5 28.6C72.8 28.5 72.7 28.5 72.6 28.5Z'
        fill='white'
      />
      <motion.path
        d='M79.6 28.8C79.6 28.7 79.5 28.7 79.5 28.6C79.5 28.6 79.4 28.5 79.3 28.5C79.2 28.5 79.1 28.5 79 28.4C78.9 28.4 78.7 28.4 78.5 28.4C78.3 28.4 78.1 28.4 78 28.4C77.9 28.4 77.8 28.4 77.7 28.5C77.6 28.5 77.6 28.6 77.5 28.6C77.5 28.6 77.4 28.7 77.4 28.8L73.1 40.8C73 40.9 73 41.1 73 41.2C73 41.3 73 41.4 73.1 41.4C73.1 41.4 73.2 41.5 73.4 41.5C73.5 41.5 73.7 41.5 73.9 41.5C74.1 41.5 74.2 41.5 74.4 41.5C74.5 41.5 74.6 41.5 74.7 41.4C74.8 41.3 74.8 41.3 74.8 41.3C74.8 41.3 74.9 41.2 74.9 41.1L76 38H81.4L82.5 41.1C82.5 41.2 82.6 41.2 82.6 41.2L82.7 41.3C82.8 41.3 82.9 41.3 83 41.3C83.1 41.3 83.3 41.3 83.5 41.3C83.7 41.3 83.9 41.3 84 41.3C84.1 41.3 84.2 41.3 84.3 41.2C84.4 41.1 84.4 41.1 84.4 41C84.4 40.9 84.4 40.8 84.3 40.6L79.6 28.8ZM76.2 36.7L78.4 30.3L80.7 36.7H76.2Z'
        fill='white'
      />
      <motion.path
        d='M92.5 28.7C92.5 28.6 92.4 28.6 92.4 28.6H92.3H83.3C83.2 28.6 83.2 28.6 83.2 28.6L83.1 28.7C83.1 28.8 83.1 28.8 83 28.9C83 29 83 29.1 83 29.2C83 29.3 83 29.4 83 29.5C83 29.6 83 29.7 83.1 29.7C83.1 29.8 83.2 29.8 83.2 29.8H83.3H86.9V41C86.9 41.1 86.9 41.1 86.9 41.1L87 41.2C87 41.2 87.2 41.2 87.3 41.3C87.4 41.4 87.5 41.3 87.7 41.3C87.9 41.3 88 41.3 88.1 41.3C88.2 41.3 88.3 41.3 88.4 41.2C88.5 41.1 88.5 41.1 88.5 41.1V41V30H92.1C92.2 30 92.2 30 92.2 30L92.3 29.9C92.3 29.8 92.4 29.8 92.4 29.7C92.4 29.6 92.4 29.5 92.4 29.4C92.4 29.3 92.4 29.2 92.4 29.1C92.6 28.8 92.6 28.7 92.5 28.7Z'
        fill='white'
      />
      <motion.path
        d='M102.6 39C102.6 38.9 102.6 38.9 102.5 38.9H102.4C102.3 38.9 102.2 39 102 39.1C101.8 39.2 101.6 39.4 101.3 39.5C101 39.7 100.7 39.8 100.3 39.9C99.9 40 99.5 40.1 99 40.1C98.4 40.1 97.8 40 97.4 39.8C96.9 39.6 96.5 39.3 96.2 38.8C95.9 38.4 95.6 37.9 95.4 37.2C95.2 36.5 95.1 35.8 95.1 35C95.1 34.2 95.2 33.4 95.4 32.8C95.6 32.2 95.8 31.6 96.2 31.2C96.6 30.8 96.9 30.4 97.4 30.2C97.9 30 98.4 29.9 99 29.9C99.5 29.9 100 30 100.4 30.1C100.8 30.2 101.1 30.4 101.4 30.5C101.7 30.7 101.9 30.8 102 30.9C102.2 31 102.3 31.1 102.4 31.1C102.5 31.1 102.5 31.1 102.5 31.1L102.6 31C102.6 30.9 102.6 30.9 102.7 30.8C102.7 30.7 102.7 30.6 102.7 30.5C102.7 30.4 102.7 30.3 102.7 30.2C102.7 30.1 102.7 30 102.7 30C102.7 29.9 102.7 29.9 102.6 29.8C102.6 29.8 102.5 29.7 102.4 29.6C102.3 29.5 102.1 29.4 101.9 29.3C101.7 29.2 101.4 29 101.1 28.9C100.8 28.8 100.5 28.7 100.1 28.6C99.7 28.5 99.4 28.5 99 28.5C98.2 28.5 97.4 28.7 96.7 29C96 29.3 95.4 29.7 94.9 30.3C94.4 30.9 94 31.6 93.7 32.4C93.4 33.2 93.3 34.2 93.3 35.3C93.3 36.3 93.4 37.3 93.7 38.1C94 38.9 94.3 39.6 94.8 40.1C95.3 40.6 95.9 41.1 96.5 41.3C97.1 41.5 97.9 41.7 98.8 41.7C99.3 41.7 99.7 41.7 100.1 41.6C100.5 41.5 100.9 41.4 101.2 41.3C101.5 41.2 101.8 41 102 40.9C102.2 40.8 102.4 40.7 102.4 40.6C102.4 40.5 102.5 40.5 102.5 40.4C102.5 40.3 102.5 40.3 102.6 40.2C102.6 40.1 102.6 40.1 102.6 40C102.6 39.9 102.6 39.8 102.6 39.7C102.6 39.5 102.6 39.4 102.6 39.3C102.6 39.1 102.6 39 102.6 39Z'
        fill='white'
      />
      <motion.path
        d='M114.1 28.5C114 28.5 113.9 28.5 113.8 28.4C113.7 28.4 113.6 28.4 113.4 28.4C113.2 28.4 113.1 28.4 113 28.4C112.9 28.4 112.8 28.4 112.7 28.5C112.6 28.5 112.6 28.6 112.6 28.6V28.7V33.9H106.7V28.7C106.7 28.6 106.7 28.6 106.7 28.6L106.6 28.5C106.5 28.5 106.4 28.5 106.3 28.4C106.2 28.4 106.1 28.4 105.9 28.4C105.7 28.4 105.6 28.4 105.5 28.4C105.4 28.4 105.3 28.4 105.2 28.5C105.1 28.5 105.1 28.6 105.1 28.6V28.7V41.1C105.1 41.2 105.1 41.2 105.1 41.2L105.2 41.3C105.2 41.3 105.4 41.3 105.5 41.4C105.6 41.5 105.7 41.4 105.9 41.4C106.1 41.4 106.2 41.4 106.3 41.4C106.4 41.4 106.5 41.4 106.6 41.3C106.7 41.2 106.7 41.2 106.7 41.2V41.1V35.4H112.6V41.1C112.6 41.2 112.6 41.2 112.6 41.2L112.7 41.3C112.8 41.3 112.9 41.3 113 41.4C113.1 41.5 113.2 41.4 113.4 41.4C113.6 41.4 113.7 41.4 113.8 41.4C113.9 41.4 114 41.4 114.1 41.3C114.2 41.2 114.2 41.2 114.2 41.2V41.1V28.8C114.2 28.7 114.2 28.7 114.2 28.7C114.2 28.6 114.1 28.6 114.1 28.5Z'
        fill='white'
      />
    </motion.svg>
  );
}

export default Integrity;
