/** @format */

import { BsThreeDots } from "react-icons/bs";

const TeamMembers = ({ MemberData }) => {
  const userphoto = `${process.env.REACT_APP_Backend_API}/upload/profile/`;

  return (
    <div className='flex flex-col w-full h-full p-4 bg-white rounded-3xl'>
      <div className='flex flex-row items-center justify-between '>
        <h1 className=' text-lg font-bold text-[#2B3674]  '>Team Members</h1>
        <div className='flex justify-center bg-[#F4F7FE] items-center w-9 h-9 rounded-lg  '>
          <BsThreeDots />
        </div>
      </div>
      {MemberData.map((member, index) => (
        <div
          key={index}
          className=' flex items-center mt-4 shadow-md shadow-gray-300 justify-between w-full h-[70px] p-3  rounded-2xl  '
        >
          <div className='flex items-center gap-4'>
            <div className='flex items-center justify-center w-10 h-10 rounded-full '>
              {" "}
              <img
                className='object-cover w-full h-full bg-blue-100 rounded-full '
                src={userphoto + member.image}
                alt='users'
              />{" "}
            </div>
            <div className='flex flex-col ml-1 '>
              <h1 className='text-base font-bold text-[#2B3674]'>
                {" "}
                {member.username}{" "}
              </h1>
              <p className='text-[#A3AED0] text-xs font-semibold '>
                {" "}
                {member.role}{" "}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TeamMembers;
