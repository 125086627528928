/** @format */

import { MdKeyboardArrowRight } from "react-icons/md";
import monitor from "assets/image/monitor.png";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { useAnimation } from "framer-motion";

const Monitoring = () => {
  const { ref, inView } = useInView({
    threshold: 0.2,
  });
  const fade = useAnimation();
  const animation1 = useAnimation();
  useEffect(() => {
    if (inView) {
      fade.start({
        opacity: 1,
        transition: { duration: 0.5 },
      });
    }
    if (!inView) {
      fade.start({
        opacity: 0,
      });
    }
  }, [inView]);
  return (
    <div
      ref={ref}
      className='max-w-screen-xl  mx-auto px-8 flex flex-col justify-center items-center  pb-[140px]'
    >
      <motion.div animate={fade} className='w-full h-full '>
        <img src={monitor} className='w-full h-full ' alt='' />
      </motion.div>

      <motion.div
        animate={animation1}
        className='flex flex-col w-full md:w-[530px] items-center'
      >
        <h1 className=' text-[#1AA9B8] dark:text-white font-custom2 font-bold text-[18px] xl:text-[40px] '>
          COMMUNITYMONITORING.ORG
        </h1>
        <p className=' dark:text-white text-[#575757] font-calibrilight text-center text-base px-2 lg:text-[22px] mt-[30px] '>
          <a
            href='https://communitymonitoring.org'
            className='text-[#1AA9B8] dark:text-white'
          >
            www.communitymonitoring.org
          </a>{" "}
          is a web application used by Integrity Volunteers to record
          community-based monitoring activities across provinces in Afghanistan.
        </p>
        <div>
          <a
            href='https://communitymonitoring.org '
            className='flex  font-calibriregular dark:bg-dark text-base lg:text-[22px] px-2 lg:px-9 py-[5px] gap-1 items-center justify-between  transition-colors duration-500 mt-[20px] hover:cursor-pointer hover:bg-[#005362] bg-[#1AA9B8] rounded-xl text-white '
          >
            <h1> Visit communitymonitoring.org</h1>
            <p className='text-lg md:text-[27px] mt-[3px] '>
              <MdKeyboardArrowRight />
            </p>
          </a>
        </div>
      </motion.div>
    </div>
  );
};

export default Monitoring;
