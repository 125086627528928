/** @format */
import { Route, Routes } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Sidebar from "components/sidebar";
import Navbar from "components/navbar/NavbarAdmin";
// import NoMatch from "view/pages/nomatch";
import StoriesAdmin from "view/admin/storiesAdmin";
import JobsAdmin from "view/admin/jobsAdmin";
import BidsAdmin from "view/admin/bidsAdmin";
import AdminHome from "view/admin";
import AddStory from "view/admin/storiesAdmin/AddStory";
import UpdateStory from "view/admin/storiesAdmin/UpdateStory";
import SinglePostAdmin from "view/admin/storiesAdmin/SinglePostAdmin";
// import AddJob from "view/admin/jobsAdmin/AddJob";
import SingleJob from "view/admin/jobsAdmin/SingleJobs";
import UpdateJob from "view/admin/jobsAdmin/UpdateJob";
import AddBid from "view/admin/bidsAdmin/AddBid";
import SingleBidAdmin from "view/admin/bidsAdmin/SingleBidAdmin";
import UpdateBid from "view/admin/bidsAdmin/UpdateBid";
// import Setting from "view/admin/setting";
import { AuthContext } from "context/authConext";
import { useContext } from "react";
import axios from "axios";
import ContactAdmin from "view/admin/contactAdmin";
import SingleContact from "view/admin/contactAdmin/SingleContact";
import PolicyAdmin from "view/admin/policyAdmin";
// import AddPolicy from "view/admin/policyAdmin/AddPolicy";
// import UpdatePolicy from "view/admin/policyAdmin/UpdatePolicy";
import SinglePolicyAdmin from "view/admin/policyAdmin/SinglePolicyAdmin";
import UsersPage from "view/admin/users";
import UserDetails from "view/admin/users/UserDetail";
import RegisterValidation from "view/admin/register/RegisterValidation";
import UpdateUserValidate from "view/admin/users/UpdateUserValidate";
import SettingValidation from "view/admin/setting/Setting";
import AddStoryValidate from "view/admin/storiesAdmin/AddStoryValidate";
import UpdateStoryValidate from "view/admin/storiesAdmin/UpdateStoryValidate";
import AddBidValidate from "view/admin/bidsAdmin/AddBidValidate";
import UpdateBidValidate from "view/admin/bidsAdmin/UpdateBidValidate";
import AddPolicyValidate from "view/admin/policyAdmin/AddPolicyValidate";
import UpdatePolicyValidate from "view/admin/policyAdmin/UpdatePolicyValidate";
import NotAuthorized from "view/admin/noAuthorized/NotAuthorized";
import AddJobValidate from "view/admin/jobsAdmin/AddJobValidate";
import UpdateJobValidate from "view/admin/jobsAdmin/UpdateJobValidation";
import AddJob from "view/admin/jobsAdmin/AddJob";

function Admin() {
  const [open, setOpen] = React.useState(true);
  const { currentUser } = useContext(AuthContext);

  const [user, setUser] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_Backend_API}/api/auth/register/${currentUser.id}`
        );

        setUser(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [currentUser.id]);

  return (
    <div className="w-full bg-[#F4F7FE] h-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      {/* Navbar & Main Content */}
      <div className="w-full h-full ">
        {/* Main Content */}
        <main
          className={`flex-none pr-md-5 ml-3 bg-[#F4F7FE] xl:ml-[313px] xl:mr-2 mr-0 transition-all`}
        >
          {/* Routes */}
          <div>
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              logoText={"Horizon UI Dashboard PRO"}
            />
            <div className="min-h-screen p-2 mx-auto mt-4 p-md-7">
              {user.role === "admin" ? (
                <Routes>
                  <Route path="/" element={<AdminHome />} />
                  <Route path="/register" element={<RegisterValidation />} />

                  <Route path="/profile" element={<SettingValidation />} />
                  <Route path="/stories" element={<StoriesAdmin />} />
                  {/* <Route path="/stories/addstory" element={<AddStory />} /> */}
                  <Route
                    path="/stories/addstory"
                    element={<AddStoryValidate />}
                  />
                  <Route
                    path="/stories/updatestory/:id"
                    element={<UpdateStoryValidate />}
                  />
                  <Route path="/contacts" element={<ContactAdmin />} />
                  <Route path="/contacts/:id" element={<SingleContact />} />

                  <Route path="/users" element={<UsersPage />} />
                  <Route path="/users/:id" element={<UserDetails />} />
                  <Route
                    path="/users/updateuser/:id"
                    element={<UpdateUserValidate />}
                  />

                  <Route path="/policies" element={<PolicyAdmin />} />
                  <Route
                    path="/policies/addpolicy"
                    element={<AddPolicyValidate />}
                  />

                  <Route
                    path="/policies/updatepolicy/:id"
                    element={<UpdatePolicyValidate />}
                  />

                  <Route path="/policies/:id" element={<SinglePolicyAdmin />} />

                  <Route path="/stories/:id" element={<SinglePostAdmin />} />
                  <Route path="/jobs" element={<JobsAdmin />} />
                  <Route path="/jobs/addjob" element={<AddJobValidate />} />

                  <Route path="/jobs/:id" element={<SingleJob />} />
                  <Route
                    path="/jobs/updatejob/:id"
                    element={<UpdateJobValidate />}
                  />
                  <Route path="/bids" element={<BidsAdmin />} />
                  <Route path="/bids/addbid" element={<AddBidValidate />} />

                  <Route path="/bids/:id" element={<SingleBidAdmin />} />
                  <Route
                    path="/bids/updatebid/:id"
                    element={<UpdateBidValidate />}
                  />

                  <Route path="/*" element={<NotAuthorized />} />
                </Routes>
              ) : user.role === "jobs" ? (
                <Routes>
                  <Route path="/" element={<AdminHome />} />
                  <Route path="/profile" element={<SettingValidation />} />
                  <Route path="/jobs" element={<JobsAdmin />} />
                  <Route path="/jobs/addjob" element={<AddJobValidate />} />
                  <Route path="/jobs/:id" element={<SingleJob />} />
                  <Route
                    path="/jobs/updatejob/:id"
                    element={<UpdateJobValidate />}
                  />
                  <Route path="/bids" element={<BidsAdmin />} />
                  <Route path="/bids/addbid" element={<AddBidValidate />} />
                  <Route path="/bids/:id" element={<SingleBidAdmin />} />
                  <Route
                    path="/bids/updatebid/:id"
                    element={<UpdateBidValidate />}
                  />
                  <Route path="/*" element={<NotAuthorized />} />
                </Routes>
              ) : (
                <Routes>
                  <Route path="/" element={<AdminHome />} />
                  <Route path="/profile" element={<SettingValidation />} />
                  <Route path="/stories" element={<StoriesAdmin />} />
                  <Route
                    path="/stories/addstory"
                    element={<AddStoryValidate />}
                  />
                  <Route
                    path="/stories/updatestory/:id"
                    element={<UpdateStoryValidate />}
                  />
                  <Route path="/stories/:id" element={<SinglePostAdmin />} />
                  <Route path="/*" element={<NotAuthorized />} />
                </Routes>
              )}
            </div>
            <div>{/* <Footer /> */}</div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Admin;
