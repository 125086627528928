/** @format */
 
import React from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import map from "assets/Where we work/map.png";
function Country() {
  return (
  <div className="w-full pl-8 pr-4 lg:px-0 mt-14 lg:mt-20 ">
       {/* header */}
      <h1 className="uppercase dark:text-white text-grayPrimary text-[30px] font-bold ">
       {/* Country working in*/}
      </h1>
      <div className="mt-8">
        <div className="flex items-center justify-between mt-8">
          <h1 className="uppercase text-[25px] dark:text-white text-grayPrimary">
          {/*  afghanistan */}
          </h1>
         {/* <a
            href="https://communitymonitoring.org"
            className="flex bg-white dark:text-white dark:bg-darkMain dark:hover:bg-dark hover:bg-grayPrimary text-grayPrimary hover:text-white cursor-pointer border border-grayPrimary justify-center items-center pr-1  px-[20px] py-[5px] transition-colors duration-300 font-medium ease-in-out rounded-xl text-sm "
          >
            Visit Webpage
            <p className="text-xl ">
              <MdOutlineKeyboardArrowRight />
            </p>
          </a>*/}
        </div>
        {/* line */}
		{/*  
        <div className="w-full h-[2px] dark:bg-white mt-1 bg-grayPrimary "></div>*/}
		  </div>
      {/* map */}
	{/* 
      <div className='w-full h-full mt-32 '>
        <img src={map} alt='' />
      </div>  */}
    </div>
  );
}

export default Country;
