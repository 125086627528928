/** @format */

import React from "react";

function Paragraph(props) {
  const {
    title,
    title2,
    title3,
    description,
    description2,
    description3,
    line,
  } = props;
  return (
    <div className='mb-4 '>
      <h1 className='text-base font-bold text-left lg:text-2xl dark:text-white font-calibriregular text-grayDark2 lg:text-center'>
        {title}
      </h1>
      <h2 className='text-base font-bold text-left lg:text-lg dark:text-white font-calibriregular text-grayDark2 lg:text-center'>
        {title2}
      </h2>

      <p className='text-base text-left font-calibrilight dark:text-white lg:text-lg text-grayDark lg:text-center'>
        {description}
      </p>
      <h3 className='mt-5 text-base font-bold text-left lg:text-lg dark:text-white font-calibriregular text-grayDark2 lg:text-center'>
        {title3}
      </h3>
      <p className='mt-4 text-base text-left font-calibrilight dark:text-white lg:text-lg text-grayDark lg:text-center'>
        {description2}
      </p>
      <p className='mt-4 text-base text-left font-calibrilight dark:text-white lg:text-lg text-grayDark lg:text-center'>
        {description3}
      </p>
      <div className='flex justify-center full '>
        <div className={`w-[1px] dark:bg-white bg-grayPrimary ${line}`}></div>
      </div>
    </div>
  );
}

export default Paragraph;
