/** @format */

import IntegrityColor from "components/icons/IntegrityColor";
import Navbars from "components/navbar/Navbars";
import Content from "./components/Content";
import Header from "./components/Header";

const Story = () => {
  return (
    <div className='w-full dark:bg-darkMain bg-white h-full pb-32   '>
      <div>
        <Navbars
          textColor={"text-textPrimary"}
          bg='bg-[#FAFAFA] dark:bg-darkHeader'
          logo={<IntegrityColor />}
        />
      </div>
      <div>
        <Header />
      </div>
      <div>
        <Content />
      </div>
    </div>
  );
};

export default Story;
