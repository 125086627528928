/** @format */
import axios from "axios";
import MainCard from "components/cards";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import StoryTable from "./StoryTable";
import { columnsDataUsersOverview } from "./variables/columnsDataUsersOverview";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const StoriesAdmin = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_Backend_API}/api/posts/`
        );
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="w-full h-full">
      <ToastContainer position="top-center" />
      {/* header */}
      <MainCard extra={"flex items-center justify-between h-[100px] px-8  "}>
        <button>
          <Link
            className="px-3 py-[16px] rounded-2xl text-lg font-banscrift font-bold text-white hover:bg-gray-500 bg-grayPrimary uppercase "
            to="/admin/stories/addstory"
          >
            Add new Story
          </Link>
        </button>
        <h1 className="text-textSecondary uppercase text-[30px] font-banscrift font-bold ">
          Stories Page
        </h1>
      </MainCard>
      <MainCard extra={"w-full  mt-4 "}></MainCard>
      {/* post list */}
      <div>
        <StoryTable tableData={posts} columnsData={columnsDataUsersOverview} />
      </div>
    </div>
  );
};

export default StoriesAdmin;
