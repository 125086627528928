/** @format */

export const columnsDataUsersOverview = [
  {
    Header: "Id",
    accessor: "id",
  },

  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Message",
    accessor: "message",
  },
];
