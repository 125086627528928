/** @format */

import axios from "axios";
import MainCard from "components/cards";
import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { toast } from "react-toastify";
import "react-quill/dist/quill.snow.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import TextField from "./variables/TextField";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const reader = new FileReader();

function UpdatePolicyValidate() {
  const ValidateSchema = Yup.object().shape({
    title: Yup.string().required("Title is Required!"),
    description: Yup.string().required("Description is Required!"),
    file: Yup.mixed().required("Policy file is Required!"),
    cover: Yup.mixed().required("Policy Cover is Required!"),
  });
  const [description, setDescription] = useState("");
  const [file, setFile] = useState("");
  const [cover, setCover] = useState("");
  const [title, SetTitle] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [avatarPreview, setAvatarPreview] = useState();

  const navigate = useNavigate();
  const locations = useLocation();
  const policyId = locations.pathname.split("/")[4];

  const handleClick = async (e) => {
    setSubmitting(true);

    var formData = new FormData();
    formData.append("description", description);
    formData.append("file", file);
    formData.append("cover", cover);
    formData.append("title", title);

    try {
      await axios.put(
        `${process.env.REACT_APP_Backend_API}/api/policy/${policyId}`,
        formData,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      toast.success("Policy updated succcessfully!");
      navigate("/admin/policies");
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  const [policy, setPolicy] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_Backend_API}/api/policy/${policyId}`
        );
        SetTitle(res.data.title);
        setDescription(res.data.description);
        setFile(res.data.file);

        setPolicy(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [policyId]);

  const policyphoto = `${process.env.REACT_APP_Backend_API}/upload/policyfile/cover/`;

  return (
    <div>
      <Formik
        initialValues={{
          title: title,
          description: description,
          file: file,
          cover: cover,
        }}
        validationSchema={ValidateSchema}
        onSubmit={handleClick}
        // enableReinitialize={true}
      >
        {(props) => (
          <div>
            <MainCard
              extra={"flex items-center justify-between h-[100px] px-8  "}
            >
              <Link to="/admin/policies">
                <h1 className="text-textSecondary flex items-center gap-2 uppercase text-[30px] font-banscrift font-bold ">
                  <AiOutlineArrowLeft />
                  Back
                </h1>
              </Link>
              <div>
                <h1 className="text-textSecondary flex items-center gap-2 uppercase text-[30px] font-banscrift font-bold ">
                  Update Policy Page
                </h1>
              </div>
            </MainCard>
            <MainCard extra={"mt-4 pt-8 pb-14 px-8"}>
              <Form>
                <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                  <TextField
                    name="title"
                    onChange={(e) => {
                      props.handleChange(e);
                      SetTitle(e.target.value);
                    }}
                    type="text"
                    label="title "
                    value={title}
                  />
                  <TextField
                    name="description"
                    onChange={(e) => {
                      props.handleChange(e);
                      setDescription(e.target.value);
                    }}
                    type="text"
                    label="Description"
                    value={description}
                  />

                  <div className="flex flex-col items-center  !w-full !col-span-2 mb-3  gap-x-14  md:flex-row md:justify-between ">
                    <div className="w-full">
                      <label
                        className={`text-base block text-bluePrimary dark:text-white mb-3 `}
                      >
                        New Cover Preview
                      </label>
                      <div className="">
                        <img
                          src={avatarPreview}
                          width={55}
                          alt="new photo"
                          height={55}
                          className="object-cover rounded-2xl "
                        />
                      </div>
                    </div>

                    <div className="w-full">
                      <TextField
                        name="cover"
                        type="file"
                        label="Policy Cover  "
                        onChange={(e) => {
                          props.handleChange(e);
                          // setFile(e.target.files[0]);
                          reader.onload = () => {
                            if (reader.readyState === 2) {
                              setAvatarPreview(reader.result);
                            }
                          };
                          reader.readAsDataURL(e.target.files[0]);
                          setCover(e.target.files[0]);
                        }}
                      />
                    </div>
                    <div className="w-full">
                      <label
                        className={`text-base block text-bluePrimary dark:text-white mb-3 `}
                      >
                        Old Cover
                      </label>
                      <div className="">
                        <img
                          src={policyphoto + policy.cover}
                          width={55}
                          alt="old"
                          height={55}
                          className="object-cover rounded-2xl "
                        />
                      </div>
                    </div>
                  </div>

                  <TextField
                    name="file"
                    type="file"
                    label="Policy File  "
                    onChange={(e) => {
                      props.handleChange(e);
                      setFile(e.target.files[0]);
                    }}
                  />
                  {/* <TextField
                    name="cover"
                    type="file"
                    label="Policy Cover"
                    onChange={(e) => {
                      props.handleChange(e);
                      setCover(e.target.files[0]);
                    }}
                  /> */}
                </div>

                <div className="flex justify-end w-full mt-4">
                  <button
                    type="submit"
                    disabled={submitting}
                    className="px-4 py-1 text-white bg-blue-900 rounded-lg"
                  >
                    {submitting ? "Updating..." : "Update"}
                  </button>
                  <button
                    type="reset"
                    className="px-4 py-1 ml-3 text-white bg-red-900 rounded-lg"
                  >
                    Reset
                  </button>
                </div>
              </Form>
            </MainCard>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default UpdatePolicyValidate;
