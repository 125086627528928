import MainCard from "components/cards";
import React from "react";
import Edit from "./components/Edit";

const SettingValidation = () => {
  return (
    <div className="flex items-center justify-center h-full pb-10 mt-10">
      <div className="w-1/2 mt-4">
        <div className="flex items-center justify-center w-full ">
          <MainCard extra="w-full pb-10 px-4  pt-8 ">
            <h1 className="text-gray-900 mb-[10px] font-bold text-4xl">
              Edit Your Profile
            </h1>
            <p className="ml-1 text-base mb-9 text-lightSecondary"></p>
            <Edit />
          </MainCard>
        </div>
      </div>
    </div>
  );
};

export default SettingValidation;
