/** @format */

import React from "react";
import story from "assets/Success Stories/story.png";

function Header() {
  return (
    <div className='bg-colorWhite dark:bg-darkHeader w-full px-6 lg:px-0 h-[200px] lg:h-[300px]  '>
      {/* headeer */}
      <div className='max-w-screen-xl h-full relative mx-auto grid grid-cols-10'>
        <div className=' col-span-10 lg:col-span-5 flex flex-col justify-center text-left '>
          <h1 className='text-grayPrimary mt-6 uppercase text-[25px] sm:text-[30px] lg:text-[35px] font-bold'>
            Our impact stories
          </h1>
         {/* <h1 className='uppercase text-textPrimary text-[30px] sm:text-[35px] lg:text-[40px] font-bold mt-4 lg:mt-6 '>
            Our success Stories
          </h1>*/}
        </div>
        <div className='hidden lg:flex justify-end  col-span-5'>
          <div className='h-[170px] absolute top-32 right-32 '>
         {/*   <img src={story} className='w-full  h-full ' alt='' />*/}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
