/** @format */

import axios from "axios";
import Widget from "components/displayData/Widget";
import { useEffect, useState } from "react";
import { BsEyeFill } from "react-icons/bs";
import { FaDatabase } from "react-icons/fa";
import { MdMuseum, MdPeople } from "react-icons/md";
import TeamMembers from "./components/TeamMembers";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

const AdminHome = () => {
  const [posts, setPosts] = useState([]);
  axios.defaults.withCredentials = true;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_Backend_API}/api/posts/`
        );
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);
  const [bids, setBids] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_Backend_API}/api/bids/`
        );
        setBids(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_Backend_API}/api/jobs/`
        );
        setJobs(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const [users, setUsers] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_Backend_API}/api/users/`
        );
        setUsers(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  //fetching from community monitoring

  const [monitored, setMonitored] = useState([]);
  const fetchPosts = async () => {
    const response = await fetch(
      "https://api.communitymonitoring.org/api/external/sites-monitored"
    );
    const data = await response.json();

    setMonitored(data);
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <div className=" grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3  gap-[19px] mt-3 h-full ">
      <div className="w-full col-span-1 xl:col-span-1 2xl:col-span-2 h-fit">
        {/* <ToastContainer position="top-center" /> */}
        <div className="grid grid-cols-3 gap-4">
          <Widget
            icon={<MdMuseum />}
            title="Jobs Announced"
            subtitle={jobs.length}
          />
          <Widget
            icon={<FaDatabase />}
            title="Bids announced"
            subtitle={bids.length}
          />
          <Widget
            icon={<FaDatabase />}
            title="Stories Published"
            subtitle={posts.length}
          />
          {/* <Widget
            icon={<BsEyeFill />}
            title="Policies published"
            subtitle="77"
          /> */}
          <Widget
            icon={<MdPeople />}
            title="Volunteers"
            subtitle={monitored["total-volunteers"]}
          />

          <Widget
            icon={<MdMuseum />}
            title="site monitored"
            subtitle={monitored["total-sites-monitored"]}
          />
          <Widget
            icon={<MdMuseum />}
            title="total problems"
            subtitle={monitored["total-problems"]}
          />
          <Widget
            icon={<MdMuseum />}
            title="problem solved"
            subtitle={monitored["total-problems-solved"]}
          />
        </div>
      </div>

      {/* right side section */}

      <div className="w-full h-full col-span-1 overflow-hidden 2xl:col-span-1 bg-lightPrimary rounded-2xl ">
        <TeamMembers MemberData={users} />
      </div>
    </div>
  );
};

export default AdminHome;
