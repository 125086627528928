/** @format */

import IntegrityColor from "components/icons/IntegrityColor";
import Navbars from "components/navbar/Navbars";
import Content from "./components/Content";
import Header from "./components/Header";

const Details = () => {
  return (
    <div className='w-full h-full pb-20 dark:bg-darkMain '>
      <div>
        <Navbars
          textColor={"text-textPrimary"}
          bg='bg-colorWhite dark:bg-darkHeader'
          logo={<IntegrityColor />}
        />
      </div>
      <div>
        <Header />
      </div>
      <div className='max-w-screen-xl px-[20px] mx-auto md:px-0'>
        <Content />
      </div>
    </div>
  );
};

export default Details;
