/** @format */
import React from "react";
function Widget({ icon, title, subtitle }) {
  return (
    <div className="flex gap-6 justify-between items-center px-4  h-[97px] w-full  bg-white rounded-2xl ">
      <div className="flex flex-col justify-center h-50 ">
        <p className="text-[#A3AED0] font-poppins leading-4 font-medium  text-base ">
          {title}
        </p>
        <h1 className="text-[#2B3674] capitalize mt-[2px] font-banscrift leading-8 font-bold text-2xl ">
          {" "}
          {subtitle}
        </h1>
      </div>
      <div className="w-[55px]  text-2xl flex text-gray-900 items-center justify-center h-[55px] rounded-full bg-[#F4F7FE] ">
        {icon}
      </div>
    </div>
  );
}

export default Widget;
