/** @format */

import axios from "axios";
import MainCard from "components/cards";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { toast } from "react-toastify";
import "react-quill/dist/quill.snow.css";
import { Link, useNavigate } from "react-router-dom";

function AddBid() {
  const [description, setDescription] = useState("");
  const [posted, setPosted] = useState("");
  const [closing, setClosing] = useState("");
  const [location, setLocation] = useState("");
  const [file, setFile] = useState("");
  const [title, setTitle] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);

  const navigate = useNavigate();

  const handleClick = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    var formData = new FormData();
    formData.append("description", description);
    formData.append("posted", posted);
    formData.append("closing", closing);

    formData.append("location", location);
    formData.append("file", file);
    formData.append("title", title);

    try {
      await axios.post(
        `${process.env.REACT_APP_Backend_API}/api/bids/`,
        formData,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      toast.success("Bid added successfully!");
      navigate("/admin/bids");
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="">
      <MainCard extra={"flex items-center justify-between h-[100px] px-8  "}>
        <Link to="/admin/bids">
          <h1 className="text-textSecondary flex items-center gap-2 uppercase text-[30px] font-banscrift font-bold ">
            <AiOutlineArrowLeft />
            Back
          </h1>
        </Link>
      </MainCard>
      <MainCard extra={"mt-4 p-8"}>
        <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
          {/* title  */}
          <div className="mb-4">
            <label className={`text-sm text-bluePrimary dark:text-white mb-2 `}>
              Bid Title
            </label>
            <input
              type="text"
              name="title"
              placeholder="Title"
              onChange={(e) => setTitle(e.target.value)}
              className="outline-none dark:bg-navy-800 dark:!border-navy-700 border text-sm flex items-center justify-center border-lightPrimary w-full p-3 h-12 rounded-xl"
            />
          </div>
          {/* Bid Description  */}
          <div className="mb-4">
            <label className={`text-sm text-bluePrimary dark:text-white mb-2 `}>
              Bid Description
            </label>
            <input
              type="text"
              name="description"
              placeholder="description"
              onChange={(e) => setDescription(e.target.value)}
              className="outline-none dark:bg-navy-800 dark:!border-navy-700 border text-sm flex items-center justify-center border-lightPrimary w-full p-3 h-12 rounded-xl"
            />
          </div>
          {/*Bid psted  */}
          <div className="mb-4">
            <label className={`text-sm text-bluePrimary dark:text-white mb-2 `}>
              Bid Posted date
            </label>
            <input
              type="date"
              name="posted"
              placeholder="Posted"
              onChange={(e) => setPosted(e.target.value)}
              className="outline-none dark:bg-navy-800 dark:!border-navy-700 border text-sm flex items-center justify-center border-lightPrimary w-full p-3 h-12 rounded-xl"
            />
          </div>
          <div className="mb-4">
            <label className={`text-sm text-bluePrimary dark:text-white mb-2 `}>
              Bid Closing date
            </label>
            <input
              type="date"
              name="closing"
              placeholder="Closing date"
              onChange={(e) => setClosing(e.target.value)}
              className="outline-none dark:bg-navy-800 dark:!border-navy-700 border text-sm flex items-center justify-center border-lightPrimary w-full p-3 h-12 rounded-xl"
            />
          </div>
          <div className="mb-4">
            <label className={`text-sm text-bluePrimary dark:text-white mb-2 `}>
              Bid Location
            </label>
            <input
              type="text"
              name="location"
              placeholder="Location"
              onChange={(e) => setLocation(e.target.value)}
              className="outline-none dark:bg-navy-800 dark:!border-navy-700 border text-sm flex items-center justify-center border-lightPrimary w-full p-3 h-12 rounded-xl"
            />
          </div>
          <div className="mb-4">
            <label className={`text-sm text-bluePrimary dark:text-white mb-2 `}>
              Bid File
            </label>
            <input
              type="file"
              name="file"
              placeholder="Bid File"
              onChange={(e) => setFile(e.target.files[0])}
              className="outline-none dark:bg-navy-800 dark:!border-navy-700 border text-sm flex items-center justify-center border-lightPrimary w-full h-12 rounded-xl"
            />
          </div>
        </div>
        {/* button */}
        <div className="mt-4">
          <button
            disabled={isSubmitting}
            type="submit"
            onClick={handleClick}
            className="px-4 py-1 text-white bg-blue-900 rounded-lg"
          >
            {isSubmitting ? "Creating Bid..." : "Create Bid"}
          </button>
          <button
            type="reset"
            className="px-4 py-1 ml-3 text-white bg-red-900 rounded-lg"
          >
            Reset
          </button>
        </div>
      </MainCard>
    </div>
  );
}

export default AddBid;
