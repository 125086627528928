/** @format */

import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function Content() {
  const [bid, setBid] = useState({});

  const location = useLocation();
  const bidId = location.pathname.split("/")[3];
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_Backend_API}/api/bids/${bidId}`
        );
        setBid(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [setBid]);
  const isExpired = new Date() > new Date(bid.closing);

  //get bid data
  const [bidlimit, setBidlimit] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_Backend_API}/api/bids/bidlimit`
        );
        setBidlimit(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);
  const bidfile = `${process.env.REACT_APP_Backend_API}/upload/bidsfile/`;
  return (
    <div>
      <div className="max-w-screen-xl dark:bg-dark mx-auto mt-14 px-14 py-8 rounded-[20px] bg-colorWhite">
        {/* header */}
        <h1 className="text-textSecondary dark:text-white text-[30px] font-bold">
          {bid.title}
        </h1>
        {/* date */}
        <div className="grid grid-cols-1 mt-6 md:grid-cols-2 md:gap-52">
          <div className="flex items-center justify-between">
            <h1 className="text-xl font-bold text-textSecondary dark:text-white">
              Posted Date:
            </h1>
            <p className="text-xl dark:text-white text-textSecondary">
              {moment(bid.posted).format("MM DD YYYY")}{" "}
            </p>
          </div>

          <div className="flex items-center justify-between mt-4 md:mt-0">
            <h1 className="text-xl font-bold dark:text-white text-textSecondary">
              Closing Date:
            </h1>
            <p className="text-xl dark:text-white text-textSecondary">
              {isExpired ? "Expired" : moment(bid.closing).format("MM DD YYYY")}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 mt-4 md:gap-52 md:grid-cols-2">
          <div className="flex items-center justify-between">
            <h1 className="text-xl font-bold dark:text-white text-textSecondary">
              Location:
            </h1>
            <p className="text-xl text-left dark:text-white text-textSecondary">
              {bid.location}{" "}
            </p>
          </div>
          <div></div>
        </div>
        {/* description */}
        <div className="mb-8">
          <h1 className="text-textSecondary dark:text-white mt-4 text-[30px] font-bold">
            Description
          </h1>
          <p className="text-[18px] mt-3 dark:text-white font-calibriregular">
            {bid.description}
          </p>
        </div>

        <a
          download={`${bid.file}`}
          href={bidfile + bid.file}
          className="px-4 py-3 mt-5 text-white transition-all duration-200 dark:text-white dark:bg-darkHeader dark:hover:bg-darkMain rounded-xl bg-textPrimary hover:bg-textSecondary"
        >
          {" "}
          Download File
        </a>
      </div>
      <div className="w-full px-10 mt-20 overflow-x-scroll md:overflow-hidden">
        <h1 className="font-banscrift dark:text-white mb-4 font-bold text-[#455A64] text-[36px] ">
          Latest bids
        </h1>
        <table className="w-[700px] md:w-full !overflow-x-scroll">
          <thead className="w-full"></thead>
          <tbody>
            {bidlimit.map((limit) => (
              <tr
                onClick={() => navigate(`/announcement/bids/${limit.id}`)}
                key={limit.id}
                className="w-full pl-2 md:px-2 text-[24px] dark:hover:bg-dark hover:bg-gray-100 text-grayPrimary mt-1 hover:text-textSecondary hover:cursor-pointer font-calibriregular  transition-all dark:text-white duration-300"
              >
                <td className="pl-2 !rounded-l-full"> {limit.id} </td>
                <td className="line-clamp-1">{limit.title}</td>
                <td className="">{limit.location} </td>

                <td className="">
                  {" "}
                  {moment(limit.posted).format("MM DD YYYY")}{" "}
                </td>
                <td className="!rounded-r-full">
                  {" "}
                  {/* {moment(limit.closing).format("MM DD YYYY")}{" "} */}
                  {moment(new Date()).format("MM DD YYYY") >
                  moment(limit.closing).format("MM DD YYYY")
                    ? "Expired"
                    : moment(limit.closing).format("MM DD YYYY")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Content;
