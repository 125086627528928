/** @format */
import EditorToolbar, {
  modules,
  formats,
} from "components/Editor/EditorToolbar";
import axios from "axios";
import MainCard from "components/cards";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import TextField from "./variables/TextField";

function UpdateStoryValidate() {
  const ValidateSchema = Yup.object().shape({
    title: Yup.string().nullable("Title is Required!"),
    postby: Yup.string().required("Postby is Required!"),
    location: Yup.string().required("Location is Required!"),
    date: Yup.date().required("Date is Required!"),
    photolocation: Yup.string().required("Photo Location is Required!"),
    // file: Yup.mixed().required("Photo is Required!"),
  });

  const [value, setValue] = useState("");
  const [title, setTitle] = useState("");
  const [date, setDate] = useState(null);
  const [file, setFile] = useState();
  const [postby, setPostby] = useState("");
  const [location, setLocation] = useState("");
  const [photolocation, setPhotolocation] = useState("");
  const [hidden, setHidden] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [avatarPreview, setAvatarPreview] = useState();
  const [startdate, setStartdate] = useState(new Date());
  const [isError, setIsError] = useState("");
  const navigate = useNavigate();
  const locations = useLocation();
  const postId = locations.pathname.split("/")[4];

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_Backend_API}/api/posts/${postId}`)
      .then((resp) => {
        setTitle(resp.data.title);
        setValue(resp.data.description);

        setDate(resp.data.date);
        setPostby(resp.data.postby);
        setLocation(resp.data.location);
        setPhotolocation(resp.data.photolocation);
        setDate(resp.data.date);
        setFile(resp.data.file);
        // setStartdate(resp.data.date);

        setHidden(resp.data.hidden);
      });
  }, [postId]);

  const handleClick = async (e) => {
    setSubmitting(true);

    var formData = new FormData();
    formData.append("title", title);
    formData.append("description", value);
    formData.append("file", file);

    formData.append("date", moment(date).format("YYYY-MM-DD"));
    formData.append("postby", postby);
    formData.append("location", location);
    formData.append("photolocation", photolocation);

    formData.append("hidden", hidden);

    try {
      await axios.put(
        `${process.env.REACT_APP_Backend_API}/api/posts/${postId}`,
        formData,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      toast.success("Story updated successfully!");
      navigate("/admin/stories");
    } catch (error) {
      // console.log("error is here :" + error);
      setIsError("Please fill all inputs :", error);
    } finally {
      setSubmitting(false);
    }
  };
  const photo = `${process.env.REACT_APP_Backend_API}/upload/stories/`;

  //showing photo
  // const onChange = (e) => {
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     if (reader.readyState === 2) {
  //       setAvatarPreview(reader.result);
  //     }
  //   };
  //   setFile(e.target.files[0]);
  //   reader.readAsDataURL(e.target.files[0]);
  // };

  // const aRef = useRef(null);
  // const handleFileChange = (event) => {
  //   const fileObj = event.target.files && event.target.files[0];
  //   if (!fileObj) {
  //     return;
  //   }
  // };

  return (
    <div>
      <Formik
        initialValues={{
          title: title,
          location: location,
          postby: postby,
          photolocation: photolocation,
          date: date,
          file: file,
        }}
        validationSchema={ValidateSchema}
        onSubmit={handleClick}
        enableReinitialize={true}
      >
        {(props) => (
          <div>
            <MainCard
              extra={"flex items-center justify-between h-[100px] px-8  "}
            >
              <Link to="/admin/stories">
                <h1 className="text-textSecondary flex items-center gap-2 uppercase text-[30px] font-banscrift font-bold ">
                  <AiOutlineArrowLeft />
                  Back
                </h1>
              </Link>
              <div>
                <h1 className="text-textSecondary flex items-center gap-2 uppercase text-[30px] font-banscrift font-bold ">
                  Update Story Page
                </h1>
              </div>
            </MainCard>
            <MainCard extra={"mt-4 pt-8 pb-14 px-8"}>
              <Form>
                <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                  <TextField
                    name="title"
                    onChange={(e) => {
                      props.handleChange(e);
                      setTitle(e.target.value);
                    }}
                    type="text"
                    label="title "
                    value={title}
                  />
                  <TextField
                    name="postby"
                    onChange={(e) => {
                      props.handleChange(e);
                      setPostby(e.target.value);
                    }}
                    type="text"
                    label="postby"
                    value={postby}
                  />

                  <TextField
                    name="location"
                    onChange={(e) => {
                      props.handleChange(e);
                      setLocation(e.target.value);
                    }}
                    type="text"
                    label="location"
                    value={location}
                  />
                  <TextField
                    name="photolocation"
                    type="text"
                    label="photo location"
                    onChange={(e) => {
                      props.handleChange(e);
                      setPhotolocation(e.target.value);
                    }}
                    value={photolocation}
                  />
                  <TextField
                    name="date"
                    type="date"
                    label="posted date "
                    onChange={(e) => {
                      props.handleChange(e);
                      setDate(e.target.value);
                    }}
                    value={moment(date).format("YYYY-MM-DD")}
                  />

                  <div className="mb-4">
                    <label
                      className={`text-sm text-bluePrimary dark:text-white mb-2 `}
                    >
                      Visiblity
                    </label>
                    <select
                      className="outline-none dark:bg-navy-800 dark:!border-navy-700 border text-sm flex items-center justify-center border-lightPrimary w-full p-3 full rounded-xl"
                      name="hidden"
                      value={hidden}
                      onChange={(e) => {
                        setHidden(e.target.value);
                      }}
                      id=""
                    >
                      <option>Select Visibilty</option>
                      <option value="false">Show</option>
                      <option value="true">Hide</option>
                    </select>
                  </div>
                  {/* <TextField
                    name="hidden"
                    type="select"
                    label="posted date "
                    onChange={(e) => {
                      props.handleChange(e);
                      setHidden(e.target.value);
                    }}
                  /> */}
                  {/* <TextField
                    name="file"
                    type="file"
                    label="flle  "
                    onChange={(e) => {
                      props.handleChange(e);
                      setFile(e.target.files[0]);
                    }}
                  /> */}
                  {/* <div className="flex flex-col items-center  !w-full !col-span-2 mb-3  gap-x-14  md:flex-row md:justify-between ">
                    <div className="w-full">
                      <label
                        className={`text-base block text-bluePrimary dark:text-white mb-3 `}
                      >
                        New photo Preview
                      </label>
                      <div className="">
                        <img
                          src={avatarPreview}
                          width={55}
                          alt="nephoto"
                          height={55}
                          className="object-cover rounded-2xl "
                        />
                      </div>
                    </div>

                    <div className="w-full">
                      <input
                        type="file"
                        id="file"
                        name="file"
                        onChange={onChange}
                      />
                     
                      <TextField
                        name="file"
                        type="file"
                        label="flle  "
                        onChange={(e) => {
                          props.handleChange(e);
                          // setFile(e.target.files[0]);
                          reader.onload = () => {
                            if (reader.readyState === 2) {
                              setAvatarPreview(reader.result);
                            }
                          };
                          reader.readAsDataURL(e.target.files[0]);
                          setFile(e.target.files[0]);
                        }}
                      />
                    </div>
                    <div className="w-full">
                      <label
                        className={`text-base block text-bluePrimary dark:text-white mb-3 `}
                      >
                        Old photo
                      </label>
                      <div className="">
                        <img
                          src={photo + file}
                          width={55}
                          alt="old"
                          height={55}
                          className="object-cover rounded-2xl "
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="mt-3 rounded-xl ">
                  <label
                    className={`text-sm text-bluePrimary dark:text-white mb-2 `}
                  >
                    Post Description
                  </label>

                  {/* <ReactQuill theme='snow' className='h-[420px] ' onChange={setValue} /> */}
                  <EditorToolbar toolbarId={"t1"} />
                  <ReactQuill
                    className="h-[500px] text-2xl"
                    theme="snow"
                    onChange={setValue}
                    placeholder={"Write post content here..."}
                    modules={modules("t1")}
                    formats={formats}
                    value={value}
                  />
                </div>
                <div className="flex justify-end w-full mt-4">
                  <button
                    type="submit"
                    disabled={submitting}
                    className="px-4 py-1 text-white transition-colors duration-200 bg-blue-800 rounded-lg hover:bg-blue-900"
                  >
                    {submitting ? "Updating..." : "Update Post"}
                  </button>
                  <button
                    type="reset"
                    className="px-4 py-1 ml-3 text-white bg-red-900 rounded-lg"
                  >
                    Reset
                  </button>
                </div>
              </Form>
            </MainCard>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default UpdateStoryValidate;
