/** @format */

import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import MainCard from "components/cards";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AiOutlineArrowLeft } from "react-icons/ai";

const SingleJob = () => {
  const [job, setJob] = useState({});
  const navigate = useNavigate();

  const location = useLocation();
  const jobId = location.pathname.split("/")[3];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_Backend_API}/api/jobs/${jobId}`
        );
        setJob(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [jobId]);

  const handleDelete = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_Backend_API}/api/jobs/${jobId}`
      );
      toast.success("Job deleted successfully!");
      navigate("/admin/jobs");
    } catch (err) {
      console.log(err);
    }
  };

  const isExpired = new Date() > new Date(job.closing);

  const MySwal = withReactContent(Swal);
  const popUpDelete = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete();
      }
    });
  };

  return (
    <>
      <MainCard
        extra={"flex items-center mb-4 justify-between h-[100px] px-8  "}
      >
        <Link to="/admin/jobs">
          <h1 className="text-textSecondary flex items-center gap-2 uppercase text-[30px] font-banscrift font-bold ">
            <AiOutlineArrowLeft />
            Back
          </h1>
        </Link>
        <h1 className="text-textSecondary flex items-center gap-2 uppercase text-[30px] font-banscrift font-bold">
          Job Details Page
        </h1>
      </MainCard>

      <MainCard extra={"w-full h-full pb-14 px-6"}>
        {/* header */}
        <h1 className="text-[40px] font-bold pt-10 text-textSecondary ">
          {job.title}
        </h1>
        {/* main */}
        <div className="mt-4">
          <div className="flex items-center mb-2 gap-7">
            <h1 className="font-bold  w-[200px] text-grayPrimary text-[24px] ">
              Date Posted
            </h1>
            <h1 className="text-grayPrimary text-[24px] ">
              {moment(job.posted).format("MMMM DD YYYY")}{" "}
            </h1>
          </div>
          <div className="flex items-center mb-2 gap-7">
            <h1 className="font-bold  w-[200px] text-grayPrimary text-[24px] ">
              Closing Date
            </h1>
            <h1 className="text-grayPrimary text-[24px] ">
              {" "}
              {/* {moment(job.closing).format("MMMM DD YYYY")}{" "} */}
              {isExpired ? "Expired" : moment(job.closing).format("MM DD YYYY")}
            </h1>
          </div>
          <div className="flex items-center mb-2 gap-7">
            <h1 className="font-bold w-[200px] text-grayPrimary text-[24px] ">
              Number of Vocancy
            </h1>
            <h1 className="text-grayPrimary text-[24px] ">{job.vacancy}</h1>
          </div>
          <div className="flex items-center mb-2 gap-7">
            <h1 className="font-bold w-[200px] text-grayPrimary text-[24px] ">
              Reference
            </h1>
            <h1 className="text-grayPrimary text-[24px] ">{job.reference}</h1>
          </div>
          <div className="flex items-center mb-2 gap-7">
            <h1 className="font-bold w-[200px] text-grayPrimary text-[24px] ">
              Work Type
            </h1>
            <h1 className="text-grayPrimary text-[24px] ">{job.worktype} </h1>
          </div>
          <div className="flex items-center mb-2 gap-7">
            <h1 className="font-bold  w-[200px] text-grayPrimary text-[24px] ">
              Gender
            </h1>
            <h1 className="text-grayPrimary text-[24px] ">{job.gender} </h1>
          </div>
          <div className="flex items-center mb-2 gap-7">
            <h1 className="font-bold w-[200px] text-grayPrimary text-[24px] ">
              Nationality
            </h1>
            <h1 className="text-grayPrimary text-[24px] ">
              {job.nationality}{" "}
            </h1>
          </div>
          <div className="flex items-center mb-2 gap-7">
            <h1 className="font-bold  w-[200px] text-grayPrimary text-[24px] ">
              Functional Area
            </h1>
            <h1 className="text-grayPrimary ml-8 w-full text-[24px] ">
              {job.functionarea}
            </h1>
          </div>
          <div className="flex items-center mb-2 gap-7">
            <h1 className="font-bold  w-[200px] text-grayPrimary text-[24px] ">
              Contract Duration
            </h1>
            <h1 className="text-grayPrimary text-[24px] ">
              {job.constractarea}{" "}
            </h1>
          </div>
          <div className="flex items-center mb-2 gap-7">
            <h1 className="font-bold  w-[200px] text-grayPrimary text-[24px] ">
              Years of Experience
            </h1>
            <h1 className="text-grayPrimary text-[24px] ">{job.experience} </h1>
          </div>
          <div className="flex items-center mb-2 gap-7">
            <h1 className="font-bold  w-[200px] text-grayPrimary text-[24px] ">
              Contract Extension
            </h1>
            <h1 className="text-grayPrimary text-[24px] "> {job.extension} </h1>
          </div>
          <div className="flex items-center mb-2 gap-7">
            <h1 className="font-bold  w-[200px] text-grayPrimary text-[24px] ">
              Required Languages
            </h1>
            <h1 className="text-grayPrimary text-[24px] ">{job.languages}</h1>
          </div>
          {/* description */}
          <div className="h-fit">
            <h1 className="text-[30px] my-5 font-bold  text-textSecondary ">
              job description
            </h1>

            <div
              className="text-[24px] text-grayDark3"
              dangerouslySetInnerHTML={{ __html: job.description }}
            />
          </div>
          {/* responsiblires */}
          <div className="">
            <h1 className="text-[30px] my-5 font-bold  text-textSecondary ">
              Duties & Responsiblites
            </h1>
            <div
              className="text-[24px] text-grayDark3"
              dangerouslySetInnerHTML={{ __html: job.duties }}
            />
          </div>
          {/* job requirement */}
          <div className="">
            <h1 className="text-[30px] my-5 font-bold  text-textSecondary ">
              Job Requirement
            </h1>
            <div
              className="text-[24px] text-grayDark3"
              dangerouslySetInnerHTML={{ __html: job.requirement }}
            />
          </div>
          {/* Work Exprience */}
          <div className="">
            <h1 className="text-[30px] my-5 font-bold  text-textSecondary ">
              Work Experience
            </h1>
            <div
              className="text-[24px] text-grayDark3"
              dangerouslySetInnerHTML={{ __html: job.workexperience }}
            />
          </div>
          {/* Skills */}
          <div className="">
            <h1 className="text-[30px] my-5 font-bold  text-textSecondary ">
              Skills
            </h1>
            <div
              className="text-[24px] text-grayDark3"
              dangerouslySetInnerHTML={{ __html: job.skills }}
            />
          </div>
          {/* Language */}
          <div className="">
            <h1 className="text-[30px] my-5 font-bold  text-textSecondary ">
              Languages
            </h1>
            <p className="text-[20px] text-grayPrimary ">{job.languages}</p>
          </div>
          {/* job location */}
          <div className="">
            <h1 className="text-[30px] my-5 font-bold  text-textSecondary ">
              Job Location
            </h1>
            <p className="text-[20px] text-grayPrimary ">{job.location}</p>
          </div>
          {/* Guidline */}
          <div className="">
            <h1 className="text-[30px] my-5 font-bold  text-textSecondary ">
              Submission guideline:
            </h1>
            <div
              className="text-[24px] text-grayDark3"
              dangerouslySetInnerHTML={{ __html: job.guidline }}
            />
          </div>
        </div>
      </MainCard>
      <MainCard
        extra={"w-full flex justify-end items-center mt-4 h-full py-8 px-6"}
      >
        <div className="flex items-center justify-end gap-5 ">
          <button
            onClick={popUpDelete}
            className="px-4 py-1 text-white transition-colors duration-200 bg-red-800 rounded-lg hover:bg-red-900"
          >
            Delete Policy
          </button>

          <Link to={`/admin/jobs/updatejob/${jobId}`}>
            <button className="px-4 py-1 text-white transition-colors duration-200 bg-blue-800 rounded-lg hover:bg-blue-900">
              Update Job
            </button>
          </Link>
        </div>
      </MainCard>
    </>
  );
};

export default SingleJob;
