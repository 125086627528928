/** @format */

import Country from "./components/Country";
import Header from "./components/Header";

const WhereWeWork = () => {
  return (
    <div className='h-full pb-32 dark:bg-darkMain'>
      <div className='dark:bg-darkHeader'>
        <Header />
      </div>
      <div className='lg:max-w-screen-md px-4 lg:px-0 text-center dark:!bg-darkMain h-full mt-10 lg:!mt-24  mx-auto'>
        <p className='text-grayDark3 dark:text-white font-calibrilight text-[22px] text-lg mt-2' style={{ textAlign: 'justify' }}>
          We have a long history of working in some of the world’s most challenging 
          development contexts, confronting all forms of corruption and inefficiency by 
          engaging with and mobilizing people on the ground: local communities, civil 
          society, media, academia, government officials, judicial actors, public 
          representatives, development partners, and the private sector. We have 
          earned international recognition and contributed to the global fight against 
          corruption through knowledge sharing and joining coalitions working on global 
          integrity and anti-corruption agendas.
          
        </p>
      </div>
      <div className='max-w-screen-xl mx-auto mt-5'>
        <Country />
      </div>
    </div>
  );
};

export default WhereWeWork;
