/** @format */

import IntegrityColor from "components/icons/IntegrityColor";
import Navbars from "components/navbar/Navbars";
import Header from "./components/Header";
import Card from "components/cards/card";
import { useEffect, useState } from "react";
import axios from "axios";

const OurStories = () => {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_Backend_API}/api/posts/`
        );
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);
  const photo = `${process.env.REACT_APP_Backend_API}/upload/stories/`;
  return (
    <div className="w-full h-full pb-32 bg-white dark:bg-darkMain ">
      <Navbars
        textColor={"text-textPrimary"}
        bg="bg-[#FAFAFA] dark:bg-darkHeader"
        logo={<IntegrityColor />}
      />
      <div className="">
        <Header />
      </div>
      {/* cards */}

      <div className=" max-w-screen-xl justify-items-center justify-center  mx-auto grid grid-cols-1 sm:grid-cols-2  lg:grid-cols-3 px-12 lg:px-0 gap-3 lg:gap-10 mt-[70px]  ">
        {posts.map(
          (post) =>
            post.hidden === "false" && (
              <div>
                <Card
                  to={`/ourstories/${post.id}`}
                  title={post.title}
                  date={post.date}
                  badgename="success stories"
                  image={photo + post.file}
                />
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default OurStories;
