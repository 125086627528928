/** @format */

import React from "react";
import announcement from "assets/Announcements/announcement.png";

function Header() {
  return (
    <div className='bg-colorWhite dark:bg-darkHeader font-banscrift  w-full h-[300px] '>
      {/* headeer */}
      <div className='relative flex h-full max-w-screen-xl px-8 mx-auto lg:grid lg:grid-cols-10'>
        <div className='flex flex-col justify-center col-span-5 text-left '>
          <h1 className='text-grayPrimary mt-6 uppercase text-[35px] font-normal'>
            announcements
          </h1>
        </div>
        <div className='justify-center hidden  lg:block lg:justify-end lg:col-span-5'>
          <div className='h-[170px] lg:absolute lg:top-32 lg:right-32 '>
            <img src={announcement} className='w-full h-full ' alt='' />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
