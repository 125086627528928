/** @format */

import IntegrityColor from "components/icons/IntegrityColor";
import Navbars from "components/navbar/Navbars";
import Header from "./components/Header";

const Contact = () => {
  return (
    <div className='w-full h-full pb-20 dark:bg-darkMain bg-white '>
      <Navbars
        textColor={"text-textPrimary"}
        bg='bg-[#FAFAFA] dark:bg-darkHeader'
        logo={<IntegrityColor />}
      />
      <div>
        <Header />
      </div>
      {/* content */}
      <div className='py-32 px-7 lg:px-0 max-w-screen-xl mx-auto '>
        <div className='flex justify-between items-center mt-8'>
          <h1 className='uppercase text-sm sm:text-base dark:text-white lg:text-lg text-grayPrimary'>
            General Inquiries
          </h1>
          <h1 className=' text-sm sm:text-sm dark:text-white lg:text-lg text-grayPrimary '>
            info@integritywatch.org
          </h1>
        </div>
        {/* line */}
        <div className='w-full dark:bg-white h-[2px] mt-1 bg-grayPrimary '></div>
        <div className='flex justify-between items-center mt-1'>
          <h1 className=' text-sm dark:text-white uppercase sm:text-base lg:text-lg text-grayPrimary'>
            INTEGRITY WATCH Hotline
          </h1>
          <h1 className='dark:text-white text-sm sm:text-base lg:text-lg text-grayPrimary '>
            complaints@integritywatch.org
          </h1>
			
        </div>
      </div>
    </div>
  );
};

export default Contact;
