/** @format */

import { RiSwordLine } from "react-icons/ri";
import { BsCurrencyDollar } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { SlUserFollowing } from "react-icons/sl";
import { MdOutlineBookmarkAdded } from "react-icons/md";
import { useState } from "react";
import group from "assets/image/group.png";

const Header = () => {
  const [hover, setHover] = useState(false);
  const [acc, setAcc] = useState(false);
  const [power, setPower] = useState(false);
  const [participate, setParticipate] = useState(false);
  const [inte, setInte] = useState(false);

  return (
    <header className={`bg-[#1AA9B8] dark:!bg-dark `}>
      <div className=' md:max-w-screen-xl md:mx-auto h-[600px] md:h-[500px] lg:h-[700px] pr-12 xl:pl-0 md:px-12 pb-[50px] xl:pb-[180px] '>
        {/* header */}

        <div className='grid w-full h-full grid-cols-2 '>
          {/* left side */}
          <div className='flex flex-col text-left pl-12 md:px-0 md:items-start col-span-2 md:col-span-1  w-full mt-14 md:mt-8 lg:pt-[40px] xl:pt-[80px]   '>
            <h1 className='text-white font-banscrift  text-[30px] xl:text-[40px] '>
              <span className='font-banscrift text-white  text-[30px] xl:text-[35px] '>
                {" "}
                We promote ,{" "}
              </span>
              <span
                className={
                  inte
                    ? `text-[#005362] text-[30px] xl:text-[35px] transition-colors duration-500 font-bold  `
                    : " text-white  text-[27px] xl:text-[35px]   font-custom2 font-bold"
                }
              >
                {" "}
                Integrity,
              </span>{" "}
              <span
                className={
                  acc
                    ? `text-[#4E2F87] text-[30px] xl:text-[35px] transition-colors duration-500 font-bold  `
                    : "text-white text-[27px] xl:text-[35px] font-bold"
                }
              >
                {" "}
                Accountability,
              </span>{" "}
              <span
                className={
                  participate
                    ? `text-[#E46710] text-[30px] xl:text-[35px] transition-colors duration-500 font-bold  `
                    : "text-white  text-[27px] xl:text-[35px] font-custom2 font-bold"
                }
              >
                {" "}
                <span className='font-normal text-white'> and inclusion, inspiring </span>{" "}
                Communities
              </span>{" "}
              <p
                className={
                  hover
                    ? `text-[#E46710] text-[30px] xl:text-[35px] transition-colors duration-500 font-bold  `
                    : "text-white text-[27px] xl:text-[35px] font-bold"
                }
              >
                {" "}
                <span className='font-normal text-white'>through </span>
                Community-Based Monitoring to improve services{" "}
              </p>
              <span
                className={
                  power
                    ? `text-[#852711] text-[30px] xl:text-[35px] transition-colors duration-500 font-bold  `
                    : "text-white text-[27px] xl:text-[35px] font-bold"
                }
              >
                {" "}
                <span className='font-normal text-white'>
                 and foster
                </span>{" "}
              </span>
              <span
                className={
                  power
                    ? `text-[#852711] text-[30px] xl:text-[35px] transition-colors duration-500 font-bold  `
                    : "text-white text-[27px] xl:text-[35px] font-bold"
                }
              >
                {" "}
                social trust.
              </span>
            </h1>
          </div>
          {/* right side */}

          <div className='col-span-2 md:col-span-1 lg:h-[450px] lg:w-[450px] w-[350px] h-[350px] hidden md:flex items-center justify-center  md:mt-20 lg:mt-[40px] xl:mt-[60px]  relative  xl:w-full xl:h-full    '>
            <div className=' xl:w-3/4 xl:h-3/4 w-[300px] h-[300px] '>
              <img src={group} alt='' />
            </div>
            {/* left */}
            <div
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              className=' hover:bg-[#E46710] hover:text-white hover:scale-125 transition-all duration-500 hover:cursor-pointer  flex absolute rounded-full   items-center justify-center w-[45px] h-[45px] text-3xl text-[#E46710] bg-white 
               xl:left-[50px] xl:top-[180px] left-[0px]  top-[90px] lg:left-[40px] lg:top-[140px] '
            >
              <BsCurrencyDollar />
            </div>

            {/* top */}
            <div
              onMouseEnter={() => setPower(true)}
              onMouseLeave={() => setPower(false)}
              className=' text-2xl text-[#852711]  flex absolute  items-center justify-center bg-white  rounded-full w-[40px] h-[40px] hover:bg-[#852711] hover:text-white hover:scale-125 transition-all duration-500 hover:cursor-pointer top-[5px] left-[138px] lg:top-[50px] lg:left-[190px] xl:left-[242px] xl:top-[40px]  '
            >
              <RiSwordLine />
            </div>

            {/* right logo */}
            <div
              onMouseEnter={() => setInte(true)}
              onMouseLeave={() => setInte(false)}
              className='hover:bg-[#005362] hover:text-white hover:scale-125 transition-all duration-500 hover:cursor-pointer flex text-4xl absolute  text-[#005362] items-center rounded-full justify-center xl:top-[190px] lg:right-[40px] lg:top-[150px] w-[55px] h-[55px] bg-white right-[0px] top-[100px] '
            >
              <AiOutlineEye />
            </div>
            {/* user logo */}
            <div
              onMouseEnter={() => setParticipate(true)}
              onMouseLeave={() => setParticipate(false)}
              className=' hover:bg-[#E46710] hover:text-white hover:scale-125 transition-all duration-500 hover:cursor-pointer absolute  flex items-center text-xl text-[#E46710] justify-center w-[40px] h-[40px] rounded-full bg-white top-[180px] right-[55px] xl:top-[300px] xl:right-[130px] lg:right-[130px] lg:top-[220px] '
            >
              <SlUserFollowing />
            </div>

            {/* bottom */}
            <div
              onMouseEnter={() => setAcc(true)}
              onMouseLeave={() => setAcc(false)}
              className=' w-[45px] bottom-[85px] left-[95px] xl:bottom-[130px] xl:left-[186px] hover:scale-125 hover:cursor-pointer hover:text-white hover:bg-[#4E2F87] absolute h-[45px] text-2xl flex transition-all duration-500 items-center justify-center rounded-full text-[#4E2F87] bg-white lg:bottom-[130px] lg:left-[140px] '
            >
              <MdOutlineBookmarkAdded />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
