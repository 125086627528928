/** @format */
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Card1 from "components/cards/Card1";
const Stories = () => {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_Backend_API}/api/posts/`,
          {
            "Content-Type": "application/json",
            // "Access-Control-Allow-Origin": process.env.REACT_APP_Backend_API,
          }
        );
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const { ref, inView } = useInView({
    threshold: 0.2,
  });
  const animation = useAnimation();

  const fade = useAnimation();
  useEffect(() => {
    if (inView) {
      fade.start({
        opacity: 1,
        transition: { duration: 0.5 },
      });
    }
    if (!inView) {
      fade.start({
        opacity: 0,
      });
    }
  }, [inView]);
  const photo = `${process.env.REACT_APP_Backend_API}/upload/stories/`;

  return (
    <div
      ref={ref}
      className={`lg:max-w-screen-2xl font-custom2 dark:text-white dark:bg-darkMain lg:mx-auto w-full pt-0 lg:pt-[20px] h-full pb-[30px] `}
    >
      {/* header */}
      <div className="flex items-center justify-center w-full ">
        <motion.h1
          animate={fade}
          className="text-[25px] sm:text-[30px] dark:text-white md:text-[40px] font-bold font-custom2 uppercase text-[#1AA9B8] "
        >
          our impact stories
        </motion.h1>
      </div>
      <motion.div
        animate={fade}
        className="flex items-center px-8 justify-center w-full  pt-10 h-[700px] pb-20"
      >
        <Swiper
          className="py-10"
          // install Swiper modules
          loop={true}
          modules={[Navigation, Pagination, Scrollbar]}
          spaceBetween={20}
          slidesPerView={3}
          pagination={{ clickable: true }}
          scrollbar={false}
          onSwiper={(swiper) => console.log(swiper)}
          centerSlide={true}
          fade={true}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },

            768: {
              slidesPerView: 2,
            },
            950: {
              slidesPerView: 3,
            },
          }}
        >
          {posts.map(
            (post) =>
              post.hidden === "false" && (
                <SwiperSlide className="pt-4 pb-14">
                  <Card1
                    to={`/ourstories/${post.id}`}
                    title={post.title}
                    date={post.date}
                    image={photo + post.file}
                  />
                </SwiperSlide>
              )
          )}
        </Swiper>
      </motion.div>
    </div>
  );
};

export default Stories;
