/** @format */

import React from "react";
import { useState } from "react";
import HealthSchool from "./HealthSchool";
import SchoolMethodology from "./SchoolMethodology";
import HealthMethodology from "./HealthMethodology";
import TrialMethodology from "./TrialMethodology";
import InfrastractureMethodology from "./InfrastractureMetho";
import ExtractiveMethodology from "./ExtractiveMetho";
import school from "assets/What we do/SVG/School.svg";
import health from "assets/What we do/SVG/Health.svg";
import trial from "assets/What we do/SVG/Trial.svg";
import extractive from "assets/What we do/SVG/Extractive.svg";
import infras from "assets/What we do/SVG/Infrastructur.svg";

function Monitoring() {
  const [active, setActive] = useState("school");
  return (
    <div className="w-full h-full pl-4 pr-6 dark:bg-darkMain ">
      <div className="w-full ">
        <h1 className=" :text-[28px] lg:px-0 lg:text-[35px] dark:text-white  text-textPrimary font-bold text-center ">
          Community Based Monitoring
        </h1>
        <div className="flex w-full justify-center  gap-2 lg:gap-6 mt-[40px] items-center">
          <h1
            onClick={() => setActive("school")}
            className={` text-sm lg:text-[20px] font-bold transition-all duration-300 hover:cursor-pointer uppercase ${
              active === "school"
                ? "border-b-[3px] text-textSecondary dark:text-white dark:border-white border-textSecondary "
                : "border-b-[3px] text-grayPrimary font-light dark:border-darkMain border-white"
            } `}
          >
            School
          </h1>
          <h1
            onClick={() => setActive("health")}
            className={` text-sm lg:text-[20px] font-bold transition-all duration-300  hover:cursor-pointer uppercase ${
              active === "health"
                ? "border-b-[3px] text-textSecondary dark:border-white dark:text-white border-textSecondary "
                : "border-b-[3px] text-grayPrimary dark:border-darkMain font-light border-white"
            } `}
          >
            Health
          </h1>
          <h1
            onClick={() => setActive("Trial")}
            className={` text-sm lg:text-[20px] font-bold transition-all duration-300  hover:cursor-pointer uppercase ${
              active === "Trial"
                ? "border-b-[3px] text-textSecondary dark:border-white dark:text-white border-textSecondary "
                : "border-b-[3px] text-grayPrimary dark:border-darkMain font-light border-white"
            } `}
          >
            TRIAL
          </h1>
          <h1
            onClick={() => setActive("Infrastracture")}
            className={` text-sm lg:text-[20px] font-bold transition-all duration-300  hover:cursor-pointer uppercase ${
              active === "Infrastracture"
                ? "border-b-[3px] text-textSecondary dark:border-white dark:text-white border-textSecondary "
                : "border-b-[3px] text-grayPrimary dark:border-darkMain font-light border-white"
            } `}
          >
            Infrastracture
          </h1>
          <h1
            onClick={() => setActive("Extractive")}
            className={` text-sm lg:text-[20px] font-bold text-textSecondary  transition-all duration-300  hover:cursor-pointer uppercase ${
              active === "Extractive"
                ? "border-b-[3px] text-textSecondary dark:border-white dark:text-white border-textSecondary "
                : "border-b-[3px] text-grayPrimary dark:border-darkMain font-light border-white"
            } `}
          >
            Extractive
          </h1>
        </div>
        {/* main content */}
        {active === "school" ? (
          <HealthSchool
            photo={school}
            text=" text-base lg:text-lg !font-calibrilight "
            paragraph1=" Initiated by Integrity Watch in July 2014, the Community-Based Monitoring of Schools (CBM-S) Program is designed to bring more coordination between communities and local government education departments, increase transparency and accountability, and enhance the quality of education services through community monitoring and collaborative problem-solving. In this way, the program seeks to make officials more responsive, encourage communities to support the education sector, and help citizens advocate for policy change at local and national levels. "
            paragraph2="
            The CBM-S Program replaced Integrity Watch’s Community Score Card (CSC) Program, in which members of School Management Shuras (SMS) were empowered through training to evaluate education services, identify school problems, and seek local opportunities for solutions. Due to the lack of an effective monitoring system and the reactive nature of the SMS, Integrity Watch decided to alter its approach and switch to CBM-S programming, having already achieved notable success with CBM in other sectors. The CBM-S approach has proven highly effective in enabling community members to regularly monitor schools and collaborate with school management to resolve problems.
            "
          />
        ) : active === "health" ? (
          <HealthSchool
            photo={health}
            text="text-base lg:text-lg font-calibriregular "
            paragraph1="Integrity Watch’s Community-Based Monitoring of Health (CBM-H) program promotes social accountability through community mobilization. The program empowers communities to monitor local health facilities, hold authorities and aid entities accountable, and engage in advocacy and problem-solving. Communities thereby become more autonomous in solving local issues through monitoring and continuous dialogue."
            paragraph2="The program began in 2018, with the monitoring of health facilities in 10 communities in Kabul province. The program quickly expanded to other provinces thanks to strong community buy-in and the program’s success in promoting integrity and accountability, and its high rates of problem resolution."
            paragraph3="While CBM-H activity targets a wide range of categories and components of health service delivery, the scope of monitoring is typically guided by available resources and the ease or difficulty of accessing information."
          />
        ) : active === "Trial" ? (
          <HealthSchool
            photo={trial}
            text="text-base lg:text-lg font-calibriregular "
            paragraph1="Through Community-Based Monitoring of Trials (CBM-T), Integrity Watch and its local partners aim to compel courts to become more open and transparent in conducting trials. The CBM-T program achieves this by facilitating community capacity-building and joint advocacy sessions for local actors and stakeholders. It enables the constructive engagement of citizens at primary and appeal courts to ensure the judiciary is accountable to the public and proceedings are conducted with integrity and impartiality."
            paragraph2="CBM-T interventions have brought about increased transparency and other significant positive changes at court trials. These include an increased number of public trial sessions and better public awareness of fair judicial procedure, which in turn has raised public confidence in the judiciary. "
            paragraph3="The route into CBM-T programming is through coordination with the Supreme Court and then through local-level engagement. Communities are chosen based on their accessibility, enthusiasm, and proximity to the court venue, and diversity in terms of ethnic groups."
          />
        ) : active === "Infrastracture" ? (
          <HealthSchool
            photo={infras}
            text="text-base lg:text-lg font-calibriregular "
            paragraph1="Integrity Watch’s Community-Based Monitoring of Infrastructure (CBM-I) program promotes social accountability through community mobilization and monitoring. The program empowers communities to hold local authorities and construction companies to account and ensure that what has been promised is delivered to communities. Communities engaged in  CBM-I become more autonomous in improving the quality and implementation of infrastructure projects, and in problem-solving through advocacy and continuous dialogue."
            paragraph2="Integrity Watch works with communities to elect Integrity Volunteers who monitor infrastructure projects on behalf of their communities. The Integrity Volunteers are trained by Integrity Watch in the use of carefully designed tools to monitor a series of metrics that illustrate the quality of infrastructure projects and their impact on the targeted communities, taking into account issues of adequacy and sustainability."
          />
        ) : (
          <HealthSchool
            photo={extractive}
            text="text-base lg:text-lg font-calibriregular "
            paragraph1="Integrity Watch’s Community-Based Monitoring of Extractives (CBM-E) program represents a framework for upholding community rights through community engagement in the oversight and monitoring of mining activities. Combining monitoring, collaborative information sharing, and local community problem-solving, the CBM-E program places strong emphasis on how mining or quarrying affects communities and the environment. It seeks to ensure that mining operations are being carried out with appropriate community consultations, and that mining companies follow legal requirements to share revenues with producing provinces/regions and compensate for any damages caused by their activities."
            paragraph2="The CBM-E program has been shaped and influenced by the discourse on human rights, sustainable development, and corporate social responsibility. Independent, credible case studies show that our CBM-E model is emerging as an important platform for local engagement and advocacy, empowering communities to promote transparency and integrity, and combat corruption."
          />
        )}
      </div>
      {/* Methodology */}
      <div className="max-w-screen-md mx-auto my-10 lg:mt-32 ">
        {active === "school" ? (
          <SchoolMethodology />
        ) : active === "health" ? (
          <HealthMethodology />
        ) : active === "Trial" ? (
          <TrialMethodology />
        ) : active === "Infrastracture" ? (
          <InfrastractureMethodology />
        ) : (
          <ExtractiveMethodology />
        )}
      </div>
    </div>
  );
}

export default Monitoring;
