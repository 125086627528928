import MainCard from "components/cards";
import React from "react";
import Update from "./components/Update";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";

const UpdateUserValidate = () => {
  return (
    <>
      <MainCard extra={"flex items-center justify-between h-[100px] px-8  "}>
        <Link to="/admin/users">
          <h1 className="text-textSecondary flex items-center gap-2 uppercase text-[30px] font-banscrift font-bold ">
            <AiOutlineArrowLeft />
            Back
          </h1>
        </Link>
        <div>
          <h1 className="text-textSecondary flex items-center gap-2 uppercase text-[30px] font-banscrift font-bold ">
            Update User Page
          </h1>
        </div>
      </MainCard>
      <div className="flex items-center justify-center h-full pb-10 mt-2">
        <div className="w-1/2 mt-4">
          <div className="flex items-center justify-center w-full ">
            <MainCard extra="w-full pb-10 px-4  pt-8 ">
              <h1 className="text-gray-900 mb-[10px] font-bold text-4xl">
                Update User
              </h1>
              <p className="ml-1 text-base mb-9 text-lightSecondary"></p>
              <Update />
            </MainCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateUserValidate;
