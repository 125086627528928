/** @format */

import axios from "axios";
import { useEffect, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import MainCard from "components/cards";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const SingleContact = () => {
  const [contact, setContact] = useState({});
  const navigate = useNavigate();

  const location = useLocation();
  const contactId = location.pathname.split("/")[3];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_Backend_API}/api/contact/${contactId}`
        );
        setContact(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [contactId]);

  const handleDelete = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_Backend_API}/api/contact/${contactId}`
      );
      toast.success("Contact deleted successfully!");
      navigate("/admin/contacts");
    } catch (err) {
      console.log(err);
    }
  };

  const MySwal = withReactContent(Swal);
  const popUpDelete = () => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete();
      }
    });
  };

  return (
    <div>
      <MainCard extra={"flex items-center justify-between h-[100px] px-8  "}>
        <Link to="/admin/contacts">
          <h1 className="text-textSecondary flex items-center gap-2 uppercase text-[30px] font-banscrift font-bold ">
            <AiOutlineArrowLeft />
            Back
          </h1>
        </Link>
      </MainCard>
      <MainCard extra={"mt-4 px-14 py-8 rounded-[20px]"}>
        {/* header */}
        <h1 className="text-textSecondary text-[30px] font-bold">
          New Message
        </h1>
        {/* date */}
        <div className="grid grid-cols-1 mt-6">
          <div className="flex items-center gap-5">
            <h1 className="text-xl font-bold text-textSecondary">Email:</h1>
            <p className="text-xl text-textSecondary">{contact.email}</p>
          </div>
        </div>
        {/* description */}
        <div>
          <h1 className="text-textSecondary mt-4 text-[30px] font-bold">
            Message
          </h1>
          <p className="text-[18px] mt-3 font-calibriregular">
            {contact.message}
          </p>
        </div>

        <div className="flex items-center justify-end gap-5 mt-12">
          <button
            onClick={popUpDelete}
            className="px-4 py-1 text-white transition-colors duration-200 bg-red-800 rounded-lg hover:bg-red-900"
          >
            Delete Contact
          </button>
        </div>
      </MainCard>
    </div>
  );
};

export default SingleContact;
