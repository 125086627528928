/** @format */

import React, { Children } from "react";
import { useField, ErrorMessage } from "formik";

const TextField = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="mb-4">
      <label className="mb-1" htmlFor={field.name}>
        {label}
      </label>
      <div className="w-full   text-white h-[50px] rounded-[10px] dark:!bg-dark bg-[#179DAB] ">
        <input
          {...field}
          {...props}
          className={`outline-none dark:bg-darkMain  placeholder:text-white border-none px-2 rounded-[10px] focus:border-none focus:outline-none text-white bg-[#179DAB] w-full h-full `}
        />
      </div>
      <ErrorMessage
        component="div"
        className="text-red-500"
        name={field.name}
      />
    </div>
  );
};

export default TextField;
