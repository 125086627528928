/** @format */

import axios from "axios";
import MainCard from "components/cards";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SignUp from "./SignUp";
import { AiOutlineArrowLeft } from "react-icons/ai";

const RegisterValidation = () => {
  return (
    <>
      <MainCard extra={"flex items-center  justify-between h-[100px] px-8  "}>
        <Link to="/admin/users">
          <h1 className="text-textSecondary flex items-center gap-2 uppercase text-[30px] font-banscrift font-bold ">
            <AiOutlineArrowLeft />
            Back
          </h1>
        </Link>
        <h1 className="text-textSecondary flex items-center gap-2 uppercase text-[30px] font-banscrift font-bold ">
          Register New User Page
        </h1>
      </MainCard>
      <div className="flex items-center justify-center h-full pb-10 mt-2">
        <div className="w-1/2 mt-4">
          <div className="flex items-center justify-center w-full ">
            <MainCard extra="w-full pb-10 px-4  pt-8 ">
              <h1 className="text-gray-900 mb-[10px] font-bold text-4xl">
                Add New User
              </h1>
              <p className="ml-1 text-base mb-9 text-lightSecondary"></p>
              <SignUp />
            </MainCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterValidation;
